import {HttpErrorResponse} from '@angular/common/http';
import {TrackAndTraceActions, TrackActionsTypes} from '../actions/track.actions';

export interface State {
    trackErrorResponse: HttpErrorResponse;
    trackLoading: boolean;
    trackResponse: any;
}

export const initialState: State = {
    trackErrorResponse: null,
    trackLoading: false,
    trackResponse: null
};

export function reducer(state = initialState, action: TrackAndTraceActions) {
    switch (action.type) {
        case TrackActionsTypes.TRACK_START:
            return {
                ...state,
                trackLoading: true,
                trackErrorResponse: null
            };
        case TrackActionsTypes.TRACK_COMPLETE:
            return {
                ...state,
                trackResponse: action.payload,
                trackLoading: false
            };
        case TrackActionsTypes.TRACK_FAILED:
            return {
                ...state,
                trackErrorResponse: action.payload,
                trackLoading: false
            };
        default:
            return state;
    }
}

export const getTrackLoading = (state: State) => state.trackLoading;
export const getTrackErrorResponse = (state: State) => state.trackErrorResponse;
export const getTrackResponse = (state: State) => state.trackResponse;
