<div id="content" class="mb-0">
  <img
      src="../../assets/dummy/chatbot_v3.jpg"
      alt="Digitaler assistent">
  <div class="panel-without-border mb-0">
    <h1>Kennen Sie schon unseren Digitalen Assistenten?</h1>
    <h2 class="mt-l">Gerne können Sie folgende Anliegen jederzeit telefonisch über unseren "Bot" abwickeln:</h2>
    <div class="row justify-content-md-center mb-xxl">
      <p class="w-75 lead"><strong>Auskunft zum aktuellen Lieferstatus (Sendungsverfolgung)</strong><br>Beispiel: "Hat die aktuelle Tour Verspätung?"</p>
      <p class="w-75 lead"><strong>Auskunft zu Verfallinformationen</strong><br>Beispiel: "Wie ist das Verfalldatum der PZN 958298?"</p>
      <p class="w-75 lead"><strong>Auskunft zu Bestellanfragen/Verfügbarkeiten</strong><br>Beispiel: "Ist die PZN 3227112 verfügbar?"</p>
      <p class="w-75 lead"><strong>Bestellungen durchführen</strong><br>Beispiel: "Ich möchte gerne etwas bestellen. Die PZN lautet 6706149."</p>
      <p class="w-75 lead"><strong>Aufnahme von Nachlieferungen</strong><br>Beispiel: "Ich möchte die PZN 1405147 zur Nachlieferung aufnehmen."</p>
    </div>

    <ul>
      <li>Dieser ist 24/7 erreichbar</li>
      <li>Sie erhalten direkt eine Auskunft</li>
      <li>Ein kurzer Anruf genügt</li>
    </ul>
    <h2 class="mt-l">Wie bestelle ich Ware?</h2>
    <img class="img-fluid w-50" src="../../assets/dummy/dig-assist-video-1.jpg">

    <ol class="mt-l">
      <li>Anliegen in natürlicher Sprache platzieren [Beispiele: „Ich möchte etwas bestellen“ oder kurz: „Bestellung“]</li>
      <li>Pharmazentralnummer und Bestellmenge einsprechen</li>
      <li>Bestellung bestätigen</li>
    </ol>

    <h2 class="mt-l">Wie frage ich Verfalldaten ab?</h2>
    <img class="img-fluid w-50" src="../../assets/dummy/dig-assist-video-2.jpg">

    <ol class="mt-l">
      <li>Anliegen in natürlicher Sprache platzieren [Beispiele: „Ich habe eine Verfallanfrage“ oder kurz: „Verfall“]</li>
      <li>Pharmazentralnummer einsprechen</li>
      <li>Verfalldatum erhalten</li>
    </ol>

    <p>Tipp: Um Zeit zu sparen sagen Sie einfach direkt: „Ich brauche das Verfalldatum von der PZN 4462984.“</p>

    <p>Unser Kundenservice steht Ihnen natürlich weiterhin zu den gewohnten Öffnungszeiten zur Verfügung.<br>
      (Montag bis Freitag 08:30 – 18:30 Uhr & Samstag 09:00 – 12:00 Uhr)</p>
  </div>
</div>
<section class="green-square">
  <h2>Rufen Sie jetzt an unter der</h2>
  <a>
    <button class="btn-primary on-dark-bg mr-l">
      0800 104 9999
    </button>
  </a>
</section>