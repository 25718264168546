<div id="content" class="mb-0" (click)="scrollTo($event)">
  <div class="panel-without-border">
    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c233" class="frame frame-default frame-type-textmedia frame-layout-0">
              <div id="c233" class="ce-textpic ce-left ce-intext">
                <p>Wir nehmen den Schutz und die Sicherheit der Daten unserer Mitarbeiter, Geschäftspartner und Kunden
                  ernst. Daher ist uns die besondere Beachtung der Privatsphäre bei der Verarbeitung personenbezogener
                  Daten ein wichtiges Anliegen. Soweit personenbezogene Daten erhoben werden (z.B. Ihr Name, Ihre
                  Adresse oder andere Kontaktdaten), erfolgt eine Verarbeitung ausschließlich in Übereinstimmung mit den
                  geltenden Datenschutzbestimmungen, vor allem der EU-Datenschutzgrundverordnung (<strong>DSGVO</strong>)
                  und dem Bundesdatenschutzgesetz (BDSG).</p>
                <p>Nachstehend finden Sie Informationen über die grundlegenden Zwecke der Verarbeitung personenbezogener
                  Daten unserer Kunden und Geschäftspartner (Einzelpersonen) oder deren Mitarbeiter, Vertreter und
                  sonstigen Ansprechpartner unserer Geschäftspartner, sowie eine Übersicht der Rechte der betroffenen
                  Personen (einschließlich der Kontaktdaten der Datenschutzbeauftragten). Informationen für eigene
                  Mitarbeiter werden separat zur Verfügung gestellt.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c234" class="frame frame-default frame-type-textmedia frame-layout-0">
              <header><h2 class="">
                Inhalt
              </h2></header>
              <div id="c234" class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext">
                  <ol>
                    <li class="MsoToc1">
                      <a href="#c235" class="scrollTo linki">Verantwortlicher &amp; Datenschutzbeauftragter</a></li>
                    <li class="MsoToc1"><a href="#c236" class="scrollTo linki">Erhebung personenbezogener Daten bei Besuch
                      unserer Website</a></li>
                    <li class="MsoToc1"><a href="#c237" class="scrollTo linki">Weitere Funktionen und Angebote unserer
                      Website</a></li>
                    <li class="MsoToc1"><a href="#c238" class="scrollTo linki">Geschäftsbeziehungen</a></li>
                    <li class="MsoToc1"><a href="#c239" class="scrollTo linki">Newsletter</a></li>
                    <li class="MsoToc1"><a href="#c240" class="scrollTo linki">E-Mail Kontakt</a></li>
                    <li class="MsoToc1"><a href="#c241" class="scrollTo linki">Videoüberwachungsaufnahmen</a></li>
                    <li class="MsoToc1"><a href="#c220" class="scrollTo linki">Vimeo</a></li>
                    <li class="MsoToc1"><a href="#c242" class="scrollTo linki">Fotoaufnahmen</a></li>
                    <li class="MsoToc1"><a href="#c243" class="scrollTo linki">Nutzung unseres Portals</a></li>
                    <li class="MsoToc1"><a href="#c352" class="scrollTo linki">Payback Kundenentwicklung</a></li>
                    <li class="MsoToc1"><a href="#c245" class="scrollTo linki">Übermittlung von personenbezogenen
                      Daten</a><br>
                      a) Innerhalb der PHOENIX Gruppe &nbsp;<br>
                      b)Externe Empfänger&nbsp;<br>
                      c)Auftragsverarbeitung
                    </li>
                    <li class="MsoToc1"><a href="#c246" class="scrollTo linki">Ihre Rechte</a></li>
                    <li class="MsoToc1"><a href="#c247" class="scrollTo linki">Meldesystem für Datenschutzvorfälle</a></li>
                    <li class="MsoToc1"><a href="#c248" class="scrollTo linki">Allgemeines</a></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c235" class="frame frame-default frame-type-textmedia frame-layout-0" #c235>
              <header><h2 class="c235">
                1) Verantwortlicher &amp; Datenschutzbeauftragter
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <p>Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer
                  personenbezogenen Daten im Sinne der DSGVO ist </p>
                <p>PHOENIX Pharmahandel GmbH &amp; Co KG (<strong>Wir und/oder PPH</strong>) </p>
                <p>Pfingstweidstraße 10-12, 68199 Mannheim, </p>
                <p>Telefon: +49 (0)621 8505-0.</p>
                <p>Unsere Datenschutzbeauftragte erreichen Sie unter <a href="mailto:datenschutz-pph@phoenixgroup.eu">datenschutz-pph@phoenixgroup.eu</a>
                  oder unserer Postadresse mit dem Zusatz „die Datenschutzbeauftragte“.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c236" class="frame frame-default frame-type-textmedia frame-layout-0" #236>
              <header><h2 class="c236">
                2) Erhebung personenbezogener Daten bei Besuch unserer Website
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <p>Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht
                  registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen
                  Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten,
                  erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website
                  anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:</p>
                <p>– IP-Adresse<br> – Datum und Uhrzeit der Anfrage<br> – Zeitzonendifferenz zur Greenwich Mean Time
                  (GMT)<br> – Inhalt der Anforderung (konkrete Seite)<br> – Zugriffsstatus/HTTP-Statuscode<br> – jeweils
                  übertragene Datenmenge<br> – Website, von der die Anforderung kommt<br> – Browser<br> – Betriebssystem
                  und dessen Oberfläche<br> – Sprache und Version der Browsersoftware.</p>
                <p>Rechtsgrundlage für diese Datenverarbeitung ist das berechtigte Interesse im Sinne von Art. 6 Abs. 1
                  lit. f DSGVO. </p>
                <p>Zusätzlich zu der zuvor genannten Datenverarbeitung verwenden wir auf diese Website:</p>
                <p><strong>Matomo (ehemals Piwik)</strong></p>
                <p>Zur Reichweitenmessung, bedarfsgerechten Gestaltung und Optimierung unserer Webseite verwenden wir
                  das Webanalysetool „Matomo“, einem Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011
                  Wellington, Neuseeland. Mithilfe dieses Tools erstellen wir Statistiken zur Nutzung unserer Webseite
                  auf Grundlage anonymer Nutzungsdaten. Die zuvor genannten Zwecke sind zugleich unsere berechtigten
                  Interessen im Sinne von Art. 6 Abs. 1 lit. f) DSGVO. Wenn Sie der Datenverarbeitung mit Matomo
                  widersprechen wollen, können Sie dies nachstehend durch entfernen des Häkchens vornehmen: </p>
                <p><a
                    href="https://phoenixapothekenportal.matomo.cloud/index.php?module=CoreAdminHome&amp;action=optOut&amp;language=de&amp;backgroundColor=&amp;fontColor=&amp;fontSize=&amp;fontFamily="
                    title="Matomo tracking opt-out" target="_blank"></a><a
                    href="https://phoenixapothekenportal.matomo.cloud/index.php?module=CoreAdminHome&amp;action=optOut&amp;language=de"
                    title="Matomo tracking opt-out">opt out</a></p>
                <p>Wenn Sie den Widerspruch erklären, wird ein Cookie (piwik_ignore) auf Ihrem Endgerät abgelegt, um
                  sich zu merken, dass Ihr Surfverhalten nicht mit dem Tool Matomo erfasst werden soll. Rechtsgrundlage
                  hierfür sind unsere berechtigten Interessen daran, den opt-out von Ihnen umzusetzen, Art. 6 Abs. 1 lit
                  f) DSGVO. Sie können diesen Cookie über Ihre Browsereinstellungen jederzeit löschen.</p>
                <p><strong>Google Fonts</strong></p>
                <p>Unsere Webseite nutzt zur einheitlichen Darstellung von Schriftarten den Dienst Fonts von Google
                  LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“). Beim Aufruf einer Seite lädt
                  Ihr Browser die benötigten Web Fonts in Ihren Browser-Cache, um Texte und Schriftarten korrekt
                  anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google
                  aufnehmen, hierbei kommt es auch zu einer Übermittlung von personenbezogener Daten an die Server der
                  Google LLC. in den USA. Google erlangt u.a. Kenntnis darüber, dass über Ihre IP-Adresse unsere
                  Webseite aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen
                  und ansprechenden Darstellung unserer Online-Angebote. Diese Zwecke sind zugleich unsere berechtigten
                  Interessen im Sinne von Art. 6 Abs. 1 lit. f) DSGVO. </p>
                <p>Für den Fall der Übermittlung von personenbezogenen Daten an die Google LLC. mit Sitz in den USA, hat
                  sich Google LLC. für das EU-U.S. Datenschutzübereinkommen „Privacy Shield“ zertifiziert:</p>
                <p><a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                      target="_blank">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>
                </p>
                <p>welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.</p>
                <p>Weitere Informationen zu Google Fonts finden Sie unter <a
                    href="https://developers.google.com/fonts/faq" target="_blank">https://developers.google.com/fonts/faq</a>
                  und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy"
                                                                 target="_blank">https://www.google.com/policies/privacy</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c237" class="frame frame-default frame-type-textmedia frame-layout-0" #c237>
              <header><h2 class="c237">
                3) Weitere Funktionen und Angebote unserer Website
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <p>Neben der rein informatorischen Nutzung unserer Website bieten wir
                  verschiedene ggf. auch kostenpflichtige Leistungen an, die Sie nutzen können. Dazu müssen Sie in der
                  Regel weitere personenbezogene Daten angeben, die wir zur Erbringung der jeweiligen Leistung nutzen
                  und
                  für die die zuvor genannten Grundsätze zur Datenverarbeitung gelten. Zur Erbringung kostenpflichtiger
                  Leistungen werden von uns zusätzliche Daten erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung
                  ausführen zu können. Wir speichern diese Daten in unseren Systemen bis die gesetzlichen
                  Aufbewahrungsfristen abgelaufen sind.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c238" class="frame frame-default frame-type-textmedia frame-layout-0" #c238>
              <header><h2 class="c238">
                4) Geschäftsbeziehungen
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Wir verarbeiten im Rahmen unserer Geschäftstätigkeit hauptsächlich
                  Identifikationsdaten (Name, Vorname, Arbeitsstelle) und Kontaktdaten (Telefon, E-Mail-Adresse),
                  nämlich
                  Kunden/Geschäftspartner (natürliche Personen) oder deren Mitarbeiter, Vertreter. Darüber hinaus werden
                  die Zahlungs- und Lieferdaten von Kunden/Geschäftspartnern oder personenbezogene Daten anderer
                  natürlicher Personen verarbeitet (Identifikationsdaten, Kontaktdaten, beschreibende Daten in Bezug auf
                  ihre Eigentumsverhältnisse), sofern diese für die Pflichten unserer Kunden/Geschäftspartner haften
                  (z.B.
                  aufgrund einer Bürgschaftserklärung).</p>
                  <p>Der Zweck der Verarbeitung der oben genannten personenbezogenen Daten besteht in der Aushandlung
                    und
                    Ausführung des entsprechenden Vertrags (z. B. Miete, Kauf usw.), in der Sicherung und Durchsetzung
                    von
                    Verpflichtungen, in der Durchführung von Geschäftskommunikationen und Korrespondenz oder in der
                    Erfüllung gesetzlicher Verpflichtungen (Steuererklärungen, Geldwäsche oder Einhaltung
                    internationaler
                    Sanktionen). </p>
                  <p>Wir verarbeiten personenbezogene Daten, die wir direkt von den Kunden/Geschäftspartnern oder aus
                    öffentlich zugänglichen Quellen erhalten haben.</p>
                  <p>Die Verarbeitung der oben genannten personenbezogenen Daten erfolgt nur solange dies zur Erfüllung
                    des Zwecks (der Zwecke) erforderlich ist, für den sie erhoben wurden. Grundsätzlich werden diese
                    Daten
                    nur für die Dauer des Vertragsverhältnisses bzw. für die Dauer der gesetzlichen Aufbewahrungsfristen
                    verarbeitet. Aufgrund unseres berechtigten Interesses (Rechtsverteidigung, Eintreibung von
                    Forderungen
                    usw.) oder aufgrund gesetzlicher Verpflichtungen können die Daten jedoch teilweise auch darüber
                    hinaus
                    verarbeitet werden.</p>
                  <p>Soweit ohne Einwilligung zulässig, wenden wir uns zum Zwecke des Marketings an unsere Kunden
                    (E-Mail
                    Werbung gemäß § 7 Abs. 3 UWG). Sie haben das Recht, dieser Verarbeitung jederzeit mit Wirkung für
                    die
                    Zukunft zu widersprechen: <a href="mailto:marketing-news@phoenixgroup.eu">marketing-news@phoenixgroup.eu</a>
                    bzw. Abmelde-Link in der jeweiligen Mail.</p>
                  <p>Rechtsgrundlage für die vorgenannte Verarbeitung personenbezogener Daten ist der Abschluss oder die
                    Erfüllung eines Vertrages, die Erfüllung gesetzlicher Verpflichtungen oder unseres berechtigten
                    Interesses (Art. 6 Abs.1 lit. b, c und f DSGVO).</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c239" class="frame frame-default frame-type-textmedia frame-layout-0" #c239>
              <header><h2 class="c239">
                5) Newsletter
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Sie können sich für unsere Newsletter anmelden, mit denen wir Sie über
                  unsere
                  aktuelle interessante Angebote und Dienstleistungen informieren. Die beworbenen Waren und
                  Dienstleistungen sind in der Einwilligungserklärung benannt. </p>
                  <p>Pflichtangabe für die Übersendung des Newsletters ist allein die für den Erhalt des Newsletters
                    notwendige Kontaktangabe. Die Angabe weiterer, gesondert markierter Daten ist freiwillig und wird
                    verwendet, um Sie persönlich ansprechen zu können. Sofern Sie sich für einen E-Mail-Newsletter
                    angemeldet haben, erhalten Sie nach Absenden des Anmeldeformulars eine Bestätigungsmail. Die
                    Anmeldung
                    zum Email-Newsletter wird erst wirksam, wenn Sie auf den Link in der Bestätigungs-E-Mail geklickt
                    haben. Danach speichern wir Ihre angegebenen Kontaktdaten zum Zweck der Zusendung des Newsletters.
                    Rechtsgrundlage ist die Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO.</p>
                  <p>Ihre Einwilligung in die Übersendung des Newsletters können Sie jederzeit widerrufen und den
                    Newsletter abbestellen. Den Widerruf können Sie jederzeit per E-Mail an <a
                        href="mailto:marketing-news@phoenixgroup.eu">marketing-news@phoenixgroup.eu</a> oder durch einen
                    Klick auf den in jedem Newslettern enthaltenen Abmeldelink erklären.</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c240" class="frame frame-default frame-type-textmedia frame-layout-0" #c240>
              <header><h2 class="c240">
                6) E-Mail Kontakt
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Wenn Sie mit uns in Kontakt treten (z. B. per Kontaktformular oder E-Mail),
                  speichern wir Ihre Angaben zur Bearbeitung der Anfrage sowie für etwaige Anschlussfragen. Die in
                  diesem
                  Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder
                  schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen. Weitere
                  personenbezogene Daten speichern und nutzen wir nur, wenn Sie dazu einwilligen oder dies ohne
                  besondere
                  Einwilligung gesetzlich zulässig ist.</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c241" class="frame frame-default frame-type-textmedia frame-layout-0" #c241>
              <header><h2 class="c241">
                7) Videoüberwachungsaufnahmen
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Wir betreiben auf unseren Betriebsgeländen ein Videoüberwachungssystem.
                  Zweck
                  der Verarbeitung ist die Wahrnehmung des Hausrechts und Wahrung der berechtigten Interessen gemäß Art.
                  6
                  Abs.1 lit. f DSGVO. Die Anlage dient ausschließlich zur Verhinderung von Straftaten (z.B. Diebstahl,
                  Vandalismus) sowie deren Aufklärung. Das Videoüberwachungssystem wird nicht zu Zwecken der Leistungs-
                  und/oder Verhaltenskontrolle der Beschäftigten genutzt. Aufzeichnungen zur Arbeitszeiterfassung finden
                  nicht statt, Tonaufzeichnungen finden ebenfalls nicht statt. Die Bilddaten werden in der Regel 72
                  Stunden aufbewahrt und rollierend überschrieben. Eine längere Speicherdauer ist bei konkretem Anlass
                  (z.B. Straftaten) möglich. Die Aufzeichnungen werden in diesem Fall mit Wegfall des verlängerten
                  Speicherzwecks gelöscht.</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c220" class="frame frame-default frame-type-textmedia frame-layout-0" #c220>
              <header><h2 class="c220">
                8) Vimeo
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Wir nutzen für die Einbindung von Videos und Darstellung dieser den Anbieter
                  Vimeo; diese Zwecke sind sogleich unsere berechtigten Interessen. Vimeo wird betrieben von Vimeo, LLC
                  mit Hauptsitz in 555 West 18th Street, New York, NY 10011, USA. Wenn Sie die mit eingebundenen Videos
                  versehenen Internetseiten unserer Internetpräsenz aufrufen – beispielsweise ein Video abspielen –,
                  wird
                  eine Verbindung zu den Vimeo-Servern hergestellt und dabei das Video dargestellt. Hierdurch wird an
                  den
                  Vimeo-Server übermittelt, welche unserer Internetseiten Sie besucht haben und wie Ihre IP-Adresse
                  lautet. Sind Sie dabei als Mitglied bei Vimeo eingeloggt, ordnet Vimeo diese Information Ihrem
                  persönlichen Benutzerkonto zu. Bei Nutzung des Plugins wie z.B. Anklicken des Start-Buttons eines
                  Videos
                  wird diese Information ebenfalls Ihrem Benutzerkonto zugeordnet. Rechtsgrundlage ist Art. 6 Abs. 1 S.
                  1
                  lit. f) DSGVO. Sie können diese Zuordnung verhindern, indem Sie sich vor der Nutzung unserer
                  Internetseite aus ihrem Vimeo-Benutzerkonto abmelden und die entsprechenden Cookies von Vimeo löschen.
                  Weitere Informationen zur Datenverarbeitung und Hinweise zum Datenschutz durch Vimeo finden Sie unter
                  <a
                      href="https://vimeo.com/privacy">https://vimeo.com/privacy</a></p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c242" class="frame frame-default frame-type-textmedia frame-layout-0" #c242>
              <header><h2 class="c242">
                9) Fotoaufnahmen
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Bei Veranstaltungen werden oftmals Fotos angefertigt. Die Fotos werden
                  intern
                  und extern (in Printmedien und Websites etc.) verwendet. Auf die Aufnahme und Verwendung der Fotos
                  wird
                  beim Betreten der Veranstaltung hingewiesen und bei der Aufnahme und Veröffentlichung der Fotos wird
                  sichergestellt, dass keine berechtigten Interessen der abgebildeten Personengruppen verletzt
                  werden.</p>
                  <p>Rechtsgrundlage für diese Datenverarbeitung ist in der Regel das berechtigte Interesse im Sinne von
                    Art. 6 Abs. 1 lit. f DSGVO: Kunden- und/oder Mitarbeiterveranstaltungen und Darstellung der
                    Marketingaktivitäten des Verantwortlichen und im Übrigen die Einwilligung (Art. 6 Abs. 1 lit. a
                    DSGVO). Es besteht das Recht, dieser Verarbeitung zu widersprechen: <a
                        href="mailto:datenschutz-pph@phoenixgroup.eu">datenschutz-pph@phoenixgroup.eu</a>.</p>
                  <p>Die Fotos werden in der Regel innerhalb von 12 Monaten nach ihrer Erstellung gelöscht, sofern die
                    Zwecke der Erhebung nicht im Einzelfall noch fortbestehen.</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c243" class="frame frame-default frame-type-textmedia frame-layout-0" #c243>
              <header><h2 class="c243">
                10) Nutzung unseres Portals
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Soweit Sie als unser Kunde das Portal&nbsp;nutzen möchten, müssen Sie sich
                  mittels Angabe Ihrer IDF-Nummer sowie Name und Anschrift der Apotheke registrieren. Die Angabe der
                  zuvor
                  genannten Daten ist verpflichtend, alle weiteren Informationen können Sie freiwillig durch Nutzung
                  unseres Portals bereitstellen.</p>
                  <p>Im Anschluss erhält der Nutzer eine Bestätigungs-E-Mail mit einen Verifizierungslink, mit dem er
                    sein
                    Nutzerkonto einrichten, sein Passwort festlegen und die Registrierung abschließen kann. Wir benutzen
                    für diese Zwecke die E-Mail Adresse, die uns für Zwecke der Geschäftsbeziehung in den
                    Kundenstammdaten
                    hinterlegt&nbsp; ist oder hinterlegen wird. </p>
                  <p>PPH verarbeitet personenbezogene Daten der Nutzer (Apothekeninhaber und ggf. dessen Angestellte)
                    für
                    die Zwecke der Nutzung des PHOENIX Portals und zum Zweck der Vertragserfüllung. Rechtsgrundlage ist
                    Art. 6 Abs. 1 lit. b und lit. f DSGVO. </p>
                  <p>Alle Angaben kann der Nutzer im geschützten Kundenbereich verwalten und ändern.</p>
                  <p>Wenn Sie unser Portal nutzen, speichern wir die zur Vertragserfüllung erforderlichen Daten bis&nbsp;
                    endgültigen Löschung des Zugangs und/oder nach Ablauf der gesetzlichen Aufbewahrungsfristen. Für
                    einen
                    längeren Zeitraum könnten die Daten auch aufgrund unseres berechtigten Interesses
                    (Rechtsverteidigung,
                    Eintreibung von Forderungen usw.) verarbeitet werden. Rechtsgrundlage ist Art. 6 Abs. 1 lit. b und
                    lit. f DSGVO.</p>
                  <p>Um unberechtigte Zugriffe Dritter auf Ihre persönlichen Daten zu verhindern, wird die Verbindung
                    per
                    TLS-Technik verschlüsselt.</p>
                  <p>Bei Nutzung des Portals erheben wir noch Daten, die unter dem <em><u>Punkt 2</u></em> aufgelistet
                    sind. Bei Nutzung des Portals sind keine Cookies auf Ihren Rechnern gespeichert. Im Browser wird
                    lediglich ein verschlüsseltes Token hinterlegt, das im Wesentlichen den Namen des Users und seine
                    Rechte enthält. Dieser Datenpunkt wird im internen Speicher des Browsers abgelegt und dort wieder
                    gelöscht, sobald sich der Nutzer abmeldet. Sie können das Token durch eine entsprechende Einstellung
                    Ihrer Browser-Software löschen.</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c352" class="frame frame-default frame-type-textmedia frame-layout-0" #c352>
              <header><h2 class="c352">11) Payback Kundenentwicklung</h2></header>
                <p>Soweit mit dem Kunden über das Programm Kundenentwicklung Payback eine Vereinbarung getroffen wurde, werden sog. relevante kumulierte Kennzahlen der Kunden (Apotheken) durch Payback ermittelt und an die PHOENIX Pharmahandel GmbH &amp; Co KG weitergeleitet. Als Grundlage dafür dienen beispielsweise: Anzahl der vergebenen Basispunkte, durchschnittlicher Payback Bonwert, Anzahl der Payback Transaktionen. Es handelt sich ausschließlich um kumulierte Kennzahlen.
                </p>
                  <p>PHOENIX Pharmahandel GmbH &amp; Co KG übergibt die Auswertungen der kumulierten Kennzahlen an das konzernverbundene Unternehmen ADG Apotheken-Dienstleistungsgesellschaft mbH und beauftragt diese mit der Beratung und ggf. Schulungen &nbsp;des Kunden und &nbsp;der Mitarbeiter.<br>Zwecke der Verarbeitung sind der optimale Einsatz des Programms Payback, die Kundenbindung und das Generieren von höheren Umsätzen.
                  </p>
                  <p>PHOENIX Pharmahandel GmbH &amp; Co KG verarbeitet diese Daten aufgrund des berechtigten Interesses und der Vertragserfüllung gemäß Art. 6 Abs. 1 lit. b und lit. f DSGVO.
                  </p>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c245" class="frame frame-default frame-type-textmedia frame-layout-0" #c245>
              <header><h2 class="c245">
                12) Übermittlung von personenbezogenen Daten
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <p><strong>a) Innerhalb der PHOENIX Gruppe</strong></p>
                <p>PPH kann die personenbezogenen Daten von Geschäftspartnern/Kunden (natürlichen Personen) oder deren
                  Mitarbeitern, Beauftragten, Bürgen usw. innerhalb der PHOENIX group für interne Verwaltungszwecke
                  übermitteln (z. B. Debitorenmanagement, Controlling, Risikomanagement, indirekter Einkauf, Einhaltung
                  gesetzlicher Verpflichtungen wie Steuererklärungen, Geldwäsche oder Einhaltung internationaler
                  Sanktionsrichtlinien).
                </p>
                <p>&nbsp;</p>
                <p>Mitarbeiter der PHOENIX group sind Im Rahmen einer Online-Schulung über die Datenschutzbestimmungen
                  der PHOENIX group geschult und sind nach internen Richtlinien zur Vertraulichkeit personenbezogener
                  Daten oder Sicherheitsmaßnahmen verpflichtet, wenn sie an der Verarbeitung teilnehmen. Die
                  Verarbeitung personenbezogener Daten erfolgt bei PHOENIX group automatisch mittels
                  Computertechnologie
                  und manuell in Form einer Papierdatei oder einer Akte durch einzelne befugte Mitarbeiter, die diese
                  Daten für ihre Arbeit benötigen (<em>Need-to-Know-Prinzip</em>).</p>
                <p>&nbsp;</p>
                <p>Im Rahmen der Verarbeitung personenbezogener Daten wurden technische und organisatorische Maßnahmen
                  getroffen, um den Schutz personenbezogener Daten zu gewährleisten.</p>
                <p>&nbsp;</p>
                <p><strong>b) Externe Empfänger</strong></p>
                <p>Personenbezogene Daten können auch an Dritte (z. B. Rechtsanwälte, Wirtschaftsprüfer, Banken,
                  Versicherungen, Strafverfolgungsbehörden usw.) weitergegeben werden, wenn ein berechtigtes Interesse
                  oder eine gesetzliche Pflicht besteht.</p>
                <p>&nbsp;</p>
                <p>Weiterhin können wir Ihre personenbezogenen Daten an Dritte weitergeben, wenn dies zur
                  Vertragserfüllung erforderlich ist (z.B. Aktionsteilnahmen, Gewinnspiele, Vertragsabschlüsse oder
                  ähnliche Leistungen von uns gemeinsam mit Partnern angeboten werden). Nähere Informationen hierzu
                  erhalten Sie jeweils bei Angabe Ihrer personenbezogenen Daten.</p>
                <p>&nbsp;</p>
                <p>Im Fall der Gewährung von Sonderkonditionen für bestimmte Produkte und/oder Sortimente, werden zum
                  Zwecke der Rückerstattung / Gegenfinanzierung der verauslagten Konditionen die Nachweise des
                  Absatzes
                  Ihrer Daten an die entsprechenden Hersteller/Geschäftspartner übermittelt. Die Rechtsgrundlage
                  dieser
                  Übermittlung ist Art 6 Abs. 1 lit. b DSGVO (Vertragserfüllung), oder aber Ihre Einwilligung (Art. 6
                  Abs. 1 lit. a DSGVO). </p>
                <p>&nbsp;</p>
                <p>PPH prüft regelmäßig bei Vertragsabschlüssen und in bestimmten Fällen, in denen ein berechtigtes
                  Interesse vorliegt, auch bei Bestandskunden ihre Bonität. Dazu arbeiten wir mit Auskunfteien
                  zusammen,
                  von denen wir die dazu benötigten Daten erhalten. Zu diesem Zweck übermitteln wir Ihren Namen und
                  Ihre
                  Kontaktdaten an diese, wir melden jedoch keine Zahlungserfahrungen.</p>
                <p>&nbsp;</p>
                <p>Die für die Durchführung eines Inkasso erforderlichen Daten können wir an einen Rechtsanwalt oder
                  ein
                  Inkassobüro zum Zweck der Geltendmachung von Ansprüchen übermitteln. Rechtsgrundlage für die
                  Übermittlung der Daten im Rahmen des Treuhandinkasso ist Art. 6 Absatz 1 lit. b DSGVO
                  (Vertragserfüllung). </p>
                <p>&nbsp;</p>
                <p>Wir können offene Forderungen an eine Finanzinstitution veräußern; die Übermittlung der Daten im
                  Rahmen des Forderungsverkaufs erfolgt auf Basis von Art. 6 Absatz 1 lit. b und f DSGVO (das
                  berechtigte Interesse).</p>
                <p>&nbsp;</p>
                <p>Darüber hinaus dürfen personenbezogene Daten von Geschäftspartnern/Kunden an staatliche Behörden
                  und
                  andere Institutionen weitergegeben werden, sofern dies gesetzlich vorgeschrieben ist (z. B. bei
                  Kontrollstellen wie Finanzamt, Strafverfolgungsbehörden, Testamentsvollstreckern,
                  Insolvenzverwaltern
                  usw.). Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO. </p>
                <p>&nbsp;</p>
                <p><strong>c) Auftragsverarbeitung</strong></p>
                <p>Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister/Auftragsverarbeiter.
                  Diese wurden von uns sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und
                  werden regelmäßig kontrolliert.</p>
                <p>&nbsp;</p>
                <p>Personenbezogene Daten werden zum Teil auch in Ländern außerhalb der Europäischen Union („EU“) oder
                  des Europäischen Wirtschaftsraums („EWR“) verarbeitet, wo generell ein geringeres Datenschutzniveau
                  herrschen könnte als in Europa. In diesen Fällen stellen wir z.B. über vertragliche Vereinbarungen
                  mit
                  unseren Vertragspartnern sicher, dass ein ausreichendes Datenschutzniveau für diese Daten
                  gewährleistet ist.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c246" class="frame frame-default frame-type-textmedia frame-layout-0" #c236>
              <header><h2 class="c246">
                13) Ihre Rechte
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Nachstehend möchten wir Sie auch über Ihre Rechte gemäß der DSGVO
                  aufklären:</p>
                  <p>&nbsp;</p>
                  <p><em>Recht aus Auskunft</em><br> Sie haben das Recht, eine Bestätigung darüber einzuholen, ob über
                    wir
                    personenbezogene Daten von Ihnen verarbeiten werden. Falls dies zutrifft, können Sie eine Auskunft
                    zu
                    den verarbeiteten Daten gemäß Art. 15 DSGVO einholen.</p>
                  <p>&nbsp;</p>
                  <p><em>Recht auf Berichtigung</em><br> Gemäß Artikel 16 der DSGVO haben Sie das Recht, die Ergänzung
                    oder Berichtigung unrichtiger Daten, die Sie betreffen, zu verlangen.</p>
                  <p>&nbsp;</p>
                  <p><em>Recht auf Löschung</em><br> Mit Verweis auf Art. 17 DSGVO haben Sie das Recht, die Löschung
                    Ihrer
                    personenbezogenen Daten zu verlangen, sofern keine gesetzlichen Verpflichtungen zur Aufbewahrung
                    bestehen.</p>
                  <p>&nbsp;</p>
                  <p><em>Recht auf Einschränkung der Verarbeitung</em><br> Sie können eine Einschränkung der
                    Verarbeitung
                    gemäß Art. 18 verlangen.</p>
                  <p>&nbsp;</p>
                  <p><em>Recht auf Datenübertragbarkeit</em><br> Sie haben das Recht, eine Herausgabe Ihrer uns zur
                    Verfügung gestellten personenbezogenen Daten als auch eine Übermittlung an andere Verantwortliche zu
                    verlangen.</p>
                  <p>&nbsp;</p>
                  <p><em>Widerspruchsrecht</em><br> Sie können jederzeit gegen die Verarbeitung Ihrer personenbezogenen
                    Daten unter den Voraussetzungen des Art. 21 DSGVO Widerspruch einlegen.</p>
                  <p>&nbsp;</p>
                  <p><em>Recht auf Widerruf</em><br> Sie haben das Recht, eine erteilte Einwilligung gemäß Art. 7 Abs. 3
                    DSGVO jederzeit mit Wirkung für die Zukunft zu widerrufen.</p>
                  <p>&nbsp;</p>
                  <p><em>Recht auf Benachrichtigung der Aufsichtsbehörde</em><br> In Übereinstimmung mit Art. 77 DSGVO
                    haben Sie das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c247" class="frame frame-default frame-type-textmedia frame-layout-0" #c247>
              <header><h2 class="c247">
                14) Meldesystem für Datenschutzvorfälle
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Die PHOENIX group, d.h. die PHOENIX Pharmahandel GmbH &amp; Co KG sowie ihre
                  konzernverbundenen Unternehmen im Sinne der §§ 15ff AktG, hat ein webbasiertes Meldesystem
                  eingerichtet,
                  das Mitarbeitern, Geschäftspartnern, Kunden und Dritten ein einfaches System zur Meldung von
                  Datenvorfällen oder -problemen bietet. Diese Berichte werden ernst genommen, regelmäßig überprüft und
                  umgesetzt und dienen dazu, den Schutz personenbezogener Daten zu verbessern.</p>
                  <p>&nbsp;</p>
                  <p>Auf dieses Meldesystem können Sie jederzeit zugreifen über:</p>
                  <p><a href="https://phoenixgroup-databreach.integrityplatform.org/" target="_blank">https://phoenixgroup-databreach.integrityplatform.org/</a>
                  </p>
                  <p>&nbsp;</p>
                  <p>Um Ihnen den Hintergrund des Meldesystems konkreter zu erläutern, haben wir nachstehend zudem
                    einige
                    häufig gestellt Fragen beantwortet:</p>
                  <p>&nbsp;</p>
                  <p><em>Wann sollte ich einen Vorfall melden?</em><br> PHOENIX group ist verpflichtet, die
                    Aufsichtsbehörde innerhalb von 72 Stunden nach Bekanntwerden eines Vorfalls zu benachrichtigen, so
                    dass alle Vorfälle unverzüglich über das Online-Reporting-Tool gemeldet werden müssen.</p>
                  <p>&nbsp;</p>
                  <p><em>Welche Datenschutzvorfälle sollten gemeldet werden und wie?</em><br> Alle Vorfälle mit
                    personenbezogenen Daten sind über das Online-Reporting-Tool an die Datenschutzbeauftragten zu
                    melden.
                  </p>
                  <p>&nbsp;</p>
                  <p><em>Was ist ein Datenschutzvorfall?</em><br> Datenschutzvorfälle sind alle Ereignisse, die zu einem
                    zufälligen oder vorsätzlichen Verlust personenbezogener Daten (elektronisch oder auf Papier) oder
                    zur
                    Zerstörung von Daten oder zu einem unbefugten Zugriff auf Daten (z.B. Verlust oder Diebstahl von
                    Laptops, Smartphones, Papierdokumenten, Rezepten) geführt haben oder führen könnten.</p>
                  <p>&nbsp;</p>
                  <p><em>Was passiert, nachdem ich eine Meldung eingereicht habe?</em><br> Die Datenschutzbeauftragten
                    werden die Meldung prüfen und sich mit Ihnen in Verbindung setzen, um weitere Informationen zu
                    erhalten oder, falls erforderlich, Ihnen bei den Maßnahmen nach dem Vorfall behilflich sein.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c248" class="frame frame-default frame-type-textmedia frame-layout-0" #c248>
              <header><h2 class="c248">
                15) Allgemeines
              </h2></header>
              <div class="ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Wir behalten uns das Recht vor, unsere Datenschutzerklärung zu verändern.
                  Dies
                  kann insbesondere wegen technischer Entwicklungen erforderlich werden. Deshalb bitten wir Sie, die
                  Datenschutzerklärung gelegentlich neu aufzurufen und die jeweils aktuelle Version zu beachten.</p>
                  <p>&nbsp;</p>
                  <p>Wenn Sie weitere Fragen zur Verarbeitung Ihrer personenbezogenen Daten haben, wenden Sie sich bitte
                    an den zuständigen Datenschutzbeauftragten.</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section-text pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div id="c250" class="frame frame-default frame-type-textmedia frame-layout-0" #c250>
              <div class="c250 ce-textpic ce-left ce-intext">
                <div class="ce-bodytext"><p>Datenschutzerklärung für <a href="https://www.phoenix-apothekenportal.de/"
                                                                        target="_blank">https://www.phoenix-apothekenportal.de/</a>&nbsp;und
                  für alle Aktivitäten der PHOENIX Pharmahandel GmbH &amp; Co KG ab dem 15.10.2019</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>
