import {Action} from '@ngrx/store';
import {TrackRequestModel} from '../../models/shop/track-request.model';
import {HttpErrorResponse} from '@angular/common/http';

export enum TrackActionsTypes {
    TRACK_START = '[TRACK] Start',
    TRACK_COMPLETE = '[TRACK] Complete',
    TRACK_FAILED = '[TRACK] Failed'
}


export class StartTrackAction implements Action {
    readonly type = TrackActionsTypes.TRACK_START;

    constructor(public payload: TrackRequestModel) {
    }
}

export class CompleteTrackAction implements Action {
    readonly type = TrackActionsTypes.TRACK_COMPLETE;

    constructor(public payload: any) {
    }
}

export class FailTrackAction implements Action {
    readonly type = TrackActionsTypes.TRACK_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export type TrackAndTraceActions =
    StartTrackAction |
    FailTrackAction |
    CompleteTrackAction;
