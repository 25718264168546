<div id="content" class="mb-0">
  <img
      src="../../assets/dummy/header-livsane.jpg"
      alt="Das LIVSANE Sortiment">
  <div class="panel-without-border mb-0">
    <h1>Die exklusive LIVSANE-Produktwelt – in Ihrer Apotheke</h1>
    <p>
      Seit 2017 sind wir mit unserer europaweiten Eigenmarke LIVSANE für alle Apotheken der PHOENIX group aktiv. Das Sortiment bietet qualitativ hochwertige Produkte für die ganze Familie. Zum Beispiel zur sinnvollen Nahrungsergänzung oder im Bereich der Diagnostik. LIVSANE steht für höchste Qualität durch sorgfältig ausgewählte Lieferanten - abgesichert durch permanente Qualitätskontrollen.    </p>
    <p>
      Unser Claim <strong>LIVSANE – Liebe Dein Leben</strong> steht für ein positives Lebensgefühl und schafft Identifikationspunkte. Unsere Botschaft: Das Leben ist es wert, es zu genießen. Auch, wenn nicht immer alles perfekt ist.    </p>
    <p>
      Von abenteuerlustigen Kids über unerschrockene Eltern bis hin zu smarten Senioren: Die Produktwelt von LIVSANE ist für alle da, und doch ebenso individuell wie Ihre Kundinnen und Kunden. Egal, ob im Alltag, im Beruf oder in der Freizeit: Unsere Produkte richten sich an jedes Alter und unterstützen in jeder Lebenslage.    </p>
    <h2 class="mt-l">Das LIVSANE Sortiment</h2>
    <p>
      Sie möchten Ihr Sortiment erweitern? Wählen Sie dabei aus folgenden Kategorien:
    </p>
    <div class="categories">
      <p><span class="leaf orange"></span> Gesundheit & Vorsorge</p>
      <p><span class="leaf green"></span> Medizinprodukte</p>
      <p><span class="leaf pink"></span> Diagnostik & Unterstützung</p>
      <p><span class="leaf blue"></span> Erste Hilfe</p>
      <p><span class="leaf lemon"></span> Schmerzen (OTC)</p>
    </div>


    <div class="row mt-xxl">
      <div class="col-md-3">
        <a target="_blank">
          <img src="../../assets/dummy/livsane-products.jpg"
               class="img-border">
        </a>
      </div>
      <div class="col-md align-items-center d-flex"><a
          href="../../assets/dummy/LIVSANE%20Infos%20zu%20platzierten%20Produkten%20ApothekenTour%202024.pdf"
          target="_blank" class="linki"><strong>LIVSANE Produktinfos</strong></a>&nbsp;
      </div>
<!--      <div class="col-md align-items-center d-flex">-->
<!--        <a class="download-button btn btn-primary btn-round"-->
<!--           target="_blank">-->
<!--          <span class="arrow-helper far fa-arrow-down"></span>-->
<!--        </a>-->
<!--      </div>-->
    </div>

  </div>
</div>
<section class="green-square">
  <h2>Lassen Sie sich dazu einfach von unserem Sortiment inspirieren:</h2>
  <div class="d-flex justify-content-center">
    <a>
      <button class="btn-primary on-dark-bg mr-l">
        Produktkatalog
      </button>
    </a>
    <a>
      <button class="btn-primary on-dark-bg">
        Website
      </button>
    </a>
  </div>
</section>
