import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from "rxjs/internal/Observable";
import * as fromDokuLightActions from '../actions/doku-light.actions';
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {InvoicesRestService} from "../../services/api/invoices.rest-service";
import {WebshopRestService} from "../../services/api/webshop-rest.service";
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductType} from '../../models/shop/product-type.model';

@Injectable()
export class DokuLightEffects {
    /**
     * In case of SearchInvoicesAction we're going to call the InvoicesService and then
     * we'll post a LoadInvoicesCompleteAction to the reducer or in case of failure
     * LoadInvoicesFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadDokuLight$: Observable<any> = this.actions$
        .pipe(ofType<fromDokuLightActions.LoadDokuLightAction>(fromDokuLightActions.DokuLightActionTypes.LOAD_DOKU_LIGHT))
        .pipe(map(action => action.payload), switchMap((productRequest) =>
            this.productRestService.loadDokuLight(productRequest).pipe(
                switchMap(data => {
                    const prodResp = new ProductsResponse({...data.returnObject, ...{type: ProductType.DokuLight}});
                    if (productRequest.page === 1) {
                        return [new fromDokuLightActions.LoadDokuLightCompleteAction(prodResp), new fromDokuLightActions.GetBatchFromCacheAction()];
                    } else {
                        return [new fromDokuLightActions.LoadDokuLightCompleteAction(prodResp)];
                    }
                }),
                catchError((error) => of(new fromDokuLightActions.LoadDokuLightFailAction(error)))
            )
        ));

    @Effect()
    searchDokuLight$: Observable<any> = this.actions$
        .pipe(ofType<fromDokuLightActions.SearchShopAction>(fromDokuLightActions.DokuLightActionTypes.SEARCH_DOKU_LIGHT))
        .pipe(map(action => action.payload), switchMap((productRequest) => [new fromDokuLightActions.LoadDokuLightAction(productRequest)]));




    /**
     * @param {InvoicesRestService} productRestService
     * @param {Actions} actions$
     */
    constructor(
        private productRestService: WebshopRestService,
        private actions$: Actions
    ) {
    }
}
