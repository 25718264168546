<h1>
  Übersicht Kooperationspartner
</h1>
<p>
  <strong>Starke Partner für ein starkes Sortiment.</strong> In enger Zusammenarbeit mit über 50 Partnern sowie mit einem apothekerlichen Beratungskreis, profitieren Sie monatlich von attraktiven Produktempfehlungswochen.
</p>
<img src="../../assets/dummy/Kooperationspartner_Image.jpg" class="img-fluid">
<div class="row mt-s">
  <div class="col-md-3 align-items-center d-flex">
    <a target="_blank" class="flying-link">Übersicht</a>&nbsp;(1,5 MB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank"><span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>
