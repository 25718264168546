<div id="content" class="mb-0">
  <div class="panel-without-border">
    <h1>Unterlagen & Downloads</h1>
    <h2 class="mt-l">Aktuelle Produktinformationen</h2>
    <div class="accordion-style-1" id="importantInfos">
      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse1"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse1"
                  data-toggle="collapse"
                  type="button">
            <span>23.02.2024 </span><strong>Hersteller 1</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse1">
          <div class="card-body py-l">
            <span>23.02.2024 </span><a class="linki">Wichtige Informationen 1</a>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse2"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse2"
                  data-toggle="collapse"
                  type="button">
            <span class="vendorName">06.02.2024. </span><strong>Hersteller 2</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse2">
          <div class="card-body py-l">
            <span>06.02.2024. </span><a class="linki">Wichtige Informationen 2</a>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse3"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse3"
                  data-toggle="collapse"
                  type="button">
            <span class="vendorName">27.01.2024. </span><strong>Hersteller 3</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse3">
          <div class="card-body py-l">
            <span>27.01.2024 </span><a class="linki">Wichtige Informationen 3</a>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse4"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse4"
                  data-toggle="collapse"
                  type="button">
            <span class="vendorName">20.01.2024. </span><strong>Hersteller 4</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse4">
          <div class="card-body py-l">
            <span>20.01.2024 </span><a class="linki">Wichtige Informationen 4</a>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse5"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse5"
                  data-toggle="collapse"
                  type="button">
            <span class="vendorName">14.12.2023. </span><strong>Hersteller 5</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse5">
          <div class="card-body py-l">
            <span>14.12.2023 </span><a class="linki">Wichtige Informationen 5</a>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse6"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse6"
                  data-toggle="collapse"
                  type="button">
            <span class="vendorName">10.11.2023. </span><strong>Hersteller 6</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse6">
          <div class="card-body py-l">
            <span>10.11.2023 </span><a class="linki">Wichtige Informationen 6</a>
          </div>
        </div>
      </div>

      <h2 class="mt-l">Covid-19 Impfstoffe</h2>
      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse7"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse7"
                  data-toggle="collapse"
                  type="button">
            <strong>Hersteller</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse7">
          <div class="card-body py-l">
            <a class="linki">Wichtige Informationen</a>
          </div>
        </div>
      </div>
      <h2 class="mt-l">Covid-19 Laientests</h2>
      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse8"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse8"
                  data-toggle="collapse"
                  type="button">
            <strong>Produkt 1 - PZN 25362526</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse8">
          <div class="card-body py-l">
            <a class="linki">PZN 25362526 Wichtige Informationen 1</a><br>
            <a class="linki">PZN 25362526 Wichtige Informationen 2</a><br>
            <a class="linki">PZN 25362526 Wichtige Informationen 3</a>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse9"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse9"
                  data-toggle="collapse"
                  type="button">
            <strong>Produkt 2 - PZN 92432148</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse9">
          <div class="card-body py-l">
            <a class="linki">PZN 92432148 Wichtige Informationen 1</a><br>
            <a class="linki">PZN 92432148 Wichtige Informationen 2</a><br>
            <a class="linki">PZN 92432148 Wichtige Informationen 3</a><br>
            <a class="linki">PZN 92432148 Wichtige Informationen 4</a>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse10"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse10"
                  data-toggle="collapse"
                  type="button">
            <strong>Produkt 3 - PZN 24163748</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse10">
          <div class="card-body py-l">
            <a class="linki">PZN 24163748 Wichtige Informationen 1</a><br>
            <a class="linki">PZN 24163748 Wichtige Informationen 2</a><br>
            <a class="linki">PZN 24163748 Wichtige Informationen 3</a><br>
            <a class="linki">PZN 24163748 Wichtige Informationen 4</a><br>
            <a class="linki">PZN 24163748 Wichtige Informationen 5</a>
          </div>
        </div>
      </div>
      <h2 class="mt-l">Atemschutzmasken</h2>
      <p>
        Der Vogel wirft ein wunderschönes Kleid über sie. Auf dem Fest wird sie von allen bewundert, der Königssohn tanzt nur mit ihr und lässt auch nicht zu, dass sie mit einem anderen tanzt. Abends verschwindet sie, und der Königssohn kennt nun zwar ihr Haus, findet aber nicht das Mädchen. Der Vater hat allerdings den Verdacht, die unbekannte Schöne könnte Aschenputtel sein.
      </p>
      <h2 class="mt-l">Zertifizierte Hersteller</h2>
      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse13"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse13"
                  data-toggle="collapse"
                  type="button">
            <strong>Hersteller 1 - PZN 45678321</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse13">
          <div class="card-body py-l">
            <a class="linki">PZN 45678321 Wichtige Informationen 1</a><br>
            <a class="linki">PZN 45678321 Wichtige Informationen 2</a><br>
            <a class="linki">PZN 45678321 Wichtige Informationen 3</a>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse12"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse12"
                  data-toggle="collapse"
                  type="button">
            <strong>Hersteller 2 - PZN 12345678</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse12">
          <div class="card-body py-l">
            <a class="linki">PZN 12345678 Wichtige Informationen 1</a><br>
            <a class="linki">PZN 12345678 Wichtige Informationen 2</a><br>
            <a class="linki">PZN 12345678 Wichtige Informationen 3</a><br>
            <a class="linki">PZN 12345678 Wichtige Informationen 4</a><br>
            <a class="linki">PZN 12345678 Wichtige Informationen 5</a><br>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <button aria-controls="infoCollapse11"
                  aria-expanded="false"
                  class="btn btn-link btn-collapsible collapsed"
                  data-target="#infoCollapse11"
                  data-toggle="collapse"
                  type="button">
            <strong>Hersteller 3 - PZN 98765432</strong>
          </button>
        </div>
        <div class="collapse"
             data-parent="#importantInfos"
             id="infoCollapse11">
          <div class="card-body py-l">
            <a class="linki">PZN 98765432 Wichtige Informationen 1</a><br>
            <a class="linki">PZN 98765432 Wichtige Informationen 2</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
