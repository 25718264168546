import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datev-info',
  templateUrl: './datev-info.component.html',
  styleUrls: ['./datev-info.component.scss']
})
export class DatevInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
