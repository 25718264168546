import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warenbevorratungen',
  templateUrl: './warenbevorratungen.component.html',
  styleUrls: ['./warenbevorratungen.component.scss']
})
export class WarenbevorratungenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
