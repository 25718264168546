<h1>
  <img src="assets/img/tiles/svg/LINDA_beauftragtenprogramm.svg" width="35"/> Beauftragtenprogramm
</h1>
<p>
<h2>Gemeinsam wachsen</h2>
<p>Wir freuen uns, dass Sie Teil des Beauftragtenprogramms sind und wir mit Ihnen die neue PHOENIX Kooperation gestalten
  und gemeinsam wachsen lassen können!
</p>
<p>
  Durch einen engen Austausch werden wir Ihnen alle Vorteile der Kooperation aufzeigen und Ihnen mit Tipps und
  Zusatzwissen die bestmögliche Voraussetzung bieten, um Sie in der Apotheke vor Ort zu unterstützen.
</p>
<p>
  Sie erhalten von uns regelmäßige Newsletter-Updates über anstehende Aktionen, Tipps zur Kundenansprache sowie
  exklusive Schulungen und Gewinnspiele.
</p>
<p>
  Exklusiv finden Sie als Kooperationsbeauftragte*r nachfolgend alle Informationen zum Nachlesen sowie bei Bedarf einen
  direkten Ansprechpartner für ein persönliches Gespräch.
</p>
<p>
  Gemeinsam mit Ihnen freuen wir uns auf den Austausch und darauf, die Kooperation mit Leben zu füllen und gemeinsam zu
  wachsen!
</p>
<p>
  Es grüßt Sie herzlich, Ihr Kooperationsbeauftragten-Team.
</p>
<div class="row mt-l">
  <div class="col-md-2">
    <a target="_blank" class="linki">
      <img src="../../assets/dummy/Newsletter_Beauftragtenprogramm_Schulung.jpg" class="img-border" />
    </a>
  </div>
  <div class="col-md-5 align-items-center d-flex">
    <a target="_blank">Newsletter Beauftragtenprogramm Schulung</a>&nbsp;(456 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank"><span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
<h2 class="mt-xxl pb-l">Sie möchten uns einen Kooperationsbeauftragten melden oder einen anderen Beauftragten aus Ihrem Team benennen?</h2>

<form class="row">
  <div class="col-md-9">
  <mat-checkbox type="checkbox" class="mat-checkbox mat-accent ng-untouched ng-pristine ng-invalid" id="mat-checkbox-1" required="">
    Ja, ich möchte einen Kooperationsbeauftragten für unsere Apotheke melden.
  </mat-checkbox>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Kooperationsbeauftragter</mat-label>
      <input matInput readonly>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Tätigkeit in der Apotheke</mat-label>
      <input matInput readonly>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Telefonnummer bei Rückfragen</mat-label>
      <input matInput readonly>
    </mat-form-field>
  </div>
  <div class="col-md-12">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label style="white-space: pre" class="textarea-label">Anmerkungen und Fragen</mat-label>
      <textarea matInput
                readonly></textarea>
    </mat-form-field>
    <button class="btn-primary on-dark-bg mr-l">
      Anfrage Senden
    </button>
  </div>
</form>


<div class="row mt-xxl">
  <div class="col-md-12">
    <h2>Ansprechpartner bei Rückfragen</h2>
  </div>
</div>
<div class="row">
  <div class="col-xl-6 mb-s">
    <div class="media">
      <img
          src="../../assets/dummy/2023_PKP_beauftragtenprogramm_AP_jessy_winter_web.jpg"
          alt="" class="mr-3" width=128>
      <div class="media-body">
        <strong class="mb-s d-block">Jessy Winter</strong>
        0 621 850 5 - 8533<br>
        <a class="linki">kooperation@phoenixgroup.eu</a>
      </div>
    </div>
  </div>

  <div class="col-xl-6 mb-s">
    <div class="media">
      <img
          src="../../assets/dummy/2023_PKP_beauftragtenprogramm_AP_roger_werner_web.jpg"
          alt="" class="mr-3" width=128>
      <div class="media-body">
        <strong class="mb-s d-block">Roger Werner</strong>
        0 621 850 5 - 445<br>
        <a class="linki">kooperation@phoenixgroup.eu</a>
      </div>
    </div>
  </div>
</div>
