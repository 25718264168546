import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject, Observable, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RetourenRestService extends RestServiceAbstract {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public returnRequest(rq) {
        return this.post('/resource/order/return-request', rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public returnAnnouncement(rq) {
        return this.post(`/resource/order/return-announcement`, rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public getStorageDetails(pzn) {
        return this.get(`/resource/product/v2/storage-details?pzn=${pzn}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public getReturnDocument(rq) {
        this.postAndGetBlob('/resource/order/return-document', rq)
            .subscribe(
                (response: any) => {
                    if (response) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(response);
                        const filename = rq.idf + '-' + rq.documentId + '.pdf';
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(response, filename);
                        } else {
                            link.download = filename;
                            link.dispatchEvent(new MouseEvent(`click`, {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            }));
                        }
                        link.remove();
                    }
                }
            );
    }
}
