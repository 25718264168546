import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AccountService} from '../../../../core/services/api/account.service';
import {FullAccountInformation} from '../../../../core/models/account/full-account-information.model';
import {UserService} from '../../../../core/services/user.service';
import {User} from '../../../../core/models/user.model';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ChangePasswordDialogComponent} from './change-password/change-password-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {ManageAccountDialogComponent} from './manage-account/manage-account-dialog.component';
import {ManageOwnerDialogComponent} from './manage-owner/manage-owner-dialog.component';
import {OwnerData} from "../../../../core/models/account/owner-data.model";
import {AccountData} from "../../../../core/models/account/account-data.model";

@Component({
  selector: 'account-data',
  templateUrl: './account-data.component.html',
  styleUrls: ['./account-data.component.scss']
})
export class AccountDataComponent implements OnInit {

  public ngUnsubscribe$: Subject<any> = new Subject<any>();
  public user: User;
  public accountData: FullAccountInformation;
  public alert: string = null;
  public errorMessage: string;


  constructor(@Inject(AccountService) public accountService: AccountService,
              @Inject(UserService) public userService: UserService,
              public dialog: MatDialog) {

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  onUpdatePassword(): void {
    this.alert = null;
    this.errorMessage = null;
    const changePasswordConfig = new MatDialogConfig();
    changePasswordConfig.autoFocus = true;
    changePasswordConfig.width = '50vw';
    this.dialog.open(ChangePasswordDialogComponent, changePasswordConfig).afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      if (result.data === 'success') {
        this.alert = 'passwordSuccess';
      } else if (result.data === 'cancel') {
        // pass
      } else if (result.data) {
        this.alert = 'failure';
        this.errorMessage = 'MESSAGE.ERROR.' + result.data;
      }
    })

  }

  onUpdateOwnerData() {
    this.alert = null;
    this.errorMessage = null;
    const updateOwnerDataConfig = new MatDialogConfig();
    updateOwnerDataConfig.autoFocus = true;
    updateOwnerDataConfig.minWidth = '50vw';
    updateOwnerDataConfig.width = 'auto';
    updateOwnerDataConfig.minHeight = 'auto';
    updateOwnerDataConfig.data = {owner: this.accountData.owner};
    this.dialog.open(ManageOwnerDialogComponent, updateOwnerDataConfig).afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      if (result.data === 'success') {
        this.alert = 'accountSuccess';
      } else if (result.data === 'cancel') {
        // pass
      } else if (result.data) {
        this.alert = 'failure';
        this.errorMessage = 'MESSAGE.ERROR.' + result.data;
      }
    })
  }


  onUpdataAccountData(account) {
    this.alert = null;
    this.errorMessage = null;
    const updateAccountDataConfig = new MatDialogConfig();
    updateAccountDataConfig.autoFocus = true;
    updateAccountDataConfig.minWidth = '50vw';
    updateAccountDataConfig.width = 'auto';
    updateAccountDataConfig.minHeight = 'auto';
    const ownerDetails = {owner: this.accountData.owner};
    const accountObj = {...ownerDetails, ...account};
    updateAccountDataConfig.data = {account: accountObj};
    this.dialog.open(ManageAccountDialogComponent, updateAccountDataConfig).afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      if (result.data === 'success') {
        this.alert = 'accountSuccess';
      } else if (result.data === 'cancel') {
        // pass
      } else if (result.data) {
        this.alert = 'failure';
        this.errorMessage = 'MESSAGE.ERROR.' + result.data;
      }
    })
  }


  ngOnInit(): void {
    this.user = new User({
      firstName: 'Sabine',
      lastName: 'Test',
      title: '',
      salutation: 'Frau',
      authorities: [],
      authoritiesEnum: [],
      username: 'sabine.test',
      featuresEnum: [],
      canary: false
    });

    this.accountData = {
      owner: new OwnerData(
          {
            firstName: 'Sabine',
            lastName: 'Test',
            title: '',
            salutation: 'Frau'
          }),
      accounts: [
        new AccountData({
          name: 'Testapotheke',
          street: 'Probestrasse 5',
          zipCode: '68219',
          city: 'Mannheim',
          email: 'info@testapotheke.de',
          phone: '0621 1234567',
          fax: '0621 1234568',
          idf: '9999910'
        })
      ]
    };
  }
}
