<h1>
  Bestellung Werbemittel
</h1>

<div class="row">
  <div class="col-md-6">
    <p>
      Sie benötigen zusätzliches Werbematerial oder möchten etwas nachbestellen?
      Im PHOENIX Store werden Sie fündig!
    </p>
    <ul>
      <li>Einfache Nachbestellung von Regalhighlightern oder LINDA Partner Aufklebern</li>
      <li>Kostenlose Social Media Vorlagen passend zur jeweiligen Monatsaktion</li>
      <li>Vorbestellfunktion des Endkundenmagazins „be healthy!“ und „Mein Ratgeber Diabetes“</li>
      <li>PAYBACK Werbemittel, Give-Aways, kostenfreie Grafiken und vieles mehr</li>
    </ul>
  </div>
  <div class="col-md-6">
    <img src="../../assets/dummy/Bestellung_Werbemittel.png"
         class="img-fluid" alt="Bestellung Werbemittel">
  </div>
</div>
<button class="btn-primary on-dark-bg">Zum Phoenix Store</button>

