import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {RevenueRestService} from '../../../../core/services/api/revenue-rest.service';
import {UserService} from '../../../../core/services/user.service';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {RevenueResponseInterface} from './interface/revenue-response.interface';
import {GlobalConstants} from '../../../../core/constants/global.constants';
import {jsPDF} from 'jspdf';


@Component({
  selector: 'my-sales',
  templateUrl: './my-sales.component.html',
  styleUrls: ['./my-sales.component.scss']
})
export class MySalesComponent implements OnInit, OnDestroy {
  public revenue: RevenueResponseInterface;
  public idfs$: Observable<any[]>;
  public currentlyDisplayedIdf: string;
  public options;
  public chart;
  public echartsInstance;
  public loading = true;
  public ngUnsubscribe$ = new Subject<any>();
  public input = false;
  public mainIdf: string;
  public today: Date = new Date();
  public digitalPaket = false;

  constructor(@Inject(RevenueRestService) public revenueApiService: RevenueRestService,
              @Inject(UserService) public userService: UserService,
              @Inject(GlobalConstants) public contants: GlobalConstants) {
    this.input = true;
    this.digitalPaket = this.userService.isDigitalPaket();
    this.currentlyDisplayedIdf = '9999910';
    this.mainIdf = '9999910';
    this.fakeGraph(this.currentlyDisplayedIdf);
  }


  ngOnInit() {
  }

  public loadRevenue(idf: string) {
    this.loading = true;
    this.digitalPaket = this.userService.isDigitalPaket();

    if (idf.length <= 0) {
      idf = this.mainIdf;
    }
    idf = this.formatIdf(idf);
    this.currentlyDisplayedIdf = idf;

    if (idf === this.contants.DUMMY_IDF) {
      this.fakeGraph(idf);
      return;
    }
    this.revenueApiService.requestRevenue(idf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
          this.revenue = res.returnObject as RevenueResponseInterface;
          const title = 'Monatlicher Umsatzverlauf';
          this.revenue.chart.series[1].name = 'RX-Umsatz inkl HP';
          this.revenue.chart.series[4].name = 'Non-RX-Umsatz';
          const filteredChart = Array.prototype.slice.call(this.revenue.chart.series);
          filteredChart.splice(2, 1);
          filteredChart.splice(0, 1);
          this.options = {
            title: {
              text: title,
              textStyle: {
                color: '#009775', fontFamily: 'Roboto Light', fontSize: 24
              }
            },
            color: [
              '#009775',
              '#92d400',
              '#393938',
              '#999999',
              '#91c7ae',
              '#749f83',
              '#92d400',
              '#bda29a',
              '#009775',
              '#999999',
              '#c4ccd3'
            ],
            tooltip: {
              trigger: 'axis',
              formatter: this.formatTooltip
            },
            legend: {
              data: [
                {
                  name: 'Non-RX-Umsatz',
                  icon: 'circle',
                  backgroundColor: '#92d400'
                }
                ,
                {
                  name: 'RX-Umsatz inkl HP',
                  icon: 'circle',
                  backgroundColor: '#009775'
                },
                {
                  name: 'HP-Umsatz',
                  icon: 'circle',
                  backgroundColor: '#535353'
                }
              ],
              bottom: 0,
              left: 80,
              itemGap: 35
            },
            grid: {
              left: '3%',
              right: '3%',
              bottom: '10%',
              top: '20%',
              containLabel: true,
            },
            xAxis: {
              axisLabel: {
                fontSize: 16,
                fontFamily: 'Roboto Light',
                color: '#999999',
                padding: [25, 0, 0, 0],
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              type: 'category',
              boundaryGap: false,
              data: this.revenue.chart.xAxis,
              padding: [0, 30, 0, 0]
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: this.formatYAxisLabel,
                fontSize: 16,
                fontFamily: 'Roboto Light',
                color: '#999999',
                padding: [0, 15, 5, 0],
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
            },
            series: filteredChart
          };

          if (this.dataEmpty(this.revenue.chart.series)) {
            this.options.series = [
              {
                name: 'Non-RX-Umsatz',
                type: 'line',
                data: [0],
              },
              {
                name: 'RX-Umsatz',
                type: 'line',
                data: [0],
              },
              {
                name: 'HP-Umsatz',
                type: 'line',
                data: [0],
              }
            ];
          }

          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  formatYAxisLabel(value) {
    return value.toLocaleString('de-DE') + '€';
  }

  formatTooltip(params, ticket, callback) {
    let res = params[0].name;
    for (let i = 0, l = params.length; i < l; i++) {
      res += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value.toLocaleString('de-DE') + '€';
    }
    return res;
  }

  formatIdf(idf) {
    return ('0000000' + idf).slice(-7);
  }

  dataEmpty(series) {
    for (const data of series) {
      if (data.data.length) {
        return false;
      }
    }
    return true;
  }

  fakeGraph(idf) {
    this.options = {
      title: {
        text: 'Monatlicher Umsatzverlauf',
        textStyle: {
          color: '#009775', fontFamily: 'Roboto Light', fontSize: 24
        }
      },
      color: [
        '#009775',
        '#92d400',
        '#393938',
        '#999999',
        '#91c7ae',
        '#749f83',
        '#92d400',
        '#bda29a',
        '#009775',
        '#999999',
        '#c4ccd3'
      ],
      tooltip: {
        trigger: 'axis',
        formatter: this.formatTooltip
      },
      legend: {
        data: [
          {
            name: 'Non-RX-Umsatz',
            icon: 'circle',
            backgroundColor: '#92d400'
          }
          ,
          {
            name: 'RX-Umsatz inkl HP',
            icon: 'circle',
            backgroundColor: '#009775'
          },
          {
            name: 'HP-Umsatz',
            icon: 'circle',
            backgroundColor: '#535353'
          }
        ],
        bottom: 0,
        left: 80,
        itemGap: 35
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '10%',
        top: '20%',
        containLabel: true,
      },
      xAxis: {
        axisLabel: {
          fontSize: 16,
          fontFamily: 'Roboto Light',
          color: '#999999',
          padding: [25, 0, 0, 0],
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        type: 'category',
        boundaryGap: false,
        data: ['Jan', 'Feb', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        padding: [0, 30, 0, 0]
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: this.formatYAxisLabel,
          fontSize: 16,
          fontFamily: 'Roboto Light',
          color: '#999999',
          padding: [0, 15, 5, 0],
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
      },
      series: [
        {
          name: 'Non-RX-Umsatz',
          type: 'line',
          data: [120, 132, 101, 134, 90, 230, 210, 120, 132, 101, 134, 90],
        },
        {
          name: 'RX-Umsatz inkl HP',
          type: 'line',
          data: [220, 182, 0, 234, 290, 330, 310, 220, 0, 191, 234, 290],
        },
        {
          name: 'HP-Umsatz',
          type: 'line',
          data: [320, 332.32, 301, 334, 390, 330, 320, 320, 332, 301, 334, 390],
        }
      ]
    };

    this.revenue = {chart: null, month: '', totalRevenue: null, rxPacketValue: null, rxSpanPercent: null};
    this.revenue.totalRevenue = 100;
    const now = new Date();

    this.revenue.month = String(new Date(now.getFullYear(), now.getMonth(), now.getDate()).toLocaleString('de', {month: 'long'}));

    this.loading = false;
  }

  onChartInit(ec) {
    this.echartsInstance = ec;
  }

  downloadChart() {
    const doc = new jsPDF();

    const imgData = this.echartsInstance.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#fff'
    });

    doc.addImage(imgData, 'PNG', 15, 40, 175, 75);
    doc.save(`Monatlicher Umsatzverlauf der IDF ${this.currentlyDisplayedIdf}.pdf`);
  }
}
