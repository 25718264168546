<h1>
  An-/Ummeldung
</h1>
<p>
  Unsere Kooperationsbeauftragten unterstützen wir in ihrem stressigen Apothekenalltag durch exklusive Insights zu spannenden Fachthemen und zahlreichen Tipps zur Endkundenaktivierung.
</p>
<h2 class="pb-l">Sie möchten uns eine/n Kooperationsbeauftragte*n melden oder einen anderen Beauftragten aus Ihrem Team benennen?</h2>
<form class="row">
  <div class="col-md-9">
    <mat-checkbox type="checkbox" class="mat-checkbox mat-accent ng-untouched ng-pristine ng-invalid" id="mat-checkbox-1" required="">
      Ja, ich möchte eine/n Kooperationsbeauftragte*n für unsere Apotheke melden.
    </mat-checkbox>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Vorname Kooperationsbeauftragte*r</mat-label>
      <input matInput readonly>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nachname Kooperationsbeauftragte*r</mat-label>
      <input matInput readonly>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Tätigkeit in der Apotheke</mat-label>
      <input matInput readonly>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Telefonnummer bei Rückfragen</mat-label>
      <input matInput readonly>
    </mat-form-field>
  </div>
  <div class="col-md-12">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label style="white-space: pre" class="textarea-label">Anmerkungen und Fragen</mat-label>
      <textarea matInput
                readonly></textarea>
    </mat-form-field>
    <button class="btn-primary on-dark-bg mr-l">
      Anfrage Senden
    </button>
  </div>
</form>

<p class="mt-xl">
  Erhalten Sie in unserem Video einen umfassenden Einblick zu den Highlights unseres Kooperationsbeauftragten-Programmes und den kommenden Themen in diesem Jahr.
</p>

<div class="text-center mt-l screen-background">
  <img src="../../assets/dummy/videoo.png">
</div>