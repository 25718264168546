export class TrackItem {
    order: String;
    delayInformation: String;
    delayInMinutes: number;
    deliveredAt: String;
    expectedTimeOfArrivalMax: Date;
    expectedTimeOfArrivalMin: Date;
    plannedTimeOfArrival: Date;
    tourId: number;
    center: String;
    package: Object[];

    constructor(obj: any = {}) {
        this.order = obj.order;
        this.tourId = obj.tourId;
        this.delayInformation = obj.delayInformation;
        this.delayInMinutes = obj.delayInMinutes;
        this.deliveredAt = obj.deliveredAt;
        this.expectedTimeOfArrivalMax = obj.expectedTimeOfArrivalMax ? new Date(obj.expectedTimeOfArrivalMax) : null;
        this.expectedTimeOfArrivalMin = obj.expectedTimeOfArrivalMin ? new Date(obj.expectedTimeOfArrivalMin) : null;
        this.plannedTimeOfArrival = obj.plannedTimeOfArrival ? new Date(obj.plannedTimeOfArrival) : null;
        this.center = obj.center;
        this.package = obj.package;
    }
}
