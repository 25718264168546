import {EventEmitter, Injectable} from '@angular/core';
import {formatDate} from '@angular/common';
import {BlobService} from './blob.service';
import {AdsRestService} from './api/cms/ads.rest-service';

@Injectable({
    providedIn: 'root',
})

export class AdsService {
    private ads = [];
    activeAds = new EventEmitter<any>();

    constructor(private adsRestService: AdsRestService) {
    }

    // async getAds() {
        // if (this.ads.length > 0) {
        //     return this.ads;
        // }
        // const allAds = JSON.parse(<string>await this.blobService.getData('ads.json'));
        // const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'de_DE');
        // for (const ad of allAds) {
        //     const startDate = formatDate(ad.start, 'yyyy-MM-dd', 'de_DE');
        //     const endDate = formatDate(ad.end, 'yyyy-MM-dd', 'de_DE');
        //
        //     if (currentDate >= startDate && currentDate <= endDate) {
        //         this.ads.push({
        //             title: ad.title,
        //             link: ad.link,
        //             image: ad.image,
        //             location: ad.location,
        //             target: ad.target
        //         });
        //     }
        // }
    // }

    showAd(ads, noprio=false) {
        let selectedAd:any[] = [];
        if(ads.length > 1) {
            const prio = ads.filter(ad => ad.prioritized===true);
            if(prio.length > 0 && noprio === false){
                selectedAd = prio;
            }else{
                selectedAd.push(ads[Math.floor(ads.length * Math.random())]);
            }
        }else{
            selectedAd = ads;
        }
        return selectedAd;
    }

    getMyAdsForLocation(location) {
        return this.adsRestService.getCurrentAds(location).subscribe(ads => {
           return this.ads = ads.return_object;

        });
    }
}
