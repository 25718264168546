import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Action} from '@ngrx/store';
import * as fromSubsequentDeliveriesActions from '../actions/subsequent-deliveries.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {SubsequentDeliveriesRestService} from '../../services/api/subsequent.deliveries.rest-service';

@Injectable()
export class SubsequentDeliveriesEffects {

    constructor(private actions$: Actions,
                private subsequentDeliveriesRestService: SubsequentDeliveriesRestService) {
    }

    loadSubsequentDeliveries$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromSubsequentDeliveriesActions.LoadSubsequentDeliveries>(
                fromSubsequentDeliveriesActions.SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES))
            .pipe(map(action => action.payload),
                switchMap((subsequentDeliveriesRequest) =>
                    this.subsequentDeliveriesRestService.loadSubsequentDeliveries(subsequentDeliveriesRequest)
                        .pipe(
                            map((data) => new fromSubsequentDeliveriesActions.LoadSubsequentDeliveriesCompleted(data.returnObject)),
                            catchError((error) => of(new fromSubsequentDeliveriesActions.FailSubsequentDeliveries(error)))
                        )
                )
            );
    });
}
