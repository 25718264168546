<div id="app">
  <div id="view" class="d-flex flex-column mx-m">
    <app-nav></app-nav>
    <div class="main-grid" id="visible-view">
      <app-ads [position]="'LEFT'"></app-ads>
      <div id="main-content">
        <app-notices></app-notices>
        <ng-container>
          <router-outlet></router-outlet>
        </ng-container>
        <app-chatbot></app-chatbot>
        <app-footer></app-footer>
      </div>
      <app-ads [position]="'RIGHT'"></app-ads>
    </div>
<!--    <app-dialog-popup></app-dialog-popup>-->
  </div>
</div>