import {Product} from './product.model';

export class CartItem {

    product: Product;
    price: number;
    availability: any;

    constructor(obj: any = {}) {
        this.product = obj && obj.product || new Product();
        this.quantity = obj && obj.quantity || 0;
        this.availability = obj && obj.availability || null;
    }

    private _quantity: number;

    get quantity(): number {
        return this._quantity;
    }

    set quantity(value: number) {
        this._quantity = value;
        for (let key in this.product.price) {
            if (this.product.price[key].minQuantity > 0) {
                if (+this._quantity >= +this.product.price[key].minQuantity) {
                    this.price = this.product.price[key].cents;
                }
            }
        }
    }
}