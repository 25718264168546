import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReklamationComponent} from './reklamation.component';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {RouterModule} from '@angular/router';
import {ReklamationsService} from '../../../../core/services/reklamations.service';

export const routes = [
  {path: '', component: ReklamationComponent, pathMatch: 'full'},
];

@NgModule({
  declarations: [
    ReklamationComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    ReklamationsService
  ]
})
export class ReklamationModule {
  static routes = routes;
}
