import { Component, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-offizin-im-blick',
  templateUrl: './offizin-im-blick.component.html',
  styleUrls: ['./offizin-im-blick.component.scss']
})
export class OffizinImBlickComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit(): void {
  }

}
