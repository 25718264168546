<div id="content" class="mb-0">
  <div class="panel-without-border">
    <h1>Impressum</h1>
    <p>PHOENIX Pharmahandel GmbH &amp; Co KG<br> Pfingstweidstraße 10-12<br> 68199 Mannheim<br>
      Telefon: +49 (0)621 8505-0<br> Fax: +49 (0)621 85 40 31<br> E-Mail: <a class="linki">apothekenportal@phoenixgroup.eu</a><br><br>
      Sitz der Hauptniederlassung: Mannheim<br> Registergericht: Amtsgericht Mannheim HRA 3551<br>
      Umsatzsteueridentifikationsnummer: DE 811 737 260<br>
      <span>Gläubiger-Identifikationsnummer: DE43ZZZ00000032466</span><br><br>
      Persönlich haftende Gesellschafterin:<br> PHOENIX Verwaltungs GmbH<br><span>Zweigniederlassung Deutschland</span><br>
      <span>Registergericht: Amtsgericht Mannheim HRB 718800</span><br><span>Hauptniederlassung</span><br><span>Sitz: Vaduz/Liechtenstein</span><br>
      <span>Handelsregister Liechtenstein Nr. FL-0002.468.204-0x</span>
    </p>
    <p>Geschäftsführung:&nbsp;<br> Sven Seidel (Vorsitzender)<br> Stephen Anderson<br> Marcus Freitag<br> Stefan Herfeld<br>
      Dr. Carsten Sauerland<br> Dr. Roland Schütz</p>
    <p><a
        class="linki"
        href="../../assets/dummy/2023-09-01_ARB_Allgemeine%20Retourenbedingungen_final.pdf">Zuständige Aufsichtsbehörden (PDF)&nbsp;</a></p></div>

</div>



