import {Component, Inject, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UserService} from '../../core/services/user.service';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit {
  public ads = [];
  private ngUnsubscribe$ = new Subject<any>();
  private selected = null;
  @Input() public position: string;

  constructor(@Inject(UserService) private userService: UserService) {
  }

  ngOnInit(): void {
    if (this.ads.length < 1) {
    }
  }

}
