import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {SubsequentDeliveryModel} from '../../models/subsequent-deliveries/subsequent.delivery.model';
import {SubsequentDeliveriesRequest} from '../../models/subsequent-deliveries/subsequent.deliveries.request';
import {SubsequentDeliveriesResponse} from '../../models/subsequent-deliveries/subsequent.deliveries.response';

export enum SubsequentDeliveriesActionsType{
    INIT_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Init',
    LOAD_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Load',
    LOAD_SUBSEQUENT_DELIVERIES_COMPLETED = '[SUBSEQUENT DELIVERIES] Load Completed',
    UPDATE_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Update',
    DELETE_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Delete',
    FAIL_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Failed'
}

export class InitSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.INIT_SUBSEQUENT_DELIVERIES;

    constructor() { // == Query
    }
}

export class LoadSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES;

    constructor(public payload: any) { // == Query
    }
}

export class LoadSubsequentDeliveriesCompleted implements Action{
    readonly type = SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES_COMPLETED;

    constructor(public payload: any) { // == Query
    }
}

export class UpdateSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.UPDATE_SUBSEQUENT_DELIVERIES;

    constructor(public payload: any) { // == Query
    }
}

export class DeleteSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.DELETE_SUBSEQUENT_DELIVERIES;

    constructor(public payload: any) { // == Query
    }
}

export class FailSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.FAIL_SUBSEQUENT_DELIVERIES;

    constructor(public payload: HttpErrorResponse) { // == Query
    }
}

export type SubsequentDeliveriesActions = InitSubsequentDeliveries
| LoadSubsequentDeliveries
| LoadSubsequentDeliveriesCompleted
| UpdateSubsequentDeliveries
| DeleteSubsequentDeliveries
| FailSubsequentDeliveries;