<div id="footer">
    <div class="meta-footer d-md-flex meta-footer d-md-flex align-items-center flex-wrap">
        <img id="logo" class="mr-4" src="assets/img/phoenix-logo_corp.svg" alt="PHOENIX Pharmahandel GmbH & Co KG"/>
        <div class="flex-grow-1 text-left">
            <a class="mr-2 pb-1">
                Impressum
            </a>
            <a class="mr-2 pb-1">
                Nutzungsbedingungen
            </a>
            <a class="mr-2 pb-1">
                Verkaufs- und Lieferbedingungen
            </a>
            <a class="mr-2 pb-1">
                Retourenbedingungen
            </a>
            <a class="mr-2 pb-1">
                Datenschutz
            </a>
        </div>

        <p class="copyright">© {{ currentYear }} PHOENIX Pharmahandel GmbH & Co KG</p>
    </div>
</div>
