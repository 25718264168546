import { Component, OnInit } from '@angular/core';
import {ReklamationsService} from '../../../../core/services/reklamations.service';

@Component({
  selector: 'app-reklamation',
  templateUrl: './reklamation.component.html',
  styleUrls: ['./reklamation.component.scss']
})
export class ReklamationComponent implements OnInit {
  currentlyDisplayedIdf = 9999910;
  lastOnClickRefreshTime = new Date();
  reklamationList = {
    recent: [],
  archive: []};

  constructor(private reklamationService: ReklamationsService) { }

  ngOnInit(): void {
    this.reklamationList = this.reklamationService.getData();
  }

  onRefresh() {
    this.lastOnClickRefreshTime = new Date();
  }
}
