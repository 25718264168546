<div id="content" class="mb-0">
  <img
      src="../../assets/dummy/csm_Offizin_im_Blick_headerimage.jpg"
      alt="Offizin im Blick - Das Expertenpanel">
  <div class="panel-without-border mb-0">
    <h1>Offizin im Blick - Das Expertenpanel</h1>
    <h2 class="mt-l">Gestalten Sie mit uns die Apotheke der Zukunft</h2>

    <p>
      Die Basis für eine erfolgreiche und kontinuierliche Zusammenarbeit ist gegenseitiges Verständnis. Daher ist Ihre Meinung für PHOENIX unverzichtbar und wir laden Sie ein, Teil unseres Expertenpanels „Offizin im Blick“ zu werden.    </p>
    <h2 class="mt-l">Was ist „Offizin im Blick“?</h2>
    <p>
      Wir haben exklusiv für Sie eine neutrale <a class="linki">Online-Befragungsplattform</a> geschaffen, auf der die unterschiedlichsten Themen offen und kritisch hinterfragt werden können. <strong>Dieses Onlinepanel können <u>ausschließlich ApothekerInnen</u> in Deutschland nutzen, die sich dazu bereit erklären, an Onlineumfragen teilzunehmen.</strong> Hier können Sie Ihr Fachwissen zu verschiedenen Themen einbringen und somit beispielsweise an der Gestaltung und Entscheidung von Konzepten mitwirken.
    </p>
    <h2 class="mt-l">Warum mitmachen?</h2>
    <p>
      Nur wenn wir genau wissen, was Sie sich von uns erwarten, können wir handeln. Daher ist Ihre Meinung für uns essentiell. Schließlich ist der Apothekenmarkt ständig in Bewegung: neue Trends, Gesetzesänderungen und softwaregesteuerte optimierte Arbeitsabläufe. Es ist uns deshalb ein großes Anliegen, dass Sie an unseren Experten-Befragungen teilnehmen.
    </p>
    <p>
      Zusätzlich haben Sie als PanelteilnehmerIn auf unserer <a class="linki">Homepage</a> Zugriff auf die Ergebnisse aller Umfragen und sind so immer bestens informiert!
    </p>
    <h2 class="mt-l">Ein weiterer Anreiz:</h2>
    <p>
      Für jede abgeschlossene Teilnahme an einer Umfrage von „Offizin im Blick“ spendet PHOENIX einen Geldbetrag an eine regionale gemeinnützige Einrichtung für Kinder.
    </p>
    <p>
      Bislang wurden dank der zahlreichen Unterstützung unserer Experten <strong>über 25.000 EURO</strong> an karitative Einrichtungen für Kinder gespendet.
    </p>
    <p>
      Sie sehen - Ihre Meinung bewegt somit <strong>auf alle Fälle!</strong>
    </p>
    <h2 class="mt-l">Ihre Vorteile auf einen Blick</h2>
    <div class="row mt-s">
      <div class="col-md-1 d-flex align-items-center">
          <span class="feature-image fal fa-2x fa-rocket"></span>
      </div>
      <div class="col-md-8">
        <h4>MITGESTALTEN:</h4>
        <p>Sehen Sie neue Dienstleistungen und Trends vor anderen Marktteilnehmern und helfen Sie uns, das künftige Angebot von PHOENIX bedarfsgerecht und am Puls der Zeit zu optimieren.</p>
      </div>
    </div>
    <div class="row mt-s">
      <div class="col-md-1 d-flex align-items-center">
        <span class="feature-image fal fa-2x fa-child"></span>
      </div>
      <div class="col-md-8">
        <h4>WOHLTÄTIG:</h4>
        <p>
          Für jede abgeschlossene Teilnahme an einer Umfrage wird PHOENIX einen Geldbetrag an eine regionale soziale Einrichtung für Kinder spenden.
        </p>
      </div>
    </div>
    <div class="row mt-s">
      <div class="col-md-1 d-flex align-items-center">
        <span class="feature-image fal fa-2x fa-shield-check"></span>
      </div>
      <div class="col-md-8">
        <h4>OHNE RISIKO:</h4>
        <p>
          Ihre Teilnahme ist völlig unverbindlich, kann jederzeit beendet werden und Sie gehen keinerlei Verpflichtung ein.
        </p>
      </div>
    </div>

    <h2 class="mt-l">Fordern Sie weitere Informationen an.</h2>
    <form class="row">
      <div class="col-md-9">
        <mat-checkbox class="mat-checkbox mat-accent ng-untouched ng-pristine ng-invalid" id="mat-checkbox-1">
          Ich möchte Mitglied bei Offizin im Blick werden.
        </mat-checkbox>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label style="white-space: pre" class="textarea-label">Anmerkungen und Fragen</mat-label>
          <textarea matInput readonly></textarea>
        </mat-form-field>
        <button class="btn-primary on-dark-bg mr-l">
          Anfrage Senden
        </button>
      </div>
    </form>
  </div>
</div>
<section class="green-square">
  <h2>Besuchen Sie die Website zum Expertenpanel</h2>
  <a>
    <button class="btn-primary on-dark-bg mr-l">
      www.offizin-im-blick.de
    </button>
  </a>
</section>
