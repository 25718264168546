import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IntegrationsComponent} from './integrations.component';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {DatevComponent} from './datev/datev.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {NotificationModule} from '../../../shared/notification/notification.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DatevLogsDialog} from './datev/datev.component';

export const routes = [
    {path: '', component: IntegrationsComponent, pathMatch: 'full'}
];

@NgModule({
    declarations: [
        IntegrationsComponent,
        DatevComponent,
        DatevLogsDialog
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        NotificationModule,
        MatSlideToggleModule
    ]
})
export class IntegrationsModule {
    static routes = routes;
}
