import {Action} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {ProductRequest} from "../../models/shop/product-request.model";

export enum DokuLightActionTypes {
    LOAD_DOKU_LIGHT = '[DOKU_LIGHT] Load DOKU_LIGHT',
    LOAD_DOKU_LIGHT_COMPLETE = '[DOKU_LIGHT] Load DOKU_LIGHT Complete',
    LOAD_DOKU_LIGHT_FAIL = '[DOKU_LIGHT] Load DOKU_LIGHT FAIL',
    SEARCH_DOKU_LIGHT = '[DOKU_LIGHT] Search',
    UPDATE_SCROLLING_POSITION = '[DOKU_LIGHT] Update Scrolling Position',
    GET_BATCH_FROM_CACHE = '[DOKU_LIGHT] Push batch in visible list'
}

export class LoadDokuLightAction implements Action {
    readonly type = DokuLightActionTypes.LOAD_DOKU_LIGHT;

    constructor(public payload: ProductRequest) { // == Query
    }
}

export class LoadDokuLightCompleteAction implements Action {
    readonly type = DokuLightActionTypes.LOAD_DOKU_LIGHT_COMPLETE;

    constructor(public payload: ProductsResponse) {
    }
}

export class LoadDokuLightFailAction implements Action {
    readonly type = DokuLightActionTypes.LOAD_DOKU_LIGHT_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class SearchShopAction implements Action {
    readonly type = DokuLightActionTypes.SEARCH_DOKU_LIGHT;

    constructor(public payload: ProductRequest) {};
}

export class UpdateScrollingPositionAction implements Action {
    readonly type = DokuLightActionTypes.UPDATE_SCROLLING_POSITION;

    constructor(public payload: number) {};
}

export class GetBatchFromCacheAction implements Action {
    readonly type = DokuLightActionTypes.GET_BATCH_FROM_CACHE;

    constructor() {};
}



export type DokuLightActions =
    LoadDokuLightAction |
    LoadDokuLightCompleteAction |
    LoadDokuLightFailAction |
    SearchShopAction |
    UpdateScrollingPositionAction |
    GetBatchFromCacheAction;