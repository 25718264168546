import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {UserService} from './user.service';


@Injectable()
export class RetourenGuardService implements CanActivate {

    constructor(private router: Router,
                public userService: UserService, ) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        return true;
    }

}
