import {Observable, Subject} from "rxjs";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {Store} from "@ngrx/store";
import * as fromReducers from "../../store/reducers";
import {Product} from "../../models/shop/product.model";
import {WebshopRestService} from "../api/webshop-rest.service";
import {takeUntil} from "rxjs/operators";
import {Inject, Directive} from "@angular/core";
import {ProductRequest} from '../../models/shop/product-request.model';

// TODO: Add Angular decorator.
@Directive()
export abstract class ProductServiceAbstract {
    ngUnsubscribe$: Subject<any> = new Subject<any>();

    /**
     * @param {Store<fromReducers.State>} store
     * @param {WebshopRestService} webshopRestService
     */
    public constructor(public store: Store<fromReducers.State>,
                          @Inject(WebshopRestService) public webshopRestService: WebshopRestService) {
    }

    private _productsResponse$: Observable<ProductsResponse>;
    get productsResponse$(): Observable<ProductsResponse> {
        return this._productsResponse$;
    }
    set productsResponse$(value: Observable<ProductsResponse>) {
        this._productsResponse$ = value;
    }

    private _productsRequest$: Observable<ProductRequest>;
    get productsRequest$(): Observable<ProductRequest> {
        return this._productsRequest$;
    }
    set productsRequest$(value: Observable<ProductRequest>) {
        this._productsRequest$ = value;
    }

    private _productsLoading: Observable<boolean>;
    get productsLoading(): Observable<boolean> {
        return this._productsLoading;
    }
    set productsLoading(value: Observable<boolean>) {
        this._productsLoading = value;
    }

    private _products: Observable<Product[]>;
    get products$(): Observable<Product[]> {
        return this._products;
    }
    set products$(value: Observable<Product[]>) {
        this._products = value;
    }

    private _scrollPosition: Observable<number>;
    get scrollPosition$(): Observable<number> {
        return this._scrollPosition;
    }
    set scrollPosition$(value: Observable<number>) {
        this._scrollPosition = value;
    }

    private _productCache: Observable<Product[]>;
    get productCache$(): Observable<Product[]> {
        return this._productCache;
    }
    set productCache$(value: Observable<Product[]>) {
        this._productCache = value;
    }


    /**
     * Update the stored scrolling position of a shop
     *
     * @param pos Position in px
     */
    abstract updateScrollingPosition(pos: number): void;

    /**
     * Dispatch product search
     *
     * @param request
     */
    abstract searchProducts(request: ProductRequest): void;


    /**
     * Display more from cache
     *
     * @param request
     */
    abstract displayNewBatch(request: ProductRequest): void;

    /**
     * @return {Observable<boolean>}
     */
    abstract getProductsLoadingObservable(): Observable<boolean>;

    /**
     * @return {Observable<ProductsResponse>}
     */
    abstract getProductsResponseObservable(): Observable<ProductsResponse>;

    /**
     * @return{Observable<ProductRequest>}
     */
    abstract getProductsRequestObservable(): Observable<ProductRequest>;

    /**
     * @return{Observable<Product[]>}
     */
    abstract getProductsObservable(): Observable<Product[]>;

    /**
     * @return{Observable<Product[]>}
     */
    abstract getProductCacheObservable(): Observable<Product[]>;

    /**
     * @return{Observable<number>}
     */
    abstract getScrollPositionObservable(): Observable<number>;




    /**
     * @param {string} pzn
     */
    findProductById(id: string): Product {
        let match: Product = null;
        this.getProductsResponseObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((sor: ProductsResponse) => {
            if (sor) {
                sor.products.forEach(product => {
                    if (product.id == id)
                        match = product;
                });
            }
        }).unsubscribe();
        return match;
    }


    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

  getParagraph73Products() {
    return [];
  }
}
