<img *ngIf="!showChat" (click)="openChat()" src="../../assets/dummy/2024-02_chatbot_animiert_v2.gif" class="img-chat" width="150" alt="Chatbot icon">
<div class="chat" *ngIf="showChat">
  <div class="chat-header text-white text-center position-relative">
    <h4 class="py-xs">PHOENIX Webchat</h4>
<!--    <button class="close minimize" type="button" (click)="minimizeChat()"><i class="fal fa-window-minimize"></i></button>-->
    <button class="close" type="button" (click)="closeChat()"><i class="fal fa-times"></i></button>
  </div>
  <div class="chat-body" #chat>
    <div class="scroll">
      <div *ngFor="let text of chatText" [ngClass]="text.type === 'chat-user' ? 'justify-content-end' : ''" class="d-flex align-items-center">
        <img src="../../assets/dummy/2023-09_chatbot.png" alt="" *ngIf="text.type==='chat-agent'" class="chat-head">
        <div class="{{text.type}}" *ngIf="text.text !== 'loading'">{{text.text}}</div>
        <div class="{{text.type}}" *ngIf="text.text === 'loading'"><img src="../../assets/dummy/gifgit.gif" alt="{{text.text}}" ></div>
      </div>
    </div>
    <div class="actions">
      <div *ngIf="action=='category'" class="chat-buttons-group">
        <button (click) = "setFlow('order', 'Bestellung aufgeben')" class="chat-button">Bestellung aufgeben</button>
        <button (click) = "setFlow('t&t', 'Sendungsverfolgung')" class="chat-button">Sendungsverfolgung</button>
        <button (click) = "setFlow('delivery', 'Liefertermine anfragen')" class="chat-button">Liefertermine anfragen</button>
        <button (click) = "setFlow('expiration', 'Verfallinformation')" class="chat-button">Verfallinformation</button>
        <button (click) = "setFlow('subsequent', 'Nachlieferung aufnehmen')" class="chat-button">Nachlieferung aufnehmen</button>
        <button (click) = "setFlow('availability', 'Verfügbarkeit anfragen')" class="chat-button">Verfügbarkeit anfragen</button>
      </div>
      <div *ngIf="action==='pzn'" class="chat-buttons-group">
        <button (click) = "setProduct('PZN 958298')" class="chat-button">PZN 958298</button>
        <button (click) = "setProduct( 'PZN 4356254')" class="chat-button">PZN 4356254</button>
      </div>
      <div *ngIf="action==='qty'" class="chat-buttons-group">
        <button (click) = "setQty('Menge 1')" class="chat-button">Menge 1</button>
        <button (click) = "setQty( 'Menge 10')" class="chat-button">Menge 10</button>
      </div>
      <div *ngIf="action==='t&t'" class="chat-buttons-group">
        <button (click) = "setFlow('t&t', 'Lieferstatus aktualisieren',  true)" class="chat-button">Lieferstatus aktualisieren</button>
        <button (click) = "resetFlow()" class="chat-button">Zurück zur Auswahl</button>
      </div>
      <div *ngIf="action==='expire'" class="chat-buttons-group">
        <button (click) = "setProduct('Artikel direkt bestellen', 'order')" class="chat-button">Artikel direkt bestellen
        </button>
        <button (click) = "setFlow('expiration', 'anderen Artikel anfragen', true)" class="chat-button">anderen Artikel anfragen
        </button>
        <button (click) = "resetFlow()" class="chat-button">Zurück zur Auswahl</button>
      </div>
      <div *ngIf="action==='available'" class="chat-buttons-group">
        <button (click) = "setQty('Artikel direkt bestellen', 'order')" class="chat-button">Artikel direkt bestellen
        </button>
        <button (click) = "setFlow('availability', 'anderen Artikel anfragen', true)" class="chat-button">anderen Artikel anfragen
        </button>
        <button (click) = "resetFlow()" class="chat-button">Zurück zur Auswahl</button>
      </div>
      <div *ngIf="action==='delivery'" class="chat-buttons-group">
        <button (click) = "setProduct('Artikel zur Nachlieferung aufnehmen', 'subsequent')" class="chat-button">Artikel zur Nachlieferung aufnehmen
        </button>
        <button (click) = "setFlow('delivery', 'anderen Artikel anfragen', true)" class="chat-button">anderen Artikel anfragen
        </button>
        <button (click) = "resetFlow()" class="chat-button">Zurück zur Auswahl</button>
      </div>
      <div *ngIf="action==='order'" class="chat-buttons-group">
        <button (click) = "setResult(true, 'Ja, bestellen!')" class="chat-button">Ja, bestellen!</button>
        <button (click) = "setResult(false, 'Nein, doch nicht.')" class="chat-button">Nein, doch nicht.</button>
      </div>
      <div *ngIf="action==='order-nach'" class="chat-buttons-group">
        <button (click) = "setResult(true, 'Ja, Nachlieferung aufnehmen!')" class="chat-button">Ja, Nachlieferung aufnehmen!</button>
        <button (click) = "setResult(false, 'Nein, doch nicht.')" class="chat-button">Nein, doch nicht.</button>
      </div>
      <div *ngIf="action==='nach'" class="chat-buttons-group">
        <button (click) = "setFlow('expiration', 'anderen Artikel anfragen')" class="chat-button">anderen Artikel anfragen
        </button>
        <button (click) = "setResult(false, 'Nein, doch nicht.')" class="chat-button">Nein, doch nicht.</button>
      </div>
    </div>
  </div>
  <div class="chat-footer">
    <i class="fas fa-paper-plane"></i>
  </div>
</div>
