import {Component, Inject, OnInit} from '@angular/core';
import {CartService} from '../../core/services/cart.service';
import {Totals} from '../../core/models/totals.model';
import {Observable} from 'rxjs';
import {CartItem} from '../../core/models/shop/cart-item.model';
import {AuthService} from '../../core/services/api/auth.service';
import {Router} from '@angular/router';
import {MessageResponse} from '../../core/models/message/message-response.model';
import {MessageService} from '../../core/services/message.service';
import {UserService} from '../../core/services/user.service';
import {Store} from '@ngrx/store';
import * as fromReducers from '../../core/store/reducers';
import {InitMessagesAction} from '../../core/store/actions/message.actions';

@Component({
  selector: 'app-quick-nav',
  templateUrl: './quick-nav.component.html',
  styleUrls: ['./quick-nav.component.scss']
})
export class QuickNavComponent implements OnInit {
  public totals$: Observable<Totals> = null;
  public cartItems$: Observable<CartItem[]> = null;
  public latestItems$: Observable<CartItem[]> = null;
  public mails$: Observable<MessageResponse> = null;

  constructor(@Inject(CartService) cartService: CartService,
              @Inject(AuthService) public authService: AuthService,
              @Inject(MessageService) public messageService: MessageService,
              @Inject(UserService) public userService: UserService,
              private router: Router,
              public store: Store<fromReducers.State>) {
    this.totals$ = cartService.getTotalsObservable();
    this.cartItems$ = cartService.getCartItemsObservable();
    this.latestItems$ = cartService.getLatestCartItemsObservable();
    if (this.canMail()) {
      this.store.dispatch(new InitMessagesAction());
      this.mails$ = messageService.getMessagesObservable();
    }
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('logout');
  }

  canMail(): boolean {
    return !this.userService.hasRequiredAuthority(['GROUP_KSC'])
        && !this.userService.hasRequiredAuthority(['GROUP_SALES'])
        && !this.userService.hasRequiredAuthority(['GROUP_MARKETING'])
        && this.userService.hasRequiredAuthority(['MAILBOX']);
  }

  canOrders(): boolean {
    return this.userService.hasRequiredAuthority(['ORDERS']) ||
        this.userService.hasRequiredAuthority(['PAYBACK']);
  }
}
