import {Component, Inject, OnInit} from '@angular/core';
import {VideoDialogComponent} from '../../../public/video-dialog/video-dialog.component';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-wissen',
  templateUrl: './wissen.component.html',
  styleUrls: ['./wissen.component.scss']
})
export class WissenComponent implements OnInit {

  constructor(
      public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      data: {type: 'pkp'},
    });
  }
}
