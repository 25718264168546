<div id="content" class="mb-0">
  <div class="panel-without-border">
    <h1>Nutzungsbedingungen für das PHOENIX Apothekenportal der PHOENIX Pharmahandel GmbH & Co KG</h1>
    <p>(Stand 17.10.2019) &nbsp;<br> &nbsp;
    </p>
    <h2>1. Geltung</h2>
    <p>1.1. Für die Nutzung des passwortgeschützten Bereichs des PHOENIX Apothekenportals („i.F. „PHOENIX Portal“)
      gelten die nachstehenden Allgemeinen Nutzungsbedingungen (i.F. „Nutzungsbedingungen“) der PHOENIX Pharmahandel
      GmbH &amp; Co KG (i.F.&nbsp;„PHOENIX“). Mit der Registrierung für die Nutzung des PHOENIX Portals erkennt der
      Nutzer diese Nutzungsbedingungen ohne Einschränkung und Vorbehalt an. Abweichende oder entgegenstehende
      Bedingungen erkennt PHOENIX nicht an.</p>
    <p>1.2. PHOENIX ist berechtigt, diese Nutzungsbedingungen zu ändern. In diesem Fall wird der Nutzer auf die
      Änderungen ausdrücklich hingewiesen und er muss diese erneut anerkennen. Des Weiteren ist PHOENIX berechtigt,
      zusätzliche Bedingungen für bestimmte Bereiche des PHOENIX Portals zu stellen. Diese gelten dann zusätzlich zu
      diesen Nutzungsbedingungen.</p>
    <p>1.3. Die Nutzungsbedingungen in ihrer jeweils aktuellen Fassung sind jederzeit unter <a
        class="linki">www.phoenix-apothekenportal.de/nutzungsbedingungen</a>
      abrufbar.</p>
    <p>1.4. Künftige mündliche Nebenabreden bedürfen zu ihrer Wirksamkeit der Schriftform, es sei denn, sie beruhen auf
      einer individuellen Vertragsabrede. Dies gilt auch für die Änderung oder Aufhebung dieser Schriftformklausel.</p>
    <p>1.5. Für Lieferungen, die über das PHOENIX Portal bestellt werden, gelten die gesondert zwischen dem Nutzer und
      PHOENIX vereinbarten Verkaufs- und Lieferbedingungen. Sofern Leistungen und Waren Dritter über das PHOENIX Portal
      angeboten werden (z.B. Shop §&nbsp;73(3) AMG der PHOENIX Pharma-Einkauf GmbH), wird PHOENIX insoweit nicht
      Vertragspartner des Nutzers. Die entsprechenden Verträge werden vielmehr ausschließlich zwischen dem Nutzer und
      dem jeweiligen Dritten geschlossen und es gelten dessen jeweilige Geschäftsbedingungen, die im Rahmen des
      Bestellvorgangs akzeptiert werden müssen.<br> &nbsp;</p>
    <h2>2. Nutzung des PHOENIX Portals</h2>
    <p>2.1. Zur Nutzung des PHOENIX Portals ist jede/r Apotheker/in berechtigt, der/die sich in einer bestehenden
      Geschäftsbeziehung zu PHOENIX befindet („Nutzer“).</p>
    <p>2.2. PHOENIX erhebt, verarbeitet und nutzt personenbezogene Daten der Nutzer für die Zwecke der Nutzung des
      PHOENIX Portals und zum Zweck der Vertragserfüllung. Rechtsgrundlage ist Art. 6 Abs. 1 lit. b und lit. f DSGVO.
      Darüber hinaus verarbeitet PHOENIX personenbezogene Daten eines Nutzers nur, sofern der Nutzer eingewilligt hat
      oder das Bundesdatenschutzgesetz, die DSGVO oder eine andere Rechtsvorschrift dies anordnet oder erlaubt.
      Einzelheiten zu der Erhebung, Verarbeitung personenbezogener Daten können der Datenschutzerklärung zum PHOENIX
      Portal entnommen werden (www.phoenix-apothekeportal.de/datenschutz).</p>
    <p>2.3 Ferner kann der Nutzer Mitarbeiter seiner Apotheke oder andere Externe (wie z.B. Berater o.ä.) im Rahmen
      seines Nutzerkontos als weitere Benutzer benennen; der Nutzer kann diese weiteren Benutzer jedoch auch vom Zugriff
      auf bestimmte Bereiche des PHOENIX Portals ausschließen. Der Nutzer ist im Verhältnis zu den Daten der weiteren
      Benutzer die Verantwortliche Stelle im Sinne der DSGVO. PHOENIX verarbeitet diese Daten als Kontaktdaten von dem
      Kunden. Weitere Informationen sind in der Datenschutzerklärung zu finden. Der Nutzer ist dafür verantwortlich,
      dass die von ihm benannten weiteren Benutzer diese Nutzungsbedingungen beachten und haftet gegenüber der PHOENIX
      für etwaige Verstöße der weiteren Benutzer gegen diese Nutzungsbedingungen.</p>
    <p>2.4 Nicht-Kunden der PHOENIX (einschließlich nicht-kaufende Kunden der PHOENIX über einen Zeitraum von mehr als
      zwei Monaten), Verbraucher sowie Minderjährige dürfen das PHOENIX Portal nicht nutzen. Nutzerkonten dürfen nicht
      auf Dritte übertragen werden. Mit der Registrierung erklärt der Nutzer, volljährig und Apotheken-Kunde der PHOENIX
      zu sein. Ein Rechtsanspruch auf die Nutzung des PHOENIX Portals besteht nicht; PHOENIX kann die Registrierung ohne
      Angabe von Gründen verweigern.</p>
    <p>2.5. Ein Zugriff auf den Server des PHOENIX Portals ist nur per http-Anforderung mithilfe eines Webbrowsers
      gestattet. Es ist nicht gestattet, Administrator-Kennwörter zu verwenden oder sich bei der Verwendung der Website
      als Administrator auszugeben.</p>
    <p>2.6. Der Zugang zum PHOENIX Portal ist kostenlos. Einige der über das PHOENIX Portal verfügbaren Leistungen sind
      jedoch kostenpflichtig; dies ist bei den jeweiligen Leistungen angegeben.</p>
    <p>2.7. Der Nutzer verpflichtet sich, bei der Nutzung des PHOENIX Portals weder die Webseite noch die zugehörige
      Software, Hardware und/oder den Server zu überlasten, anzugreifen, zu verändern oder zu beeinträchtigen. Der
      Nutzer verpflichtet sich darüber hinaus, die Verwendung der Website durch andere nicht zu behindern oder zu
      beeinträchtigen. Des Weiteren stimmt der Nutzer zu, nicht anderweitig Informationen oder Material auf oder in
      Verbindung mit der Website zu manipulieren.</p>
    <p>2.8 Der Nutzer wird nach 2 Stunden ab der letzten Aktivität automatisch abgemeldet.<br> &nbsp;</p>
    <h2>3. Registrierung</h2>
    <p>3.1. Zum Zugriff auf die Inhalte des PHOENIX Portals ist eine Registrierung notwendig. Zu diesem Zweck muss der
      Nutzer bei der Registrierung seine Daten (IDF-Nummer sowie Name und Anschrift der Apotheke) angeben. Im Anschluss
      erhält der Nutzer eine Bestätigungs-E-Mail mit einen Verifizierungslink, mit dem er sein Nutzerkonto einrichten,
      sein Passwort festlegen und die Registrierung abschließen kann. Sodann kann sich der Nutzer mit seinem
      Benutzernamen einloggen, das gewählte Passwort eingeben und die Inhalte des PHOENIX Portals nutzen. PHOENIX
      benutzt für diese Zwecke die E-Mail Adresse, die PHOENIX für Zwecke der Geschäftsbeziehung in den Kundenstammdaten
      hinterlegt &nbsp;hat oder hinterlegen wird. Die Zugangsregelungen zur Mailbox des Nutzers sind ausschließlich in
      Verantwortung des Nutzers. PHOENIX trägt keine Haftung in diesem Zusammenhang für einen unerlaubten Zugriff auf
      die &nbsp;Mailbox des Nutzers.</p>
    <p>3.2. Jeder Nutzer kann nur ein Nutzerkonto registrieren. Eine Mehrfachregistrierung eines Nutzers ist nicht
      gestattet.</p>
    <p>3.3. Der Nutzer ist verpflichtet, seine Zugangsdaten geheim zu halten und vor dem Zugriff Dritter zu schützen.
      Seine Zugangsdaten dürfen Dritten nicht zur Nutzung überlassen oder auf sonstige Weise an diese weitergegeben
      werden. Ebenso ist es nicht gestattet, die Zugangsdaten von Dritten zu nutzen.</p>
    <p>3.4. Der Nutzer ist allein für die Verwendung seines Nutzerkontos bzw. alle entsprechenden Kontoaktivitäten
      verantwortlich, einschließlich der Verwendung des Kontos durch eine Person, die mit oder ohne Genehmigung die
      Anmeldedaten des Nutzers verwendet oder Zugriff auf einen Computer hat, auf dem sich das Nutzerkonto befindet oder
      über den Zugriff darauf besteht. Sollten sich Anhaltspunkte ergeben, dass ein Dritter Zugriff auf die Anmeldedaten
      erlangt hat, ist der Nutzer verpflichtet, die betroffenen Anmeldedaten umgehend zu ändern. Ferner ist der Nutzer
      in solchen Fällen verpflichtet, PHOENIX unverzüglich zu informieren.</p>
    <p>3.5. Der Nutzer ist verpflichtet, seine bei der Registrierung angegebenen Daten stets aktuell zu halten.<br>
      &nbsp;</p>
    <h2>4. [leer]<br> &nbsp;</h2>
    <h2>5. Geistiges Eigentum</h2>
    <p>Alle im PHOENIX Portal dargestellten Inhalte, also insbesondere Texte, Bilder, Fotos, grafische Darstellungen,
      Musiken, Marken und Warenzeichen unterfallen dem Schutz des Urheberrechtsgesetzes beziehungsweise dem Schutz
      anderer Gesetze zum Schutz des geistigen Eigentums. Diese dürfen daher, mit Ausnahme der vertragsgemäßen Nutzung
      des PHOENIX Portals, weder vervielfältigt, verbreitet, bearbeitet, übersetzt, oder sonst in andere Medien
      eingespeichert oder verarbeitet werden, einschließlich solcher in elektronischer Form. Ebenso untersagt ist die
      Verwendung automatisierter Systeme oder automatisierter Software zur Extraktion von Inhalten des PHOENIX Portals.
      Jede Verwertung, insbesondere die Verwendung von Texten, Textteilen, Ton- oder Bildmaterial, bedarf der vorherigen
      Zustimmung der jeweiligen Urheber bzw. Rechteverwalter.<br> &nbsp;</p>
    <h2>6. Beendigung</h2>
    <p>6.1. Der Nutzer kann sein Nutzerkonto jederzeit ohne Angabe von Gründen und ohne Einhaltung einer Frist löschen.
      Sobald das Nutzerkonto gelöscht wird, werden die personenbezogenen Daten des Nutzers, die für die Nutzung des
      PHOENIX Portals erforderlich waren, binnen eines Monats gelöscht, sofern nicht aufgrund von Gesetzen oder
      Rechtsvorschriften eine Pflicht oder ein Recht zur Aufbewahrung der Daten besteht.</p>
    <p>6.2. PHOENIX ist berechtigt, das Nutzerkonto eines Nutzers bei Vorliegen eines wichtigen Grundes zu löschen und
      den Nutzer damit von der weiteren Nutzung des PHOENIX Portals auszuschließen. Ein wichtiger Grund zur Löschung des
      Nutzerkontos ist insbesondere in folgenden Fällen gegeben:</p>
    <ul>
      <li>der Nutzer hat bei seiner Registrierung falsche Angaben gemacht;</li>
      <li>der Nutzer ist seit mehr als zwei Monaten kein kaufender Kunde der PHOENIX mehr;</li>
      <li>bei mehrfacher Registrierung eines Nutzers;</li>
      <li>bei Verstößen gegen diese Nutzungsbedingungen oder gegen andere Bedingungen des PHOENIX Portals;</li>
      <li>bei Verstößen gegen gesetzliche Verbote, insbesondere urheberrechtliche, wettbewerbsrechtliche oder
        datenschutzrechtliche Bestimmungen.<br> &nbsp;
      </li>
    </ul>
    <h2>7. Änderung oder Einstellung des PHOENIX Portals; Verfügbarkeit</h2>
    <p>7.1. PHOENIX behält sich vor, jederzeit Änderungen an den Inhalten des PHOENIX Portals vorzunehmen, insbesondere
      einzelne Inhalte auszusetzen, einzustellen oder zu löschen. PHOENIX behält sich ferner vor, das PHOENIX Portal
      insgesamt einzustellen.</p>
    <p>7.2. PHOENIX ist bemüht, das PHOENIX Portal möglichst unterbrechungsfrei zur Nutzung bereit zu stellen.
      Ausfallzeiten können jedoch trotz aller Sorgfalt nicht ausgeschlossen werden. Aus einer Unterbrechung oder
      anderweitigen Störung kann der Nutzer keine Ansprüche gegen PHOENIX herleiten.<br> &nbsp;</p>
    <h2>8. Haftung</h2>
    <p>8.1. Die Schadensersatzhaftung der PHOENIX ist bei leichter Fahrlässigkeit von PHOENIX und deren
      Erfüllungsgehilfen ausgeschlossen. Dies gilt jedoch nicht bei der Verletzung einer vertragswesentlichen Pflicht
      (d.h. einer Pflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages erst ermöglicht und auf deren
      Einhaltung der Nutzer regelmäßig vertrauen darf); in diesem Fall ist die Haftung auf den vertragstypischen,
      vorhersehbaren Schaden beschränkt. Die Haftungsbeschränkung gilt nicht bei Ansprüchen nach dem
      Produkthaftungsgesetz sowie Verletzung des Lebens, des Körpers oder der Gesundheit.</p>
    <p>8.2. Abweichend von Ziffer 8.1 haftet die PHOENIX in keinem Fall für die Richtigkeit, Vollständigkeit und
      Aktualität der im PHOENIX Portal verfügbaren Daten, insbesondere Arzneimitteldaten.</p>
    <p>8.3. Im Übrigen haftet die PHOENIX nach den gesetzlichen Bestimmungen.<br> &nbsp;</p>
    <h2>9. Links zu anderen Webseiten</h2>
    <p>Das PHOENIX Portal kann Links auf Webseiten Dritter enthalten. PHOENIX überprüft die Inhalte und die Gestaltung
      dritter Webseiten nicht und übernimmt für die Inhalte und die Gestaltung dritter Webseiten weder eine
      Verantwortung noch macht sie sich diese Webseiten und ihre Inhalte zu eigen. Wenn PHOENIX feststellt oder
      anderweitig darauf hingewiesen wird, dass der konkrete Inhalt einer dritten Webseite, zu der sie einen Link
      bereitgestellt hat, eine zivil- oder strafrechtliche Verantwortlichkeit auslöst, wird sie den Link löschen.<br>
      &nbsp;</p>
    <h2>10. Erfüllungsort und Gerichtsstand</h2>
    <p>Erfüllungsort und Gerichtsstand ist Mannheim. Es gilt deutsches Recht.</p>
    <p>&nbsp;</p>
    <p>PHOENIX Pharmahandel GmbH &amp; Co KG, Pfingstweidstraße 10-12, 68199 Mannheim</p>


  </div>
</div>
