<div id="content" class="mb-0">
  <img
      src="../../assets/dummy/datev_header.jpg"
      alt="Die DATEV Schnittstelle ">
  <div class="panel-without-border">
    <h1>Die DATEV Schnittstelle </h1>
    <p>Mit der neuen DATEV Schnittstelle können Sie Ihre PHOENIX Sammelrechnungen
      automatisiert an die DATEV-Finanzsoftware übertragen. Dafür wird der DATEV Belegbilderservice
      Rechnungswesen genutzt. Wie die Übermittlung funktioniert und wie Sie die Schnittstelle einrichten,
      erfahren Sie hier.</p>
    <h2 class="mt-l">Vorteile und Funktionsweise - kurz erklärt</h2>
      <img class="img-fluid w-50" src="../../assets/dummy/datev%20video.jpg">
    <h2 class="mt-l">Downloads</h2>
    <div class="row mb-m">
      <div class="col-md align-items-center d-flex">
        <a
           target="_blank"
           class="linki"
           >Anleitung zur Einrichtung der DATEV Schnittstelle</a><span>&nbsp;(1 MB)</span>
      </div>
      <div class="col-md align-items-center d-flex">
        <a class="download-button btn btn-primary btn-round"

           target="_blank"><span
            class="arrow-helper far fa-arrow-down"></span>
        </a>
      </div>
    </div>
    <div class="row mb-m">
      <div class="col-md align-items-center d-flex">
        <a
           target="_blank"
           class="linki"
        >Optimierungen der DATEV Schnittstelle November 2022</a><span>&nbsp;(2 MB)</span>
      </div>
      <div class="col-md align-items-center d-flex">
        <a class="download-button btn btn-primary btn-round"

           target="_blank"><span
            class="arrow-helper far fa-arrow-down"></span>
        </a>
      </div>
    </div>
    <h2 class="mt-l">Nächste Schritte</h2>
    <h4>Sie erhalten die PHOENIX Sammelrechnungen in elektronischer Form?</h4>
    <p>Dann können Sie direkt loslegen und die Schnittstelle in nur wenigen einfachen Schritten konfigurieren.<br>
      Gehen Sie dazu unter&nbsp;"Meine persönlichen Einstellungen" auf den neuen Bereich "Schnittstellen".&nbsp;Hierfür
      müssen Sie mit dem Inhaberzugang angemeldet sein.</p><h4>Sie erhalten die PHOENIX Sammelrechnungen
    postalisch?</h4>
    <p>Stimmen Sie zunächst dem Erhalt&nbsp;der&nbsp;elektronischen Sammelrechnung zu, indem Sie uns formlos
      eine Mail an <a class="linki">apothekenportal@phoenixgroup.eu</a>
      senden. Die Umstellung von postalischem Versand auf elektronische Bereitstellung erfolgt zum
      Monatswechsel. Ab diesem Moment können Sie auch die DATEV Schnittstelle konfigurieren.</p>

  </div>
</div>
<section class="green-square">
    <h2>Antworten auf häufig gestellte Fragen finden Sie in unserem Hilfecenter</h2>
      <a target="_blank">
        <button class="btn-primary on-dark-bg">
          Hilfecenter
        </button>
      </a>
</section>



