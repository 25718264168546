<div *ngIf="!loading && show">
    <div class="p-m d-flex justify-content-between align-items-center mb-l send-warning" *ngIf="sendError">
        <p class="mb-0"><strong>Die letzte Übertragung an DATEV ist fehlgeschlagen.</strong><br>
        Sie können die Übertragung hier erneut auslösen.</p>
        <button class="btn btn-secondary mt-0 bg-white" (click)="sendAgainFiles()">Erneut übertragen</button>
    </div>
    <notification *ngIf="!sendError && resend" type="success" message="Documents are sent to DATEV."></notification>
    <notification *ngIf="saved && submit" type="success" message="Die Konfiguration wurde erfolgreich abgeschlossen. Die Datenübertragung an
DATEV ist aktiv."></notification>
    <notification *ngIf="!saved && submit" type="error" message="Error message should be changed"></notification>
    <div class="text-right mb-l"><a class="phx-cta" (click)="openDialog()">Sendungsprotokoll</a></div>
    <form [formGroup]="datevForm" (ngSubmit)="onSubmit()">
        <div class="row justify-content-end">
            <div class="col-md-4 ">
                <p class="text-center">E-Mail Benachrichtigung bei Übertragungsfehlern</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Beraternummer/Mandantennummer</mat-label>
                    <input matInput formControlName="clientId" tabindex="1" readonly>
                </mat-form-field>
                <a *ngIf="mainIdf!==idf" (click)="createNewToken()">Ich habe mehrere Mandantennummern</a>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Bemerkung</mat-label>
                    <input matInput formControlName="note" tabindex="5">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                    <div class="text-center slid">
                        <mat-slide-toggle formControlName="notify" [color]="primary"></mat-slide-toggle>
                    </div>
            </div>
        </div>
        <!--        <div class="row mb-l">-->
        <!--            <div class="col-md-12">-->
        <!--                <p class="m-m">Falls Sie die Rechnungen direkt in ein Archiv übertragen lassen möchten,
        geben Sie bitte das entsprechende Verzeichnis an:</p>-->
        <!--            </div>-->
        <!--            <div class="col-md-4">-->
        <!--                <mat-form-field appearance="outline">-->
        <!--                    <mat-label>Kategorie</mat-label>-->
        <!--                    <input matInput formControlName="category" tabindex="2">-->
        <!--                </mat-form-field>-->
        <!--            </div>-->
        <!--            <div class="col-md-4">-->
        <!--                <mat-form-field appearance="outline">-->
        <!--                    <mat-label>Ordner</mat-label>-->
        <!--                    <input matInput formControlName="folder" tabindex="3">-->
        <!--                </mat-form-field>-->
        <!--            </div>-->
        <!--            <div class="col-md-4">-->
        <!--                <mat-form-field appearance="outline">-->
        <!--                    <mat-label>Verzeichnis</mat-label>-->
        <!--                    <input matInput formControlName="register" tabindex="4">-->
        <!--                </mat-form-field>-->
        <!--            </div>-->
        <!--        </div>-->
        <p class="my-l">Sie können jeder PHOENIX Belegart einen DATEV Belegtyp zuordnen. Neue Rechnungen werden somit
            automatisch im richtigen Verzeichnis abgelegt.</p>
        <div class="row justify-content-end">
            <div class="col-md-4 ">
                <p class="text-center">Belegart an DATEV übertragen</p>
            </div>
        </div>
        <div class="row" *ngFor="let phxDoc of phxDocuments" >
            <div class="col-md-4 align-self-center">
                <p>{{phxDoc.value}}</p>
            </div>
            <div class="col-md-4" formGroupName="documents">
                <mat-form-field appearance="outline">
                    <mat-label>DATEV Belegtyp</mat-label>
                    <mat-select [formControlName]="phxDoc.attribute">
                        <mat-option value="" disabled>Auswahl der DATEV Belegtyp</mat-option>
                        <mat-option *ngFor="let datevDoc of datevDocuments" [value]="datevDoc">
                            {{datevDoc}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" formGroupName="send">
                <div class="text-center slid">
                    <mat-slide-toggle [formControlName]="phxDoc.attribute + '-send'" [color]="primary"></mat-slide-toggle>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-secondary" [disabled]="!datevForm.valid" (click)="onDeactivate()">DEAKTIVIEREN
            </button>
            <button class="btn btn-primary" [disabled]="!datevForm.valid" type="submit">KONFIGURATION SPEICHERN</button>
        </div>
        <div class="mt-l">
            <p class="text-right"><i class="fas fa-check-circle"></i> DATEV Schnittstelle aktiv</p>
        </div>
    </form>

</div>

<div *ngIf="!show  && !loading">
    <notification *ngIf="deactivated" type="success"
                  message="Die DATEV Schnittstelle wurde deaktiviert. Es werden keine weiteren Rechnungen übertragen."></notification>
    <notification *ngIf="error" type="error" message="{{error}}"></notification>
    <div>
        <p>Die DATEV Schnittstelle ermöglicht Ihnen die Übertragung der elektronischen Sammelrechnungen
            von PHOENIX an Ihre Finanzbuchhaltung. Nach erfolgreicher Authorisierung ist die
            Schnittstelle aktiviert und die elektronischen Sammelrechnungen werden als Belegbilder
            automatisch an DATEV gesendet.</p>
        <ol>
            <li>Stellen Sie sicher, dass die Berechtigung für die Nutzung des DATEV <a
                    href="https://www.datev.de/web/de/datev-shop/92902-belegbilderservice/ ">Belegbilderservice</a>
                gesetzt ist. Setzen Sie sich dafür ggf. mit Ihrer Steuerberatung in Verbindung
            </li>
            <li>Melden Sie sich durch Eingabe der unten stehenden Daten bei DATEV an:</li>
        </ol>
        <form [formGroup]="clientForm" (ngSubmit)="onSubmitRedirect()">
            <div class="row">
                <div class="col-md-5">
                    <mat-form-field appearance="outline">
                        <mat-label>Beraternummer</mat-label>
                        <input matInput formControlName="berater" tabindex="3" >
                        <mat-error>
                            Bitte geben Sie Ihre Beraternummer ein.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline" *ngIf="!newToken || clientIds.length < 1">
                        <mat-label>Mandantennummer</mat-label>
                        <input matInput formControlName="client" tabindex="5">
                        <mat-error>
                            Bitte geben Sie Ihre Mandantennummer ein.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="newToken && clientIds.length > 0">
                        <mat-label>Mandantennummer</mat-label>
                        <mat-select formControlName="client" tabindex="5">
                            <mat-option *ngFor="let clientId of clientIds" [value]="clientId">
                                {{ clientId }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <button class="btn btn-primary" type="submit" [disabled]="!clientForm.valid">KONFIGURIEREN</button>
                </div>
            </div>
            <div class="mt-l">
                <p class="text-right"><i class="fas fa-exclamation-circle text-danger"></i> Aktuell ist die
                    Datenübertragung an DATEV nicht aktiv.</p>
            </div>
        </form>
    </div>
</div>
