import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../core/models/user.model';
import { Nav } from '../../core/models/nav.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {


    private ngUnsubscribe$ = new Subject<any>();

    public user: User;
    public nav: Nav = new Nav({}, '', {});
    public activeHeading: string | boolean;

    constructor(private router: Router, private route: ActivatedRoute,
        @Inject(UserService) private userService: UserService) {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.determineHeading();
            }
        });
    }

    ngOnInit(): void {


    }

    determineHeading() {
        const route = this.route.snapshot;
        if (route.children.length > 0) {
            const changeHeading = this.nav && this.nav.getProperty(route.children[0].routeConfig.path, route.queryParamMap.get('url'));
            if (route.queryParamMap.has('heading')) {
                this.activeHeading = route.queryParamMap.get('heading');
            } else if (route.children[0].routeConfig.path.indexOf('static-content') > -1 && changeHeading) {
                this.activeHeading = changeHeading;
            } else if (route.children[0].routeConfig.path.indexOf('static-content') === -1) {
                this.activeHeading = changeHeading;
            }
        }
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
