import {Component, OnDestroy, OnInit} from '@angular/core';
import {PagesRestService} from '../../../core/services/api/cms/pages.rest-service';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit, OnDestroy {
  public loading = true;

  public body = null;
  public footer = null;
  public page = null;
  public link = null;
  public ngUnsubscribe$ = new Subject<any>();

  constructor(private pageService: PagesRestService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.link = params.url;
    });
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
