import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {BlobService} from '../../core/services/blob.service';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-notices',
    templateUrl: './notices.component.html',
    styleUrls: ['./notices.component.scss']
})
export class NoticesComponent implements OnInit {

    public notices = [];
    @Input() noticeLocation: string;

    constructor(
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.getNotices();
    }

    ngOnInit(): void {
    }

    async getNotices() {
       return [];
    }

    showNotice(filter) {
        let route;
        if (this.noticeLocation !== 'login') {
            route = this.router.url.replace(/^\/|\/$/g, '');
        } else {
            route = this.activatedRoute.snapshot.routeConfig.path;
        }
        return !(filter !== null && filter !== route && !route.includes(filter));
    }

}

