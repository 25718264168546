/**
 * represent the basic navigation model
 */
import {NavItem, NavType} from './nav-item.model';
import {ProductType} from './shop/product-type.model';

export class Nav {
  headline = '';
  component = '';
  tree: NavItem[];


  constructor(obj: any = {}, cmsHost: string = '', vaccineConfig: any = {}) {
    this.headline = '';
    this.component = 'dashboard';
    this.tree = [];
    // this.tree = obj && obj.tree || [];
    // this.tree = [];

    // this.tree.push(new NavItem({navLinkName: 'NAVIGATION.DASHBOARD.LINK', path: 'dashboard'}));
    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.DATA.LINK',
      requiredAuthorities: [
        'MY_SALES',
        'BULK_BILL_RECEIPT',
        'BULK_BILL',
        'CREDIT_NOTE',
        'VETERINARY_DRUG',
        'TRANSFUSION',
        'REIMPORT',
        'EXTENDED_BENEFIT',
        'PAYBACK'
      ],
      tree: [
        new NavItem({
          heading: 'NAVIGATION.DATA.INVOICES.HEADING',
          navLinkName: 'NAVIGATION.DATA.INVOICES.LINK',
          component: 'belegsuche',
          path: 'belegsuche',
          requiredAuthorities: [
            'BULK_BILL_RECEIPT',
            'BULK_BILL',
            'CREDIT_NOTE',
            'VETERINARY_DRUG',
            'TRANSFUSION',
            'REIMPORT',
            'EXTENDED_BENEFIT'
          ],
          isTile: true,
          tileTitle: 'NAVIGATION.DATA.INVOICES.TILE',
          tileImageFileName: 'assets/img/tiles/svg/rechnung-abfragen-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/rechnung-abfragen-hover-kachel.svg',
          tilePosition: 10
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.REVENUE.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.DATA.REVENUE.TILE',
          heading: 'NAVIGATION.DATA.REVENUE.HEADING',
          component: 'mein-umsatz',
          path: 'mein-umsatz',
          requiredAuthorities: ['MY_SALES'],
          tileImageFileName: 'assets/img/tiles/svg/umsatz-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/umsatz-hover-kachel.svg',

          tilePosition: 20,
          pageStatus: this.getPageStatus(7, this.parseDate('2023-03-31T23:59:00'))
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.PAYBACK.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.DATA.PAYBACK.TILE',
          heading: 'NAVIGATION.DATA.PAYBACK.HEADING',
          component: 'payback',
          path: 'payback',
          requiredAuthorities: ['PAYBACK'],
          tileImageFileName: 'assets/img/tiles/svg/payback-new-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/payback-new-hover-kachel.svg',
          tilePosition: 30
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.REKLAMATION.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.DATA.REKLAMATION.TILE',
          heading: 'NAVIGATION.DATA.REKLAMATION.HEADING',
          component: 'reklamation',
          path: 'reklamation',
          tileImageFileName: 'assets/img/tiles/svg/reklamation-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/reklamation-hover-kachel.svg',
          tilePosition: 40
        })
      ]
    }));

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.ORDER.LINK',
      heading: 'NAVIGATION.ORDER.HEADING',
      activatesPath: ['bestellung'],
      dataCy: 'order-and-delivery',
      requiredAuthorities: [
        'ORDERS',
        'TRACK_TRACE'
      ],
      tree: [
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PRODUCTS.LINK',
          heading: 'Produktshop',
          component: 'doku-light',
          activatesPath: ['doku-light-details'],
          path: ProductType.DokuLight,
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.PRODUCTS.TILE',
          tileImageFileName: 'assets/img/tiles/svg/produktshop.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/produktshop.svg',
          dataCy: 'doku-light',
          requiredAuthorities: ['ORDERS'],
          tilePosition: 2
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.LINK',
          heading: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.HEADING',
          component: 'nachlieferung',
          path: 'nachlieferung',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/nachlieferungliste-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/nachlieferungliste-hover-kachel.svg',
          requiredAuthorities: ['NACH'],
          tilePosition: 2,
          pageStatus: this.getPageStatus(1, this.parseDate('2022-09-25T18:00:00'))
        }),
        new NavItem({
          heading: 'NAVIGATION.ORDER.LAGERAKTION.HEADING',
          navLinkName: 'NAVIGATION.ORDER.LAGERAKTION.LINK',
          component: 'lageraktion',
          path: 'lageraktion/grippe2023',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.LAGERAKTION.TILE',
          tileImageFileName: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel-hover.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 5,
          start: '2022-06-27T00:00:00',
          class: 'tealTile',
          pageStatus: this.getPageStatus(1, this.parseDate('2023-02-14T17:59:00'))
        }),
        // new NavItem({
        //   navLinkName: 'NAVIGATION.ORDER.PROMO.LINK',
        //   heading: 'NAVIGATION.ORDER.PROMO.HEADING',
        //   component: 'promo',
        //   path: 'promotions',
        //   isTile: true,
        //   tileTitle: 'NAVIGATION.ORDER.PROMO.TILE',
        //   tileImageFileName: 'assets/img/tiles/svg/promotions-01.svg',
        //   tileImageFileNameHover: 'assets/img/tiles/svg/promotions-hover-01.svg',
        //   requiredAuthorities: ['ORDERS'],
        //   tilePosition: 7,
        //   start: '2022-12-11T08:00:00',
        //   pageStatus: this.getPageStatus(2, this.parseDate('2022-03-04T23:59:00'))
        // }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PARAGRAPH73.LINK',
          heading: 'NAVIGATION.ORDER.PARAGRAPH73.HEADING',
          component: 'paragraph73',
          path: ProductType.Paragraph73,
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.PARAGRAPH73.TILE',
          tileImageFileName: 'assets/img/tiles/svg/amg-73-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/amg-73-kachel-hover.svg',
          requiredAuthorities: ['ORDERS'],
          tilePosition: 8
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.TRACKANDTRACE.LINK',
          heading: 'NAVIGATION.ORDER.TRACKANDTRACE.HEADING',
          component: 'track-trace',
          path: 'track-trace',
          isTile: true,
          requiredAuthorities: ['TRACK_TRACE'],
          tileTitle: 'NAVIGATION.ORDER.TRACKANDTRACE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/track-and-trace.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/track-and-trace-hover.svg',
          tilePosition: 14
        }),

        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.RETOUREN.LINK',
          heading: 'NAVIGATION.ORDER.RETOUREN.HEADING',
          component: 'retouren',
          path: 'retouren',
          isTile: true,
          requiredAuthorities: ['ORDERS'],
          tileTitle: 'NAVIGATION.ORDER.RETOUREN.TILE',
          tileImageFileName: 'assets/img/tiles/svg/retourenavis.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/retourenavis-hover.svg',
          tilePosition: 15,
          pageStatus: this.getPageStatus(1, this.parseDate('2023-07-17T23:00:00')),
        }),
      ]
    }));

    // Create a subtree for the Advertorials

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
      showInNav: false,
      tree: [
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
          heading: 'NAVIGATION.ORDER.ADVERTORIAL.HEADING',
          activatesPath: ['advertorial/:link'],
          component: 'advertorial',
          path: '1apharma',
          isTile: true,
          tileTitle: '1A Pharma',
          tileImageFileName: '/1APharma_Januar_2_Super_Banner.jpg',
          tilePosition: 1,
          type: NavType.ADVERTORIAL,
          start: '2023-10-16T21:00:00',
          end: '2024-10-31T23:59:00',
          showInNav: false,
          pageStatus: this.getPageStatus(1, this.parseDate('2023-07-31T23:00:00')),
        })
      ]
    }));


    // Create a sub-tree for the service related links and tiles
    const serviceTree = [];

    serviceTree.push(
        new NavItem({
          navLinkName: 'NAVIGATION.SERVICE.LINDA.LINK',
          heading: 'NAVIGATION.SERVICE.LINDA.HEADING',
          component: 'linda',
          path: 'pkp',
          activatesPath: ['pkp'],
          isTile: true,
          tileTitle: 'NAVIGATION.SERVICE.LINDA.TILE',
          tileImageFileName: 'assets/img/tiles/svg/LINDA-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/LINDA-kachel.svg',
          shouldOpenInNewTab: false,
          tilePosition: 10
        }),
        new NavItem({
          navLinkName: 'DATEV',
          heading: 'DATEV',
          component: 'datev-info',
          path: 'datev-info',
          activatesPath: ['datev-info'],
          isTile: true,
          tileTitle: 'DATEV',
          tileImageFileName: 'assets/img/tiles/dummy/DATEV_tile.svg',
          tileImageFileNameHover: 'assets/img/tiles/dummy/DATEV_tile.svg',
          shouldOpenInNewTab: false,
          tilePosition: 20
        }),
        new NavItem({
          navLinkName: 'Wichtige Informationen',
          heading: 'Wichtige Informationen',
          component: 'wichtige-informationen',
          path: 'wichtige-informationen',
          activatesPath: ['wichtige-informationen'],
          isTile: true,
          tileTitle: 'Wichtige Informationen',
          tileImageFileName: 'assets/img/tiles/dummy/info-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/dummy/info-kachel-hover.svg',
          shouldOpenInNewTab: false,
          tilePosition: 40
        }),
        new NavItem({
          navLinkName: 'LIVSANE',
          heading: 'LIVSANE',
          component: 'livsane',
          path: 'livsane',
          activatesPath: ['livsane'],
          isTile: true,
          tileTitle: 'LIVSANE',
          tileImageFileName: 'assets/img/tiles/dummy/livsane-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/dummy/livsane-hover-kachel.svg',
          shouldOpenInNewTab: false,
          tilePosition: 50
        }),
        new NavItem({
          navLinkName: 'AKADEMIE',
          heading: 'AKADEMIE',
          component: 'akademie',
          path: 'akademie',
          activatesPath: ['akademie'],
          isTile: true,
          tileTitle: 'AKADEMIE',
          tileImageFileName: 'assets/img/tiles/dummy/akademie-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/dummy/akademie-kachel-hover.svg',
          shouldOpenInNewTab: false,
          tilePosition: 60
        }),
        new NavItem({
          navLinkName: 'Digitaler Assistent',
          heading: 'Digitaler Assistent',
          component: 'digitaler-assistent',
          path: 'digitaler-assistent',
          activatesPath: ['ppc'],
          isTile: true,
          tileTitle: 'Digitaler Assistent',
          tileImageFileName: 'assets/img/tiles/svg/bot-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/bot-kachel.svg',
          shouldOpenInNewTab: false,
          tilePosition: 30
        }),
        new NavItem({
          navLinkName: 'Offizin im Blick',
          heading: 'Offizin im Blick',
          component: 'offizin-im-blick',
          path: 'offizin-im-blick',
          activatesPath: ['offizin-im-blick'],
          isTile: true,
          tileTitle: 'Offizin im Blick',
          tileImageFileName: 'assets/img/tiles/dummy/offizin-im-blick-logo.svg',
          tileImageFileNameHover: 'assets/img/tiles/dummy/offizin-im-blick-logo-white.svg',
          shouldOpenInNewTab: false,
          tilePosition: 70
        }),
        new NavItem({
          // requiredAuthorities: ['PAYBACK', 'GROUP_MARKETING', 'GROUP_KSC', 'GROUP_SALES'],
          navLinkName: 'NAVIGATION.SERVICE.DEINEAPOTHEKESTORE.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.SERVICE.DEINEAPOTHEKESTORE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/phoenixstore-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/phoenixstore-hover-kachel.svg',
          shouldOpenInNewTab: true,
          type: NavType.DEINEAPOTHEKESTORE,
          tilePosition: 200,
        }),
        new NavItem({
          requiredAuthorities: ['GROUP_ACCOUNT_OWNER', 'GROUP_MARKETING', 'GROUP_KSC', 'GROUP_SALES'],
          navLinkName: 'NAVIGATION.SERVICE.PRIVATSORTIMENT.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.SERVICE.PRIVATSORTIMENT.TILE',
          tileImageFileName: 'assets/img/tiles/svg/genuss-kultur-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/genuss-kultur-hover-kachel.svg',
          shouldOpenInNewTab: true,
          type: NavType.PRIVATSORTIMENT,
          tilePosition: 300
        }),
    );
    if (obj && obj.length) {

      obj.forEach((element) => {
        serviceTree.push(new NavItem({
          navLinkName: element.title,
          heading: element.title,
          extUrl: element.url,
          path: 'static-content',
          isTile: true,
          tileTitle: element.title,
          tileImageFileName: cmsHost + element['button-icon-normal'],
          tileImageFileNameHover: cmsHost + element['button-icon-hover'],
          pageStatus: this.getPageStatus(element['page-status'], element['page-status-until']),
          tilePosition: 5
        }));
      });
    }

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.SERVICE.LINK',
      tree: serviceTree
    }));

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.PREFERENCES.LINK',
      requiredAuthorities: ['GROUP_ACCOUNT_OWNER'],
      tree: [
        new NavItem({
          requiredAuthorities: ['GROUP_ACCOUNT_OWNER'],
          heading: 'NAVIGATION.PREFERENCES.SUBUSERMANAGEMENT.HEADING',
          navLinkName: 'NAVIGATION.PREFERENCES.SUBUSERMANAGEMENT.LINK',
          component: 'mitbenutzerverwaltung',
          path: 'mitbenutzerverwaltung',
          isTile: true,
          tileTitle: 'NAVIGATION.PREFERENCES.SUBUSERMANAGEMENT.TILE',
          tileImageFileName: 'assets/img/tiles/svg/mitbenutzer-verwalten-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/mitbenutzer-verwalten-hover-kachel.svg',
          tilePosition: 1
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.PREFERENCES.PHARMACY-DATA.LINK',
          heading: 'NAVIGATION.PREFERENCES.PHARMACY-DATA.HEADING',
          requiredAuthorities: ['GROUP_ACCOUNT_OWNER'],
          component: 'apothekenstammdaten',
          path: 'apothekenstammdaten',
          isTile: true,
          tileTitle: 'NAVIGATION.PREFERENCES.PHARMACY-DATA.TILE',
          tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
          tilePosition: 2
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.PREFERENCES.INTEGRATIONS.LINK',
          heading: 'NAVIGATION.PREFERENCES.INTEGRATIONS.HEADING',
          requiredAuthorities: ['GROUP_ACCOUNT_OWNER', 'E_SARE'],
          component: 'schnittstellen',
          path: 'schnittstellen',
          isTile: true,
          tileTitle: 'NAVIGATION.PREFERENCES.INTEGRATIONS.TILE',
          tileImageFileName: 'assets/img/tiles/svg/schnittstellen-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/schnittstellen-hover-kachel.svg',
          tilePosition: 3
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.PREFERENCES.COMMUNICATIONS.LINK',
          heading: 'NAVIGATION.PREFERENCES.COMMUNICATIONS.HEADING',
          component: 'benachrichtigungen',
          path: 'benachrichtigungen',
          isTile: true,
          tileTitle: 'NAVIGATION.PREFERENCES.COMMUNICATIONS.TILE',
          tileImageFileName: 'assets/img/tiles/svg/notices-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/notices-kachel.svg',
          tilePosition: 4,
        })
      ]
    }));
  }

  getParent(currentlyActivePath: string, extUrl: string = null): string {
    if (!this.tree || currentlyActivePath === 'dashboard'
        || (currentlyActivePath === 'static-content' && extUrl == null)) {
      return null;
    }
    let ret = null;
    let done = false;
    if (currentlyActivePath.indexOf('static-content') !== -1 && extUrl !== null) {
      this.tree.forEach(navItem => {
        if (navItem.tree) {
          navItem.tree.forEach(subNavItem => {
            if (subNavItem.extUrl && subNavItem.extUrl.indexOf(extUrl) !== -1) {
              ret = navItem.navLinkName;
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    } else {
      this.tree.forEach(navItem => {
        if (navItem.tree) {
          navItem.tree.forEach(subNavItem => {
            if (subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1) {
              ret = navItem.navLinkName;
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    }
    return ret;
  }

  getProperty(currentlyActivePath: string, extUrl: string = null, property: string = 'heading'): string | boolean {
    if (!this.tree || currentlyActivePath === 'dashboard'
        || (currentlyActivePath === 'static-content' && extUrl == null)) {
      return false;
    }
    let ret: string | boolean = false;
    let done = false;
    if (currentlyActivePath.indexOf('static-content') !== -1 && extUrl !== null) {
      if (property === 'path') {
        property = 'extUrl';
      }
      this.tree.forEach(navItem => {
        if (navItem.extUrl && navItem.extUrl.indexOf(extUrl) !== -1 && navItem.hasOwnProperty(property)) {
          ret = navItem[property];
          done = true;
        } else if (navItem.tree) {
          navItem.tree.forEach(subNavItem => {
            if (subNavItem.extUrl && subNavItem.extUrl.indexOf(extUrl) !== -1 && subNavItem.hasOwnProperty(property)) {
              ret = subNavItem[property];
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    } else {
      this.tree.forEach(navItem => {
        if ((navItem.path === currentlyActivePath || navItem.activatesPath.indexOf(currentlyActivePath) !== -1)
            && navItem.hasOwnProperty(property)) {
          ret = navItem[property];
          done = true;
        } else if (navItem.tree) {
          navItem.tree.forEach(subNavItem => {
            if ((subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1)
                && subNavItem.hasOwnProperty(property)) {
              ret = subNavItem[property];
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    }
    return ret;
  }

  parseDate(string) {
    const time = new Date(string);
    return time.getTime() / 1000;
  }

  getPageStatus(pageStatus, until) {
    let status;
    const now = new Date();

    if (until < now.getTime() / 1000) {
      return 0;
    }

    switch (pageStatus) {
      case 1:
        status = 'neu';
        break;
      case 2:
        status = 'update';
        break;
      case 3:
        status = 'letzte chance';
        break;
      case 4:
        status = 'Letzte Chance Efluelda';
        break;
      case 5:
        status = 'Hier teilnehmen';
        break;
      case 6:
        status = 'Advertorial';
        break;
      case 7:
        status = 'Neue Inhalte';
        break;
      case 8:
        status = 'Gewinnspiel';
        break;
      default:
        status = null;
        break;
    }

    return status;
  }
}
