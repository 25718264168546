<div class="content">
  <div class="panel">
    <div class="row  align-items-center">
      <div class="col-8">
        <h2 class="pb-s">Umsatzstatus</h2>
        <p>Hier können Sie Ihre aktuellen Umsatzkennzahlen einsehen.</p>
      </div>
      <div class="col-4">
        <form>
          <mat-form-field appearance="outline">
            <mat-label>IDF-Nummer</mat-label>
            <input name="idf" matInput [value]="currentlyDisplayedIdf" readonly>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div *ngIf="revenue">
      <h2>{{ revenue.month | translate}} {{ today |date: 'YYYY' }}</h2>
      <div class="row mt-s">
        <div class="col-sm-4">
          <div class="mb-s"><img src="/assets/img/icons/mein-umsatz-euro.svg" alt="Umsatz total"></div>
          <h4>Umsatz total</h4>
          <div class="revenue">
            {{ 54433.61 | currency:'EUR':'symbol':'1.2-2'}}
          </div>
          <p class="disclaimer">Stand: {{ today | date: 'dd.MM.YYYY'}} 03:42</p>

        </div>
        <div class="col-sm-3">
          <div class="mb-s"><img src="/assets/img/icons/mein-umsatz-span.svg" alt="Ist Spanne"
                                 [ngClass]="!digitalPaket ? 'disabled' : ''"></div>
          <h4 [ngClass]="!digitalPaket ? 'disabled' : ''">Ist Spanne
          </h4>
          <div class="revenue" *ngIf="digitalPaket">
            {{ 5.26 | number }} %
          </div>
          <p class="disclaimer" *ngIf="digitalPaket">Stand: {{ today | date: 'dd.MM.YYYY'}} 03:42</p>

          <p *ngIf="!digitalPaket" class="no-paket mt-s">Die aktuellen Werte zu Ist Spanne und RX Packungswert werden nur bei
            Nutzung des PHOENIX Digitalpakets angezeigt. --> <a
                href="https://phoenix-apothekenportal.de/phoenix-digitalpaket" target="_self">Jetzt freischalten</a>
          </p>
        </div>
        <div class="col-sm-2">
          <div class="mb-s"><img src="/assets/img/icons/mein-umsatz-span.svg" style="visibility: hidden" alt="Ist Spanne"
                                 [ngClass]="!digitalPaket ? 'disabled' : ''"></div>
          <h4 [ngClass]="!digitalPaket ? 'disabled' : ''" style="visibility: hidden">Entspricht
          </h4>
          <div class="revenue" *ngIf="digitalPaket">
            ≙
          </div>
        </div>
        <div class="col-sm-3">
          <div class="mb-s"><img src="/assets/img/icons/mein-umsatz-rx.svg" alt="RX Packungswert"
                                 [ngClass]="!digitalPaket ? 'disabled' : ''"></div>
          <h4 [ngClass]="!digitalPaket ? 'disabled' : ''">RX Packungswert <i class="fal fa-info-circle"
                                                                            matTooltip="Der dargestellte Wert entspricht dem ~ RX Packungswert zur ausgewiesenen Ist Spanne."></i>
          </h4>
          <div class="revenue" *ngIf="digitalPaket">
            {{ ((0.73/1.0315)/((5.26/100)-1+(1/1.0315))) | number:'1.1-1' }}0 €
          </div>
          <p class="disclaimer" *ngIf="digitalPaket">Stand: {{ today | date: 'dd.MM.YYYY'}} 03:42</p>
        </div>
      </div>
    </div>
  </div>
  <div class="panel">
    <div class="loading-indicator" *ngIf="loading">
      <i class="fal fa-spinner-third fa-spin fa-7x"></i>
    </div>
    <div class="chart" echarts [options]="options" (chartInit)="onChartInit($event)"></div>
    <div class="border-top" *ngIf="revenue">
      <div class="container p-0">
        <div class="row">
          <div class="col-md-6 col-xs-12">
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="text-sm-left text-md-right">
              <h4 class="mb-2">Download</h4>
              <button class="btn-primary btn-round" >
                <span class="far fa-arrow-down"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="revenue" *ngIf="loading"><i class="fal fa-spinner-third fa-spin"></i></div>
    </div>
  </div>
</div>
