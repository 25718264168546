import {Injectable, OnDestroy} from '@angular/core';
import {UserService} from './user.service';
import {catchError, map, switchMap, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {UserRestService} from './api/user.rest-service';
import {AuthService} from './api/auth.service';


@Injectable({
  providedIn: 'root',
})

export class NavService implements OnDestroy {
  private ngUnsubscribe$ = new Subject<any>();

  constructor(private userService: UserService,
              private userRestService: UserRestService,
              private auth: AuthService) {
  }

  canUseDc(node): boolean {

    return true;
  }

  isVisibleForUser(node): boolean {
    return true;
  }

  checkUserAndDc(node) {
    return true;
  }

  checkTemporalAndCanary(node) {
    return true;
  }

  /**
   * this function is used in route guard canActivate
   * the reason why we cannot use it in our component is because -
   * it is not using store and we are making raw http request. concurrent request will occur in that case.
   * route guard occurs only once while navigation or page refresh.
   */
  checkRights(route: string): Observable<boolean> | boolean {
    return true;
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
