<h1>Standard Planogramm & Optigramm</h1>
<p>Aktives Category Management ist im stationären Handel nicht mehr wegzudenken. Profitieren Sie daher durch ein auf Ihre Apotheke abgestimmtes Plano- sowie Optigramm.
  Erstellt in Zusammenarbeit mit einem Gremium aus Apotheker*innen und selbstverständlich unter Betrachtung pharmazeutischer Kriterien und entsprechender Marktdaten, erhalten Sie die Daten in digitaler Form.</p>
<p>Erhöhen Sie die Sichtbarkeit Ihrer Top-Produkte sowie gängigen Indikationen durch individuell gesetzte Themenschwerpunkte und optimieren Sie die Warenpräsentation in Ihrer Apotheke schnell und einfach.</p>
<p>Steigern Sie Ihren Abverkauf durch eine optimale Anordnung und Produktdarstellung im Sicht- und Freiwahlbereich.</p>
<p>Und eines versprechen wir: Gebündelt mit Ihrer Kompetenz profitieren Sie von einem einzigartigen Beratungsinstrument und schaffen so zusätzliches Vertrauen bei Ihrer Kundschaft und stärken die Kundenbindung langfristig.</p>
<p>Bitte teilen Sie uns Ihre Meinung bzw. Feedback unter <a >kooperation@phoenixgroup.eu</a> mit.
  Auf einen Dialog mit Ihnen freuen wir uns!</p>

<div class="row mt-l">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Planogramm_60cm_Winter_2023_24.jpg" class="img-border">
    </a>
  </div>
  <div class="col-md align-items-center d-flex"><a  target="_blank">Planogramm Winter 2023/2024 - 60cm</a>&nbsp; (15,51 MB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"  target="_blank"><span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Planogramm_80cm_Winter_2023_24.jpg" class="img-border">
    </a>
  </div>
  <div class="col-md align-items-center d-flex"><a target="_blank">Planogramm Winter 2023/2024 - 80cm</a>&nbsp; (17,74 MB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round" target="_blank"><span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Planogramm_100cm_Winter_2023_24.jpg" class="img-border">
    </a>
  </div>
  <div class="col-md align-items-center d-flex"><a target="_blank">Planogramm Winter 2023/2024 - 100cm</a>&nbsp; (15,7 MB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round" target="_blank"><span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Artikelliste_Planogramm_Winter23_24.jpg" class="img-border">
    </a>
  </div>
  <div class="col-md align-items-center d-flex"><a target="_blank">PZN-Liste Planogramm Winter 2023/2024</a>&nbsp; (97 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round" target="_blank"><span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Optigramm_2023_2024.jpg" class="img-border">
    </a>
  </div>
  <div class="col-md align-items-center d-flex"><a target="_blank">Optigramm 2023/2024</a>&nbsp; (2,92 MB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round" target="_blank"><span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
