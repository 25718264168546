import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digitaler-assistent',
  templateUrl: './digitaler-assistent.component.html',
  styleUrls: ['./digitaler-assistent.component.scss']
})
export class DigitalerAssistentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
