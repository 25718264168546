<h1>Monatsaktion</h1>
<p>
    Wenn Sie sich innerhalb der PHOENIX Kooperations-Partnerschaft für die Leistungsstufen 2 oder 3 entschieden haben,
    erhalten Sie nicht nur den Vorteil von hochwertigen Produktbevorratungen, abgestimmt an die Bedürfnisse der Endkunden
    sondern auch dazu perfekt angepasste Werbematerialien. Der Umsetzung einer attraktiven Customer Journey in Ihrer
    Apotheke steht damit nichts mehr im Weg.
</p>
<div class="position-relative">
    <img src="../../assets/dummy/monataktions.png" alt="LINDA Monatsaktion"
         class="img-fluid">
    <div class="shoppable-image-overlay">
        <ul class="shoppable-image-list">
            <li class="dot-1">
        <span class="shoppable-image-dot lindatip cursorPointer">
          <span class="lindatiptext">
            <strong>Plakate</strong><br><br>
              <p>
                2 Stück, gerollt im Paket (1x mit UVP´s / 1x blanko zum Eintragen der individuellen Preise). Möglichkeiten der Platzierung: Schaufenster / Kundenstopper /Eingangstür.
              </p>
          </span>
        </span>
            </li>
            <li class="dot-2">
        <span class="shoppable-image-dot lindatip cursorPointer">
          <span class="lindatiptext">
            <strong>Monatsflyer</strong><br><br>
            <p>
              100 Stück zum Auslegen in Ihrem Verkaufsraum. Möglichkeiten der Platzierung: HV / Zeitungsständer / Tisch. Individualisierung durch
              <a class="linda-link" href="#/page/leistungen?heading=PHOENIX%20Kooperations-Partnerschaft">SPARezept</a>
            </p>
          </span>
        </span>
            </li>
            <li class="dot-3">
        <span class="shoppable-image-dot lindatip cursorPointer">
          <span class="lindatiptext right">
            <strong>LINDA Partneraufkleber</strong><br><br>
            <p>
              Einmalige Ausstattung zum Start (individuell/optional einsetzbar). Nachbestellbar im PHOENIX Store .
            </p>
          </span>
        </span>
            </li>
            <li class="dot-4">
        <span class="shoppable-image-dot lindatip cursorPointer">
          <span class="lindatiptext right">
            <strong>be healthy!</strong><br><br>
              <p>
                Quartalsweise 100 Stück dem Paket beiliegend. Apotheken Lifestyle Magazin als wertvolles Kundenbindungsinstrument rund um die Themen Gesundheit und Wohlbefinden, zur kostenfreien Abgabe an Endverbraucher. Vorbestellung der jeweils nächsten Ausgabe über PHOENIX Store .
              </p>
          </span>
        </span>
            </li>
            <li class="dot-5">
        <span class="shoppable-image-dot lindatip cursorPointer">
          <span class="lindatiptext">
            <strong>Dekowürfel</strong><br><br>
              <p>
                Zum selbst Aufbauen in zwei unterschiedlichen Größen. Einsatzfähig im Verkaufsraum oder auch im Schaufenster.
              </p>
          </span>
        </span>
            </li>
            <li class="dot-6">
        <span class="shoppable-image-dot lindatip cursorPointer">
          <span class="lindatiptext right">
            <strong>Regalhighlighter</strong><br><br>
            <p>
              4 Stück als einmalige Ausstattung zum Start. Um Ihre Highlightprodukte im Regal in den Fokus zu rücken. Nachbestellbar im PHOENIX Store .
            </p>
          </span>
        </span>
            </li>
            <li class="dot-7">
        <span class="shoppable-image-dot lindatip cursorPointer">
          <span class="lindatiptext right">
            <strong>Mein Ratgeber Diabetes</strong><br><br>
            <p>
              Quartalsweise 10 Stück dem Paket beiliegend. Alles Wissenswerte rund um Diabetes, zur kostenfreien Abgabe an Endverbraucher. Vorbestellung der jeweils nächsten Ausgabe über PHOENIX Store .
            </p>
          </span>
        </span>
            </li>
        </ul>
    </div>
</div>
<p class="mt-xl">
    Sie möchten in die nächsthöhere Leistungsstufe wechseln und von den Bestandteilen der Monatsaktion profitieren?
    <br>
    Dann kontaktieren Sie Ihre Gebietsverkaufsleitung.
</p>
