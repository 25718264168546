<h1>
  Feedback
</h1>
<h2 class="mt-l">Vorsicht vor Feedback? Auf keinen Fall!</h2>
<p>
  Teilen Sie uns mit, was Ihnen auf dem Herzen liegt und fühlen Sie sich völlig frei, ob Sie uns zu den aufgeführten oder anderen Themen Ihr Feedback geben. Wir freuen uns, wenn Sie sich dafür einen Augenblick Zeit nehmen, denn nur so können wir auf Ihre Anregungen und Wünsche reagieren und unsere Kooperation gemeinsam mit Ihnen gestalten.
</p>

<span>
  Mögliche Themen können sein:
</span>
<ul>
  <li>PHOENIX Kooperations-Partnerschaft (PKP)</li>
  <li>Marketingmaterialien</li>
  <li>Warenzuteilung</li>
  <li>Rechnungsstellung</li>
  <li>Sonstiges</li>
</ul>
<form class="row">
<div class="col-md-12">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label style="white-space: pre" class="textarea-label">Ihre Wünsche, Anregungen und Ihr Feedback</mat-label>
    <textarea matInput
              readonly></textarea>
  </mat-form-field>
  <button class="btn-primary on-dark-bg mr-l">
    Absenden
  </button>
</div>
</form>
