import {Injectable} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Response} from '../../models/response.model';
import {Observable, Subject} from 'rxjs';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';

@Injectable({
    providedIn: 'root',
})
export class VaccineRestService extends RestServiceAbstract {
    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public requestVaccineCartForIdf(idf: any): Observable<Response> {
        return this.get(`/resource/vaccines/carts/${idf}/cart`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public updateVaccineReservation(idf: any, data: any): Observable<Response> {
        return this.post(`/resource/vaccines/carts/${idf}/cart`, data).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public getAllArchivedReservations(idf: any): Observable<Response> {
        return this.get(`/resource/vaccines/carts/${idf}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public getSingleCart(idf: any, id: number): Observable<Response> {
        return this.get(`/resource/vaccines/carts/${idf}/cart/${id}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    // ************************************
    // *********** Shipment ***************
    // ************************************
    public requestAllShipmentsForIdf(idf: any): Observable<Response> {
        return this.get(`/resource/vaccines/shipments/${idf}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public requestLatestShipmentsForIdf(idf: any, type: 'A' | 'O' | 'V' | 'B' | 'K' | 'Z'): Observable<Response> {
        return this.get(`/resource/vaccines/shipments/${idf}/${type}/latest`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    // Type A = Ärzte, O = Öffentlicher Dienst / Impfzentren
    public requestLastShipmentForIdf(idf: any, type: 'A' | 'O' | 'K' | 'Z'): Observable<Response> {
        return this.get(`/resource/vaccines/shipments/${idf}/${type}/shipment`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public getSingleShipment(idf: any, id: number): Observable<Response> {
        return this.get(`/resource/vaccines/shipments/${idf}/shipment/${id}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    getVaccineShopConfig() {
        return this.get('').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({errorCode: null, responseObject: false});
            })
        );
    }
}
