import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {ProductServiceAbstract} from './product-service.abstract';
import {WebshopRestService} from '../api/webshop-rest.service';
import * as fromReducers from '../../store/reducers';
import {Product} from '../../models/shop/product.model';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {
    GetBatchFromCacheAction,
    LoadDokuLightAction, SearchShopAction, UpdateScrollingPositionAction
} from '../../store/actions/doku-light.actions';
import {SearchAvailableProductRequest} from '../../models/shop/search-available-product-request.model';
import {ProductRequest} from '../../models/shop/product-request.model';

@Injectable()
export class DokuLightService extends ProductServiceAbstract {
    constructor(public store: Store<fromReducers.State>, @Inject(WebshopRestService) public webshopRestService: WebshopRestService) {
        super(store, webshopRestService);
    }

    updateScrollingPosition(pos: number): void {
        this.store.dispatch(new UpdateScrollingPositionAction(pos));
    }

    searchProducts(request: SearchAvailableProductRequest = new SearchAvailableProductRequest({})): void {
        this.store.dispatch(new SearchShopAction(request));
    }

    displayNewBatch(request: SearchAvailableProductRequest = new SearchAvailableProductRequest({})): void {
        let end: boolean = false;
        this.store.pipe(select(fromReducers.getDokuLightResponse))
            .subscribe(response => end = response.end).unsubscribe();

        // determine how much cache is left
        let cacheSize: number = 0;
        this.store.pipe(select(fromReducers.getDokuLightProductCache))
            .subscribe(cache => cacheSize = cache.length).unsubscribe();

        if (!end && cacheSize < 2 * 20) {
            this.store.dispatch(new LoadDokuLightAction(request));
        }

        // get batch from cache
        this.store.dispatch(new GetBatchFromCacheAction());
    }


    /**
     * GETTERS
     */
    getProductsLoadingObservable(): Observable<boolean> {
        this.productsLoading = this.store.pipe(select(fromReducers.getDokuLightLoading));
        return this.productsLoading;
    }

    getProductsResponseObservable(): Observable<ProductsResponse> {
        this.productsResponse$ = this.store.pipe(select(fromReducers.getDokuLightResponse));
        //this.checkAvailability();
        return this.productsResponse$;
    }

    getProductsRequestObservable(): Observable<ProductRequest> {
        this.productsRequest$ = this.store.pipe(select(fromReducers.getDokuLightRequest));
        //this.checkAvailability();
        return this.productsRequest$;
    }

    getProductsObservable(): Observable<Product[]> {
        this.products$ = this.store.pipe(select(fromReducers.getDokuLightProducts));
        return this.products$;
    }

    getScrollPositionObservable(): Observable<number> {
        this.scrollPosition$ = this.store.pipe(select(fromReducers.getDokuLightScrollPosition));
        return this.scrollPosition$;
    }

    getProductCacheObservable(): Observable<Product[]> {
        this.productCache$ = this.store.pipe(select(fromReducers.getDokuLightProductCache));
        return this.productCache$;
    }

}