import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsComponent} from './cms.component';
import {RouterModule} from '@angular/router';
import {FormsComponent} from './forms/forms.component';
import {ContentComponent} from './content/content.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {SuccessModule} from '../success/success.module';
import {NotificationModule} from '../notification/notification.module';
import {MonatsaktionComponent} from './monatsaktion/monatsaktion.component';
import {PhoenixStoreComponent} from '../phoenix-store/phoenix-store.component';
import { AktionenComponent } from './aktionen/aktionen.component';
import { PlanogrammComponent } from './planogramm/planogramm.component';
import { BeauftragtenprogrammComponent } from './beauftragtenprogramm/beauftragtenprogramm.component';
import { JahreskalenderComponent } from './jahreskalender/jahreskalender.component';
import { MonatsfolderComponent } from './monatsfolder/monatsfolder.component';
import { WarenbevorratungenComponent } from './warenbevorratungen/warenbevorratungen.component';
import { SonderaktionenComponent } from './sonderaktionen/sonderaktionen.component';
import { AllgemeinesUndAnsprechPartnerComponent } from './allgemeines-und-ansprech-partner/allgemeines-und-ansprech-partner.component';
import { AnmeldungComponent } from './anmeldung/anmeldung.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { WissenComponent } from './wissen/wissen.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { LeistungenComponent } from './leistungen/leistungen.component';
import { LeistungsstufenComponent } from './leistungsstufen/leistungsstufen.component';
import { KooperationspartnerComponent } from './kooperationspartner/kooperationspartner.component';
import { BestellungWerbemittelComponent } from './bestellung-werbemittel/bestellung-werbemittel.component';

export const routes = [
    {
        path: '', component: CmsComponent, pathMatch: 'full'
    },
];

@NgModule({
    declarations: [
        FormsComponent,
        ContentComponent,
        CmsComponent,
        MonatsaktionComponent,
        PhoenixStoreComponent,
        AktionenComponent,
        PlanogrammComponent,
        BeauftragtenprogrammComponent,
        JahreskalenderComponent,
        MonatsfolderComponent,
        WarenbevorratungenComponent,
        SonderaktionenComponent,
        AllgemeinesUndAnsprechPartnerComponent,
        AnmeldungComponent,
        FeedbackComponent,
        WissenComponent,
        NewsletterComponent,
        LeistungenComponent,
        LeistungsstufenComponent,
        KooperationspartnerComponent,
        BestellungWerbemittelComponent,
    ],
    exports: [
        FormsComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        SuccessModule,
        NotificationModule
    ]
})
export class CmsModule {
}
