/* tslint:disable:quotemark */
import * as fromReducers from '../store/reducers';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {User} from '../models/user.model';
import {Nav} from '../models/nav.model';
import {select, Store} from '@ngrx/store';
import {UserResponse} from '../models/user-response.model';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {
  GetAllDistributionCentersAdressAction,
  GetMainDistributionCentersAdressAction,
  LoadAuthTokenAction,
  LoadAuthTokensAction,
  LoadUserIDFsAction,
  LoadUserMainIdfAction,
  LoadUserShippingAdressesAction,
  LoadUserVZsAction,
  UserLogoutAction
} from '../store/actions/user.actions';
import {IdfBranches} from '../models/idf-branches.model';
import {Branch} from '../models/branch.model';
import {UserRestService} from './api/user.rest-service';
import {Adress} from '../models/adress.model';
import {LogService} from './utility/log.service';
import * as fromUserActions from '../store/actions/user.actions';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private user$: Observable<UserResponse>;
  private mainDistributionCentersAdress$: Observable<any>;
  private allDistributionCentersAdress$: Observable<any>;
  private userResponse: UserResponse;
  private ngUnsubscribe$ = new Subject<any>();
  private idfs: string[] = null;
  private allIdfs: any[] = null;
  private vzs: IdfBranches[];
  private adresses$: Observable<Adress[]>;
  private adressesLoading$: Observable<boolean>;
  private vzs$: Observable<IdfBranches[]>;

  private authToken$: Observable<string>;
  // PXPPO-1885 - Tokens for all of them sub IDFs, yo!
  private authTokens$: Observable<any>;

  private mainIdf$: Observable<string>;

  constructor(public store: Store<fromReducers.State>,
              public userRestService: UserRestService,
              @Inject('CMS_URL') private CMS_URL,
              public log: LogService) {
  }

  /**
   * Get UserResponse from store.
   *
   * @returns {UserResponse}
   */
  public getUserResponseFromStore(): UserResponse {
    this.user$ = this.store.pipe(select(fromReducers.getUserResponse));
    this.user$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(ur => this.userResponse = ur);
    return this.userResponse;
  }

  /**
   * Get User from store.
   *
   * @returns {User}
   */
  public getUser(): User {
    return new User({
      "title": "",
      "name": "Adrian Picasso",
      "lastName": "Picasso",
      "userSalutation": "Herr",
      "username": "adrian.picasso",
      "authorities": [
        4,
        6,
        8,
        9,
        12,
        77,
        15,
        80,
        16,
        17,
        81,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        30,
        31,
        32,
        33,
        34,
        35,
        37,
        38,
        39,
        40
      ],
      "authoritiesEnum": [
        "EXTRA_PRISMA",
        "CREDIT_NOTE",
        "SEMINAR_RECOMMENDATIONS",
        "MVDA",
        "BULK_BILL",
        "TRACK_TRACE",
        "MVDA_RECEIPT",
        "EXCLUSIVE_OFFERS",
        "DATEV",
        "PAYBACK",
        "REIMPORT",
        "EXTENDED_BENEFIT",
        "LIVPLUS_RECEIPT",
        "NACH",
        "STOCK_ENQUIRY",
        "BARGAIN_OFFERS",
        "NL_DEFECTS",
        "PARAGRAPH_73_AMG",
        "PPC",
        "INITIATIVE_DIABETES",
        "TOP_PRISMA",
        "VETERINARY_DRUG",
        "BASISLISTE",
        "BULK_BILL_RECEIPT",
        "ORDERS",
        "TRANSFUSION",
        "MVDA_LINDA",
        "LIVSANE",
        "E_SARE",
        "CONTACT_PERSON",
        "MY_SALES",
        "GROUP_ACCOUNT_OWNER",
        "MAILBOX"
      ],
      "featuresEnum": [
        "DATEV",
        "NACH",
        "TRACK_TRACE"
      ],
      "canary": true
    });
  }

  getUserLoadingObservable(): Observable<boolean> {
    return this.store.pipe(map(() => true));
  }

  getNav(): Nav {
    return new Nav();
  }

  getNavObservable(): Observable<Nav> {
    return this.store.pipe(select(fromReducers.getNav));
  }

  getNavLoadingObservable(): Observable<boolean> {
    return this.store.pipe(select(fromReducers.getNavLoading));
  }

  /**
   * @param authorities
   */
  hasRequiredAuthority(authorities: string[]): boolean {
    return true;
  }

  /**
   * @param feature
   */
  canUseFeature(feature: string): boolean {
    return true;
  }

  canUseDc(): boolean {
   return true;
  }

  hasDigitalPackage(): boolean {
    const user = this.getUser();

    if (this.isMarketing()) {
      return true;
    }

    return user.authoritiesEnum.indexOf('E_SARE') > -1;
  }

  dispatchContextualizedIdfsRequest(context: string) {
    this.store.dispatch(new LoadUserIDFsAction(context));
  }

  selectContextualizedIdfsObservable(context: string): Observable<any[]> {
    const idfs$ = this.store.pipe(select(fromReducers.getContextualizedIDFs, context));
    idfs$
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe(idfs => !idfs && this.dispatchContextualizedIdfsRequest(context));
    return idfs$;
  }

  getIdfsObservable(context: string = 'invoice'): Observable<string[]> {
    const idfs$ = this.store.pipe(select(fromReducers.getContextualizedIDFs, 'invoice'));
    idfs$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(ridfs => this.idfs = ridfs);
    if (!this.idfs) {
      this.store.dispatch(new LoadUserIDFsAction(context));
    }
    return idfs$;
  }

  loadAllIdfs(): Observable<any[]> {
    const idfs$ = this.store.pipe(select(fromReducers.getContextualizedIDFs, 'all'));
    idfs$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(ridfs => this.allIdfs = ridfs);
    if (!this.allIdfs) {
      this.userRestService.getAllIDFs()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.returnObject) {
          const loadAllIdfComplete = new fromUserActions.LoadUserIDFsCompleteAction({
            idfs: response.returnObject,
            context: 'all'
          });
          this.store.dispatch(loadAllIdfComplete);
        }
      });
    }
    return idfs$;
  }

  /**
   * @param idf
   */
  doesVzAlreadyExist(idf): boolean {
    if (!this.vzs) {
      return false;
    }

    const predicate = (vz) => vz.idf === idf;
    return (this.vzs.findIndex(predicate) > -1);
  }

  /**
   * @param idf
   */
  getDistributionCentersObservable(idf: string): Observable<IdfBranches[]> {
    if (!this.vzs$ || !this.doesVzAlreadyExist(idf)) {
      this.store.dispatch(new LoadUserVZsAction(idf));
      this.vzs$ = this.store.pipe(select(fromReducers.getUserVZs));
      this.vzs$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(rvzs => this.vzs = rvzs);
    }
    return this.vzs$;
  }

  /**
   * @param idf
   */
  getDistributionCentersByIdf(idf): Branch[] {
    let branches = null;
    this.vzs.forEach((item) => {
      if (item.idf === idf) {
        this.log.dir('UserService:getDistributionCentersByIdf:' + idf);
        this.log.dir(item);
        branches = item.branches;
      }
    });
    return branches;
  }

  /**
   * @param idf
   */
  getMainDistributionCenterByIdf(idf): Branch {
    let branch = null;
    this.vzs.forEach((item) => {
      if (item.idf === idf) {
        this.log.dir('UserService:getMainDistributionCenterByIdf:' + idf);
        this.log.dir(item);
        branch = item.branches[0];
      }
    });
    return branch;
  }

  getDistributionCentersLoadingObservable(): Observable<boolean> {
    return this.store.pipe(select(fromReducers.getUserVZsLoading));
  }

  getIdfsLoadingObservable(): Observable<boolean> {
    return this.store.pipe(select(fromReducers.getUserIDFsLoading));
  }

  getShippingAdressesObservable(): Observable<Adress[]> {
    if (!this.adresses$) {
      this.store.dispatch(new LoadUserShippingAdressesAction());
    }
    this.adresses$ = this.store.pipe(select(fromReducers.getUserShippingAdresses));
    return this.adresses$;
  }

  getShippingAdressesLoadingObservable() {
    this.adressesLoading$ = this.store.pipe(select(fromReducers.getUserShippingAdressesLoading));
  }

  getMainDistributionCentersAdressObservable() {
    if (!this.mainDistributionCentersAdress$) {
      this.store.dispatch(new GetMainDistributionCentersAdressAction());
      this.mainDistributionCentersAdress$ = this.store.pipe(select(fromReducers.getMainDistributionCentersAdress));
    }
    return this.mainDistributionCentersAdress$;
  }

  getAllDistributionCentersAdressObservable() {
    if (!this.allDistributionCentersAdress$) {
      this.store.dispatch(new GetAllDistributionCentersAdressAction());
      this.allDistributionCentersAdress$ = this.store.pipe(select(fromReducers.getAllDistributionCentersAdress));
    }
    return this.allDistributionCentersAdress$;
  }


  getMainIdfObservable(): Observable<string> {
    if (!this.mainIdf$) {
      this.store.dispatch(new LoadUserMainIdfAction());
      this.mainIdf$ = this.store.pipe(select(fromReducers.getUserMainIdf));
    }
    return this.mainIdf$;
    // return this.store.pipe(select(fromReducers.getUserMainIDF));
  }


  getMainIdfLoadingObservable(): Observable<boolean> {
    return this.store.pipe(select(fromReducers.getUserMainIdfLoading));
  }

  getAuthTokenObservable() {
    return {9999910: 'aslkjotirehbrlksaiewqjre'};
  }

  getAuthTokensObservable() {
    return [{9999910: 'aslkjotirehbrlksaiewqjre'}];
  }

  getAuthTokensLoadingObservable() {
    return false;
  }

  logout() {
    this.store.dispatch(new UserLogoutAction());
    // TODO Check whether this is necessary, but for now, null all that ish.
    this.user$ = null;
    this.authToken$ = null;
    this.mainIdf$ = null;

  }

  downloadPaybackAd(req) {
    return this.userRestService.downloadPaybackAd(req);
  }

  isAdmin(): boolean {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    if (user.authoritiesEnum.indexOf('GROUP_MARKETING') > -1 ||
        user.authoritiesEnum.indexOf('GROUP_KSC') > -1 ||
        user.authoritiesEnum.indexOf('GROUP_SALES') > -1 ||
        user.authoritiesEnum.indexOf('GROUP_TECHNICAL') > -1
    ) {
      return true;
    }

    return false;
  }

  isMarketing(): boolean {
    return this.getUserGroup() === 'GROUP_MARKETING';
  }

  isKsc(): boolean {
    return this.getUserGroup() === 'GROUP_KSC';
  }

  isDigitalPaket(): boolean {
    return this.getUser().authoritiesEnum.indexOf('E_SARE') > -1;
  }

  isOwner(): boolean {
    return this.getUserGroup() === 'GROUP_ACCOUNT_OWNER';
  }

  getUserGroup() {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    const index = user.authoritiesEnum.findIndex(s => s.includes('GROUP_'));
    return user.authoritiesEnum[index];
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

