import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  @ViewChild('chat') chat!: ElementRef;
  showChat = false;
  chatText: any[] = [];
  action: any;
  flow = '';

  constructor(
      public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  openChat() {
    this.showChat = true;
    setTimeout(() => {
      this.chatText.push({
        type: 'chat-agent',
        text: 'Herzlich Willkommen bei PHOENIX. Ich bin Ihr Digitaler Assistent.'
      });
      setTimeout(() => {
        this.chatText.push({type: 'chat-agent', text: 'Was möchten Sie tun?'});
        this.action = 'category';
        this.scrollBottom();
      }, 1000);
    }, 1000);
  }

  minimizeChat() {
    this.showChat = false;
    this.flow = '';
    this.action = '';
  }

  closeChat() {
    this.minimizeChat();
    this.chatText = [];
  }

  setFlow(flow: string, text, reload = false) {
    this.flow = flow;
    this.chatText.push({type: 'chat-user', text: text});
    if (this.flow === 'order') {
      this.chatText.push({type: 'chat-agent', text: 'Gerne nehme ich Ihre Bestellung entgegen'});
      this.scrollBottom();
    }

    if (this.flow === 'subsequent') {
      this.chatText.push({type: 'chat-agent', text: 'Gerne nehme ich Ihre Nachlieferung entgegen.'});
      this.scrollBottom();
    }

    if (this.flow === 'availability' && !reload) {
        this.chatText.push({type: 'chat-agent', text: 'Gerne prüfe ich die Verfügbarkeit.'});
        this.scrollBottom();

    }

    if (this.flow === 'expiration' && !reload) {
      this.chatText.push({
        type: 'chat-agent',
        text: 'Gerne prüfe ich das Verfalldatum zu Ihrem gewünschten Produkt.'
      });
      this.scrollBottom();
    }

    if (this.flow === 'delivery' && !reload) {
      this.chatText.push({type: 'chat-agent', text: 'Gerne prüfe ich einen voraussichtlichen Liefertermin für Sie.'});
      this.scrollBottom();
    }

    if (this.flow === 't&t') {
      if (!reload) {
        this.chatText.push({type: 'chat-agent', text: 'Ich schaue ob ich etwas zu Ihrer aktuellen Tour finden kann.'});
      }
      this.action = '';
      this.scrollBottom();
      this.loading();

      setTimeout(() => {
        this.chatText.push({
          type: 'chat-agent',
          text: 'Ihre 15:30 Uhr Lieferung wird mit einer leichten Verspätung um 15:50 Uhr eintreffen'
        });
        this.action = 't&t';
        this.scrollBottom();
      }, 3000);

      setTimeout(() => {
        this.chatText.push({type: 'chat-agent', text: 'Wie möchten Sie fortfahren?'});
        this.scrollBottom();
      }, 3500);
    }

    if (this.flow === 'order' || this.flow === 'subsequent' || this.flow === 'availability' || this.flow === 'expiration' || this.flow === 'delivery') {
      setTimeout(() => {
        this.chatText.push({type: 'chat-agent', text: 'Bitte nennen Sie mir die Pharmazentralnummer'});
        this.action = 'pzn';
        this.scrollBottom();
      }, 1000);
    }


  }

  setProduct(pzn: string, flow = '') {
    this.chatText.push({type: 'chat-user', text: pzn});
    if (flow !== '') {
      this.flow = flow;
    }

    if (this.flow === 'order' || this.flow === 'subsequent' || this.flow === 'availability') {
      this.chatText.push({type: 'chat-agent', text: 'Bitte nennen Sie mir nun die gewünschte Menge'});
      this.action = 'qty';
      this.scrollBottom();
    }

    if (this.flow === 'expiration') {
      this.chatText.push({type: 'chat-agent', text: 'Ihr angefragter Artikel hat das Verfalldatum März 2027'});
      this.action = '';
      this.scrollBottom();

      setTimeout(() => {
        this.chatText.push({type: 'chat-agent', text: 'Wie möchten Sie fortfahren?'});
        this.action = 'expire';
        this.scrollBottom();
      }, 1000);
    }

    if (this.flow === 'delivery') {
      this.chatText.push({
        type: 'chat-agent',
        text: 'Das angefragte Produkt ist laut dem Hersteller in KW 25 wieder lieferbar und kann zur Nachlieferung aufgenommen werden.'
      });
      this.action = '';

      setTimeout(() => {
        this.chatText.push({type: 'chat-agent', text: 'Wie möchten Sie fortfahren?'});
        this.action = 'delivery';
        this.scrollBottom();
      }, 1000);
    }
    this.scrollBottom();
  }

  setQty(qty: string, flow = '') {
    if (flow !== '') {
      this.flow = flow;
    }
    this.chatText.push({type: 'chat-user', text: qty});

    if (this.flow === 'order') {
      if (flow === '') {
        this.chatText.push({
          type: 'chat-agent',
          text: 'Wir haben das Produkt in der gewünschten Menge sofort für Sie verfügbar. Anlieferung wäre noch heute um 15:30 Uhr wenn Sie jetzt bestellen.'
        });
      }

      setTimeout(() => {
        this.chatText.push({type: 'chat-agent', text: 'Darf ich die Bestellung verbindlich aufnehmen?'});
        this.action = 'order';
        this.scrollBottom();
      }, 1000);
    }

    if (this.flow === 'subsequent') {
      this.chatText.push({type: 'chat-agent', text: 'Das Produkt ist nachlieferungsfähig.'});
      setTimeout(() => {
        this.chatText.push({type: 'chat-agent', text: 'Darf ich die Nachlieferung verbindlich aufnehmen?'});
        this.action = 'order-nach';
        this.scrollBottom();
      }, 1000);
    }


    if (this.flow === 'availability') {
      this.chatText.push({
        type: 'chat-agent',
        text: 'Wir haben das Produkt in der gewünschten Menge sofort für Sie verfügbar. Anlieferung wäre noch heute um 15:30 Uhr wenn Sie jetzt bestellen.'
      });
      setTimeout(() => {
        this.chatText.push({type: 'chat-agent', text: 'Wie möchten Sie fortfahren?'});
        this.action = 'available';
        this.scrollBottom();
      }, 1000);
    }

    this.scrollBottom();
  }

  resetFlow() {
    this.chatText.push({type: 'chat-user', text: 'Zurück zur Auswahl'});
    this.action = 'category';
    this.scrollBottom();
  }

  setResult(act, text: string) {
    this.chatText.push({type: 'chat-user', text: text});
    if (this.flow === 'order') {
      if (act) {
        this.chatText.push({type: 'chat-agent', text: 'Bestellung erfolgreich!'});
      } else {
        this.chatText.push({type: 'chat-agent', text: 'Bestellung abgebrochen!'});
      }
      this.scrollBottom();
    }

    if (this.flow === 'subsequent') {
      if (act) {
        this.chatText.push({type: 'chat-agent', text: 'Nachlieferung erfolgreich aufgenommen'});
      } else {
        this.chatText.push({type: 'chat-agent', text: 'Nachlieferung abgebrochen!'});
      }
      this.scrollBottom();
    }

    setTimeout(() => {
      this.chatText.push({type: 'chat-agent', text: 'Was möchten Sie tun?'});
      this.action = 'category';
      this.scrollBottom();
    }, 1000);
  }

  scrollBottom() {
    const chatEl = this.chat.nativeElement;

    setTimeout(() => {
      chatEl.scrollBy({top: chatEl.scrollHeight + 5, behavior: 'smooth'});
    }, 500);
  }

  private loading() {
    this.chatText.push({type: 'chat-agent', text: 'loading'});
    setTimeout(() => {
      this.chatText.splice(this.chatText.findIndex(ch => ch.text === 'loading'));
    }, 3000);
  }
}
