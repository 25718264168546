<div class="d-flex justify-content-center">
  <nav class="navbar fixed-top navbar-expand-xl navbar-dark navbar-phoenix py-0">
    <a class="navbar-brand position-relative px-xxl mr-xxs" href="#/dashboard">
      <img src="assets/img/phoenix-logo_white.svg" width="50" alt="">
      <pre class="nav-environment--qa">&nbsp;DEMO</pre>
    </a>
    <div class="collapse navbar-collapse" id="navbarAportal">
      <ul class="navbar-nav mr-auto">
        <li class="position-relative nav-item mr-l pl-xs {{(item.tree && item.showInNav) ? 'dropdown' : ''}}" *ngFor="let item of main">
          <a class="nav-link dropdown-toggle pl-0" href="#" role="button" data-toggle="dropdown" aria-expanded="false"
             *ngIf="item.tree && item.showInNav">{{item.navLinkName | translate}}</a>
          <div class="dropdown-menu" *ngIf="item.tree">
            <ng-container *ngFor="let subitem of item.tree">
              <a *ngIf="subitem.showInNav && subitem.type !== NavType.DEINEAPOTHEKESTORE && subitem.type !== NavType.PRIVATSORTIMENT && subitem.type !== NavType.PDF"
                 class="dropdown-item py-xxs"
                 routerLink="/{{subitem.path}}"
                 [queryParams]="subitem.extUrl ? {url: subitem.extUrl} : ''">
                {{subitem.navLinkName | translate}}</a>

              <a class="dropdown-item py-xxs"
                 *ngIf="subitem.type === NavType.DEINEAPOTHEKESTORE"
                 [attr.target]="subitem.shouldOpenInNewTab ? '_blank' : null"
                 attr.data-cy="nav-item-{{subitem.dataCy}}">
                {{subitem.navLinkName | translate}} <i class="fal fa-external-link"></i>

              </a>
              <a class="dropdown-item py-xxs"
                 *ngIf="subitem.type ===  NavType.PRIVATSORTIMENT"
                 [attr.target]="subitem.shouldOpenInNewTab ? '_blank' : null"
                 attr.data-cy="nav-item-{{subitem.dataCy}}">
                {{subitem.navLinkName | translate}} <i class="fal fa-external-link"></i>
              </a>
            </ng-container>
          </div>
          <a *ngIf="!item.tree && item.showInNav " class="nav-link pl-0" routerLink="/{{item.path}}" [queryParams]="item.extUrl ? {url: item.extUrl} : ''">{{item.navLinkName | translate}}</a>
          <a *ngIf="item.tree && !item.showInNav " class="d-none" routerLink="/{{item.path}}" [queryParams]="item.extUrl ? {url: item.extUrl} : ''">{{item.navLinkName | translate}}</a>
        </li>
      </ul>
      <app-header></app-header>
      <app-quick-nav></app-quick-nav>
    </div>
  </nav>
</div>
