import {Component, ElementRef, OnInit} from '@angular/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent implements OnInit {

  constructor(
      private elRef: ElementRef
  ) { }

  ngOnInit(): void {
  }

  scrollTo($event: MouseEvent) {
    const target = $event.target;

    if (target['classList'].contains('scrollTo')) {
      $event.stopPropagation();
      $event.preventDefault();

      const hash = target['hash'].replace('#', '').trim();

      this.elRef.nativeElement.getElementsByClassName(hash)[0].scrollIntoView({behavior: 'smooth'});
    }

  }
}
