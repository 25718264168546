import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {map, takeUntil} from 'rxjs/operators';
import {Response} from '../../models/response.model';
import {Observable, Subject, throwError} from 'rxjs';
import {TrackRequestModel} from '../../models/shop/track-request.model';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TrackingRestService extends RestServiceAbstract {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public requestTracking(request: TrackRequestModel): Observable<Response> {
        return this.post(`/resource/shipmenttracking`, request).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
