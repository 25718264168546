import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DatevService} from '../../../../../core/services/integrations/datev.service';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatomoService} from '../../../../../core/services/matomo.service';
import {ThemePalette} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  logs: [];
  types: [{
    attribute: string;
    value: string;
  }];
}

@Component({
  selector: 'app-datev',
  templateUrl: './datev.component.html',
  styleUrls: ['./datev.component.scss']
})
export class DatevComponent implements OnInit, OnDestroy, OnChanges {
  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  public authorized = new Subject<boolean>();
  public active = false;
  public loading = false;
  public saved = false;
  public submit = false;
  public deactivated = false;
  public error;
  public phxDocuments = [
    {value: 'Elektronische Sammelrechnung', attribute: 'E_INVOICE'},
    {value: 'Elektronische PAYBACK Sammelrechnung', attribute: 'E_PAYBACK_INVOICE'},
    {value: 'Elektronische Valuta', attribute: 'E_VALUTA'},
    {value: 'Elektronische Kontoauszug', attribute: 'E_ACCOUNT_STATEMENT'},
    {value: 'Elektronische Vorteilserläuterung / Kaufstruktur', attribute: 'E_ADVANTAGE_CALCULATION'},
  ];
  public datevDocuments = [
    'Rechnungseingang', 'Rechnungsausgang', 'Kontoauszug', 'Sonstige'
  ];
  public show = false;
  private checkDocs = false;
  @Input() idf;
  @Input() mainIdf;
  datevResponse;
  datevUrl: string;
  clientIds = [];
  primary: ThemePalette = 'primary';
  sendError = false;
  resend = false;
  newToken = false;
  breater = null;

  datevForm = this.fb.group({
    clientId: [''],
    folder: [''],
    category: [''],
    register: [''],
    note: [''],
    notify: [''],
    documents: this.fb.group({}),
    send: this.fb.group({})
  });

  clientForm = this.fb.group({
    berater: ['', Validators.pattern(/^[0-9]\d*$/)],
    client: ['', Validators.pattern(/^[0-9]\d*$/)],
  });


  constructor(
      private fb: FormBuilder,
      private datevService: DatevService,
      private route: ActivatedRoute,
      private router: Router,
      public matomoService: MatomoService,
      public dialog: MatDialog
  ) {
    const df = this.datevForm.get('documents') as FormGroup;
    const sf = this.datevForm.get('send') as FormGroup;
    this.phxDocuments.forEach(function (doc) {
      df.addControl(doc.attribute, new FormControl());
      sf.addControl(doc.attribute + '-send', new FormControl());
    });


  }

  ngOnInit(): void {
    this.getConfig(9999910);
    this.show = true;
  }

  onRegistration() {
    window.location.href = this.datevUrl;
  }

  onSubmit() {
    this.datevService.saveConfiguration(this.datevForm.value, this.idf)
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe(res => {
      this.submit = true;
      this.saved = res.returnObject === true;
    });
   }

  getClients() {
    this.datevService.getDatevClients()
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe(res => {
      this.clientIds = res.returnObject.clientList;
      console.log(this.clientIds);
    }, err => {
      this.show = false;
      this.error = 'Die Verbindung zu DATEV wurde unterbrochen, bitte neu konfigurieren.';
      this.loading = false;
      this.active = false;
    });
  }

  getConfig(idf) {
    if (idf !== undefined) {
      const datevData = {
        clientId: '1234567-1000',
        folder: [''],
        category: [''],
        register: [''],
        note: [''],
        notify: [''],
        documents: {
          'E_INVOICE': 'Rechnungseingang',
          'E_PAYBACK_INVOICE': 'Rechnungseingang',
          'E_VALUTA': 'Rechnungsausgang',
          'E_ACCOUNT_STATEMENT': 'Kontoauszug',
          'E_ADVANTAGE_CALCULATION': 'Sonstige'
        },
        send: {
          'E_INVOICE-send': true,
          'E_PAYBACK_INVOICE-send': false,
          'E_VALUTA-send': true,
          'E_ACCOUNT_STATEMENT-send': true,
          'E_ADVANTAGE_CALCULATION-send': false
        }
      };

      this.datevForm.patchValue(datevData);
    }
  }

  getPhoenixDocuments(idf) {
    if (idf !== undefined) {
      this.datevService.getPhoenixDocuments(idf)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
            if (res.errorCode === null) {
              this.phxDocuments = res.returnObject.filter(document => {
                return document.attribute.indexOf('E_') === 0;
              });

              const df = this.datevForm.get('documents') as FormGroup;
              const sf = this.datevForm.get('send') as FormGroup;
              this.phxDocuments.forEach(function (doc) {
                df.addControl(doc.attribute, new FormControl());
                sf.addControl(doc.attribute + '-send', new FormControl());
              });
            }
          },
          err => {
            this.error = 'Ihre Belege konnten nicht abgerufen werden. Bitte versuchen Sie es später erneut.';
          });
    }
  }

  getDatevDocuments(idf) {
    if (idf !== undefined && this.datevDocuments.length < 1) {
      this.datevService.getDatevDocuments(idf)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
            if (res.errorCode === null && res.returnObject !== null) {
              this.datevDocuments = res.returnObject;
            } else if (res.errorCode !== null) {
              this.show = false;
              this.error = 'Die Schnittstelle kann nicht aktiviert werden. Bitte prüfen Sie die Einstellungen auf DATEV Seite und stellen Sie sicher, dass alle Freigaben erteilt sind.';
              this.active = false;
              this.loading = false;
            }
          },
          error => {
            this.show = false;
            this.error = 'Die Verbindung zu DATEV wurde unterbrochen, bitte neu konfigurieren.';
            this.active = false;
            this.loading = false;
          });
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    this.show = true;
    // if (changes.idf.currentValue !== null) {
    //     const idf = changes.idf.currentValue;
    //     if (idf !== undefined) {
    //         if (idf !== this.idf){
    //             if (this.newToken === true) {
    //                 this.show = true;
    //                 this.newToken = false;
    //             }
    //         }
    //         this.idf = idf;
    //     }
    //
    //     if (this.checkDocs) {
    //         this.getPhoenixDocuments(idf);
    //         this.getConfig(idf);
    //     }
    //
    //     if (idf !== undefined && !this.show) {
    //         this.datevService.hasDatev(idf)
    //             .pipe(takeUntil(this.ngUnsubscribe$))
    //             .subscribe(result => {
    //                 this.authorized.next(result.returnObject.user);
    //                 this.active = result.returnObject.active;
    //                 this.show = result.returnObject.user && this.active;
    //                 if (result.returnObject.error === 'invalid_grant') {
    //                     this.error = 'Die Verbindung zu DATEV wurde unterbrochen, bitte neu konfigurieren.';
    //                 }
    //                 this.loading = false;
    //             }, err => {
    //                 this.show = false;
    //                 this.error = 'Die Verbindung zu DATEV wurde unterbrochen, bitte neu konfigurieren.';
    //                 this.loading = false;
    //                 this.active = false;
    //             });
    //     }
    //
    //     this.authorized.pipe(takeUntil(this.ngUnsubscribe$))
    //         .subscribe(a => {
    //             this.checkDocs = a;
    //             if (a) {
    //                 this.getPhoenixDocuments(idf);
    //                 this.getConfig(idf);
    //             }
    //         });
    //
    //     this.onSendError();
    // }
  }

  onSubmitRedirect() {
    const data = this.clientForm.value;
    const clientId = data.berater + '-' + data.client;

    sessionStorage.setItem('clientId', clientId);
    sessionStorage.setItem('idf', this.idf);
    this.datevUrl = this.datevService.getDatevUrl(clientId);
    this.newToken = false;
    this.onRegistration();
  }

  onDeactivate() {
    this.datevService.deactivate(this.idf)
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe(res => {
      if (res.errorCode === null) {
        this.datevForm.reset();
        sessionStorage.removeItem('clientId');
        this.deactivated = true;
        this.authorized.next(false);
        this.active = false;
        this.show = false;
      }
    });
  }

  openDialog(): void {
    // this.datevService.getDatevLogs(this.idf)
    // .pipe(takeUntil(this.ngUnsubscribe$))
    // .subscribe(res => {
    //   const logs = res.returnObject;
    //
    //   const dialogRef = this.dialog.open(DatevLogsDialog, {
    //     width: '700px',
    //     data: {logs: logs, types: this.phxDocuments},
    //   });
    //
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log('The dialog was closed');
    //   });
    // });
  }

  onSendError() {
    if (this.idf !== undefined) {
      this.datevService.getLatestError(this.idf)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
        if (res.returnObject != null && res.returnObject.status === 'FAILURE') {
          this.sendError = true;
        } else {
          this.sendError = false;
        }
      });
    }
  }

  sendAgainFiles() {
    this.datevService.sendLatestFiles(this.idf)
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe(res => {
      this.sendError = !res.returnObject;
      this.resend = true;
    });
  }


  createNewToken() {
    this.show = false;
    this.clientForm.patchValue({berater: this.breater});
    this.newToken = true;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

@Component({
  selector: '<datev-logs-dialog>',
  templateUrl: 'datev-logs-dialog.html',
})
export class DatevLogsDialog {
  constructor(
      public dialogRef: MatDialogRef<DatevLogsDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getType(type) {
    let typ = this.data.types.find(t => t.attribute === type);
    return typ ? typ.value : '';
  }
}
