export class ProductRequest {
    page: number;
    search: string;

    constructor(obj: any = {}) {
        this.page = obj && (obj.pageIndex + 1) || 1;
        this.search = obj && obj.search || '';
    }

}
