import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anmeldung',
  templateUrl: './anmeldung.component.html',
  styleUrls: ['./anmeldung.component.scss']
})
export class AnmeldungComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
