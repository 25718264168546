import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './api/auth.service';
import {LogService} from './utility/log.service';
import {UserService} from './user.service';
import {UrlService} from './url.service';

@Injectable()
export class AuthGuardService implements CanActivateChild {
    constructor(@Inject(AuthService) public authService: AuthService,
                @Inject(UserService) public us: UserService,
                @Inject(UrlService) private urlService: UrlService,
                private route: ActivatedRoute,
                public log: LogService,
                public router: Router) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // if (!this.authService.isAuthenticated()) {
        //     this.urlService.lastUrl = 'logout';
        //     this.router.navigateByUrl('login');
        //     return false;
        // }
        // let has = true;
        // const nav = this.us.getNav();
        //
        // const requiredAuthorities = nav.getAuthoritiesForRoute(state.url);
        // if (requiredAuthorities && requiredAuthorities.length > 0) {
        //     has = this.us.hasRequiredAuthority(requiredAuthorities);
        // }
        //
        // const feature = nav.getFeatureForRoute(state.url);
        // if (feature !== '') {
        //     has = this.us.canUseFeature(feature);
        // }

        return true;
    }
}
