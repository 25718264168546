<div id="content" class="mb-0" >
<!--    <img *ngIf="page?.banner_image" src="{{page.banner_image}}" alt="{{page.page_title}}">-->
    <div class="panel">
        <div class="row mb-m">
            <a href="#/pkp">
                <i class="material-icons"> keyboard_arrow_left </i> Zurück
            </a>
        </div>
<!--        <h1 *ngIf="page.show_title">{{page.page_title}}</h1>-->
<!--        <app-page-content [body]="body" [public]="page.public" *ngIf="link!== 'monatsaktion'"></app-page-content>-->
        <app-monatsaktion *ngIf="link === 'monatsaktion'"></app-monatsaktion>
        <app-aktionen *ngIf="link === 'aktionen'"></app-aktionen>
        <app-beauftragtenprogramm *ngIf="link === 'beauftragtenprogramm'"></app-beauftragtenprogramm>
        <app-planogramm *ngIf="link === 'std-planogramm-optigramm'"></app-planogramm>
        <app-jahreskalender *ngIf="link === 'jahreskalender'"></app-jahreskalender>
        <app-monatsfolder *ngIf="link === 'monatsfolder'"></app-monatsfolder>
        <app-warenbevorratungen *ngIf="link === 'warenbevorratungen'"></app-warenbevorratungen>
        <app-sonderaktionen *ngIf="link === 'sonderaktionen'"></app-sonderaktionen>
        <app-allgemeines-und-ansprech-partner *ngIf="link === 'allgemeines-und-ansprech-partner'"></app-allgemeines-und-ansprech-partner>
        <app-anmeldung *ngIf="link === 'anmeldung'"></app-anmeldung>
        <app-feedback *ngIf="link === 'feedback'"></app-feedback>
        <app-wissen *ngIf="link === 'wissen'"></app-wissen>
        <app-newsletter *ngIf="link === 'newsletter'"></app-newsletter>
        <app-leistungen *ngIf="link === 'leistungen'"></app-leistungen>
        <app-leistungsstufen *ngIf="link === 'leistungsstufen'"></app-leistungsstufen>
        <app-kooperationspartner *ngIf="link === 'kooperationspartner'"></app-kooperationspartner>
        <app-bestellung-werbemittel *ngIf="link === 'bestellung-werbemittel'"></app-bestellung-werbemittel>
        <app-linda-leistungen *ngIf="link === 'linda-leistungen'"></app-linda-leistungen>
    </div>
</div>
<app-page-footer [foot]="footer" *ngIf="!loading"></app-page-footer>
