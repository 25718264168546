<article class="content">
    <div class="panel">
        <div class="row justify-content-center align-items-center d-flex">
            <div class="col-md-8 col-sm-8">
                <p>
                    Hier haben Sie die
                    Möglichkeit,
                    Schnittstellen zu anderen
                    Systemen zu
                    konfigurieren.
                </p>
            </div>
            <div class="col-md-4 col-sm-4 ">
                <form [formGroup]="userForm">
                    <mat-form-field appearance="outline">
                        <mat-label>IDF-Nummer</mat-label>
                        <input [value]="selectedIdf" matInput formControlName="idfInput">
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div class="accordion-style-1" id="accordion">
            <div *ngIf="loading" class="text-center py-l">
                <i class="fal fa-spinner-third fa-spin"></i> Laden...
            </div>
            <div *ngIf="loading && integrations.length === 0" class="text-center py-l">
                Keine Schnittstellen verfügbar
            </div>
            <div class="card">
                <div class="card-header">
                    <button aria-controls="collapseOne"
                            aria-expanded="false"
                            class="btn btn-link btn-collapsible"
                            data-target="#collapseOne" data-toggle="collapse"
                            type="button">
                        <img src="assets/img/integrations/DATEV_Datenservice_Signet_ohne_Kachel_RGB.png" alt="Datev" style="max-height: 80px">
                    </button>
                </div>
                <div class="collapse show" data-parent="#accordion" id="collapseOne">
                    <div class="card-body">
                        <app-datev [idf]="selectedIdf" [mainIdf]="mainIdf"></app-datev>
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>

