import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.css']
})
export class VideoDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
