import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../../core/services/user.service';
import {Observable, Subscription} from 'rxjs';
import {FormBuilder} from '@angular/forms';

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit, OnDestroy {
    public input = false;
    public loading = false;
    public getIdf: Subscription;
    public selectedIdf;
    public mainIdf;
    public idfs$: Observable<any[]>;
    public integrations = [];

    userForm = this.fb.group({
        idfInput: ['']
    });

    constructor(
        @Inject(UserService) public userService: UserService,
        private fb: FormBuilder
    ) {
        this.selectedIdf = 9999910;
        this.userForm.patchValue({idfInput: this.selectedIdf});
    }

    setIdf(event) {
        this.selectedIdf = event.value;
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        // this.getIdf.unsubscribe();
    }

}
