import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {ChatbotComponent} from './chatbot.component';

// export const routes = [
//   {path: '', component: ChatbotComponent, pathMatch: 'full'},
// ];

@NgModule({
  declarations: [
    ChatbotComponent
  ],
  exports: [
    ChatbotComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    // RouterModule.forChild(routes),

  ]
})
export class ChatbotModule {
  // static routes = routes;
}
