<h1>
  Wissen
</h1>
<p>
  Ziel unseres Kooperationbeauftragten-Programmes ist es, Sie in ihrem stressigen Apothekenalltag durch exklusive Insights zu spannenden Fachthemen und zahlreichen Tipps zur Endkundenaktivierung zu unterstützen und Ihre Kunden langfristig zu begeistern.
</p>
<p>
  Auf dieser Seite finden Sie alle Informationen rund um die Kooperation, speziell für Sie und das ganze Team in der Apotheke, in kurzen Videos aufbereitet.
</p>

<div class="text-center mt-l screen-background">
    <img src="../../assets/dummy/videoo.png">
</div>


<h2 class="mt-l">Snackable Content!</h2>
<p>
  Alle Informationen rund um die Kooperation, speziell für Sie und das ganze Team in der Apotheke, in kurzen Videos aufbereitet. Schauen Sie sich all unsere Schulungen und Webinare im Nachgang zum Zeitpunkt Ihrer Wahl an und holen Sie sich wertvolle Tipps & Tricks für Ihren Apothekenalltag.
</p>

<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 snackable-cards">
  <div class="col mb-4">
    <div class="card h-100">
      <div class="card-body p-0">
        <a class="linki">Aufzeichnung: Webinar Cross-Selling</a>
      </div>
      <img src="../../assets/dummy/cross-selling-webinar-ss.png"
           class="cursorPointer card-img-bottom"
           alt="Aufzeichnung: Webinar Cross-Selling" >
    </div>
  </div>
  <div class="col mb-4">
    <div class="card h-100">
      <div class="card-body p-0">
        <a class="linki">Erklärung und Zusammenfassung des Programms</a>
      </div>
      <img src="../../assets/dummy/download.png"
           class="cursorPointer card-img-bottom"
           alt="Aufzeichnung: Webinar Cross-Selling" >
    </div>
  </div>
</div>
