<div class="row">
  <div class="col-md">
    <h1>
      Allgemeine Informationen & Ansprechpartner
    </h1>

    <p>
      Wir freuen uns, dass Sie Teil des Beauftragtenprogramms sind oder werden wollen! Mit Ihnen an unserer Seite werden wir die neue PHOENIX Kooperation gestalten und gemeinsam wachsen lassen!
    </p>
    <p>
      Durch einen <strong>engen Austausch</strong> werden wir Ihnen <strong>alle Vorteile der Kooperation</strong> aufzeigen und Ihnen mit <strong>Tipps und Zusatzwissen</strong> die bestmögliche Voraussetzung bieten, um Sie in der Apotheke vor Ort zu unterstützen.
    </p>
    <p>
      Nutzen Sie die Feedback-Kachel auf der Startseite, um uns <strong>aktiv Feedback zu geben</strong>. Sichten Sie unsere Erklärvideos oder lesen Sie die Newsletter der letzten Monate nach. Bei Rückfragen oder Anmerkungen stehen Ihnen zudem unseren beiden Ansprechpartner stets zur Verfügung.
    </p>
    <p>
      Gemeinsam mit Ihnen freuen wir uns auf den Austausch und darauf, die Kooperation mit Leben zu füllen!
    </p>
    <p>
      Es grüßt Sie herzlich, Ihr Kooperationsbeauftragten-Team.
    </p>
  </div>
  <div class="col-md-3">
    <img src="../../assets/dummy/Allgemeines_Ansprechpartner_image.png"
         alt="Allgemeines Ansprechpartner Bild">
  </div>
</div>
<div class="row mt-xxl">
  <div class="col-md-12">
    <h2>Ansprechpartner bei Rückfragen</h2>
  </div>
</div>
<div class="row">
  <div class="col-xl-6 mb-s">
    <div class="media">
      <img
          src="../../assets/dummy/2023_PKP_beauftragtenprogramm_AP_jessy_winter_web.jpg"
          alt="Jessy Winter" class="mr-3" width=128>
      <div class="media-body">
        <strong class="mb-s d-block">Jessy Winter</strong>
        0 621 850 5 - 8533<br>
        <a >kooperation@phoenixgroup.eu</a>
      </div>
    </div>
  </div>

  <div class="col-xl-6 mb-s">
    <div class="media">
      <img
          src="../../assets/dummy/2023_PKP_beauftragtenprogramm_AP_roger_werner_web.jpg"
          alt="Roger Werner" class="mr-3" width=128>
      <div class="media-body">
        <strong class="mb-s d-block">Roger Werner</strong>
        0 621 850 5 - 445<br>
        <a >kooperation@phoenixgroup.eu</a>
      </div>
    </div>
  </div>
</div>

