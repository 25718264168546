import {Injectable, OnDestroy} from '@angular/core';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {Response} from '../../../models/response.model';
import {Observable, Subject, throwError} from 'rxjs';
import {RestServiceAbstract} from '../../../abstracts/rest-service.abstract';
import {ResponseSnakeModel} from '../../../models/response-snake.model';

@Injectable({
  providedIn: 'root',
})
export class AdsRestService extends RestServiceAbstract implements OnDestroy {
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  getAds(search?: string) {
    return this.get('/cms/admin/ads', {params: {search: search}})
    .pipe(takeUntil(this.ngUnsubscribe$),
        map(data => {
          return new ResponseSnakeModel(data);
        })
    );
  }

  getCurrentAds(location: string) {
    return this.get('/cms/ads', {params: {location: location}})
    .pipe(takeUntil(this.ngUnsubscribe$),
        map(data => {
          return new ResponseSnakeModel(data);
        })
    );
  }
  //
  // saveAd(ad) {
  //   return this.post('/cms/admin/ads',  ad).pipe(takeUntil(this.ngUnsubscribe$),
  //       map(data => {
  //         return new ResponseSnake(data);
  //       }));
  // }
  //
  // deleteAd(id) {
  //   this.delete('/cms/admin/ads/delete', {params: {id: id}})
  //   .pipe(takeUntil(this.ngUnsubscribe$),
  //       map(data => {
  //         return new ResponseSnake(data);
  //       })
  //   );
  // }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }
}
