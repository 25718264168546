import {Component,Inject, OnInit, OnDestroy} from '@angular/core';
import {LindaRestService} from '../../../../core/services/api/linda.rest-service';
import {UserService} from '../../../../core/services/user.service';
import {ScrollingService} from '../../../../core/services/scrolling.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {NavType} from '../../../../core/models/nav-item.model';
import {IdfSelectionDialogComponent} from '../../idf-selection-dialog/idf-selection-dialog.component';
import {MatomoService} from '../../../../core/services/matomo.service';

@Component({
    selector: 'app-monatsaktion',
    templateUrl: './monatsaktion.component.html',
    styleUrls: ['./monatsaktion.component.scss']
})
export class MonatsaktionComponent implements OnInit, OnDestroy {

    public NavType: typeof NavType = NavType;

    public ngUnsubscribe$ = new Subject<any>();
    public lindaIdfs = [];
    public tokens = [];

    constructor(private lindaRestService: LindaRestService,
                public userService: UserService,
                public dialog: MatDialog,
                @Inject('DA_STORE_URL') public DA_STORE_URL: string,
                @Inject('DA_STORE_REFERRER') public DA_STORE_REFERRER: string,
                @Inject('CDN') public CDN,
                private scrollingService: ScrollingService,
                private matomoService: MatomoService) {
        // if (!this.userService.isAdmin()) {
        //     const idf$ = this.userService.selectContextualizedIdfsObservable('all');
        //     idf$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(accounts => {
        //         if (accounts !== undefined && accounts !== null) {
        //             for (const account of accounts) {
        //                 if (account.linda.indexOf('010') > -1 || account.linda.indexOf('110') > -1 || account.linda.indexOf('111') > -1 || account.linda.indexOf('011') > -1) {
        //                     this.lindaIdfs.push(account.idf);
        //                 }
        //             }
        //         }
        //     });
        //
        // }
        //     const tokens$ = this.userService.getAuthTokensObservable();
        //     tokens$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(tokens => {
        //         for(const token in tokens) {
        //             let item = {key: '', value: ''};
        //             item.key = token;
        //             item.value = tokens[token];
        //             this.tokens.push(item);
        //         }
        //     });


        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        });
    }

    ngOnInit(): void {
    }


    goToLinda(idf) {
        this.lindaRestService.getRedirect(idf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(ret => {
            if (ret.errorCode === null) {
                this.trackClick('Jetzt entdecken');
                window.location = ret.returnObject.url + ret.returnObject.token;
            }
        });
    }

    openIdfSelectionDialog(navType, imageFileName: string, heading: string, message: string, tokens) {
        const idfSelectionDialogConfig = new MatDialogConfig();
        const authTokens = tokens;
        const authTokensLoading = this.userService.getAuthTokensLoadingObservable();
        const userName = this.userService.getUser().username;

        idfSelectionDialogConfig.width = '50vw';
        idfSelectionDialogConfig.data = {
            navType: navType,
            userName: userName,
            imageFileName: imageFileName,
            heading: heading,
            message: message,
            authTokens: authTokens,
            authTokensLoading: authTokensLoading
        };

        const dialogRef = this.dialog.open(IdfSelectionDialogComponent, idfSelectionDialogConfig);
    }


    allowed(authTokens) {
        const allowed = [];
        for (const idf of authTokens) {
            if (this.lindaIdfs.indexOf(idf.key) > -1) {
                allowed.push(idf);
            }
        }
        return allowed;
    }

    trackClick(name: string) {
        this.matomoService.trackEvent('LINDA', 'Klick auf Button', name);
    }

    openFile() {
        // window.open('https://cspapssacc.blob.core.windows.net/cms/files/2_anleitung.pdf', '_blank');
        // this.trackClick('Download Anleitung');
        console.log('Click');
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

}
