import {Inject, Injectable, OnDestroy} from '@angular/core';
import {TrackItem} from '../models/shop/track-item.model';
import {Observable, Subject} from 'rxjs';
import {TrackingRestService} from './api/tracking-rest-service';
import {TrackRequestModel} from '../models/shop/track-request.model';
import * as fromReducers from '../store/reducers';
import {select, Store} from '@ngrx/store';
import {StartTrackAction} from '../store/actions/track.actions';


const dummy = {
  customerIdentification: {customerNo: 7812, branchNo: 5},
  tourInformation: {
    tour: [
      new TrackItem({
        delayInformation: 'Verspätung',
        delayInMinutes: 17,
        deliveredAt: null,
        expectedTimeOfArrivalMax: '2023-09-09T16:30:00',
        expectedTimeOfArrivalMin: '2023-09-09T16:17:00',
        plannedTimeOfArrival: '2023-09-09T18:50:00',
        tourId: '140042',
        center: 'Fürth',
        package: [
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 1,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '15733441',
                'articleName': 'Artikel 1',
                'isNarcotic': false
              }
            ],
            'packageId': '0',
            'packageType': 'Tote',
            'cscOrderNo': 495354,
            'deliveryAdviceNo': 212324,
            'trackingBarcode': null,
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          }
        ],
        order: 'order'
      }),
      new TrackItem({
        delayInformation: 'Verspätung',
        delayInMinutes: 47,
        deliveredAt: null,
        expectedTimeOfArrivalMax: '2023-09-09T15:05:00',
        expectedTimeOfArrivalMin: '2023-09-09T14:57:00',
        plannedTimeOfArrival: '2023-09-09T18:20:00',
        tourId: '140620',
        center: 'Fürth',
        package: [
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 2,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '6938629',
                'articleName': 'Artikel 2',
                'isNarcotic': false
              }
            ],
            'packageId': '0',
            'packageType': 'Tote',
            'cscOrderNo': 505948,
            'deliveryAdviceNo': 123456,
            'trackingBarcode': null,
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          },
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 3,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '6938629',
                'articleName': 'Artikel 3',
                'isNarcotic': false
              }
            ],
            'packageId': '0',
            'packageType': 'Tote',
            'cscOrderNo': 517234,
            'deliveryAdviceNo': 456789,
            'trackingBarcode': null,
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          },
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 1,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '18159474',
                'articleName': 'Artikel 4',
                'isNarcotic': false
              },
              {
                'orderPosNo': 2,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '4116099',
                'articleName': 'Artikel 5',
                'isNarcotic': false
              },
              {
                'orderPosNo': 3,
                'expectedDeliveryQuantity': 5,
                'confirmedDeliveryQuantity': null,
                'articleCode': '2129494',
                'articleName': 'Artikel 6',
                'isNarcotic': false
              },
              {
                'orderPosNo': 4,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '10780163',
                'articleName': 'Artikel 7',
                'isNarcotic': false
              },
              {
                'orderPosNo': 5,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '2662099',
                'articleName': 'Artikel 8',
                'isNarcotic': false
              },
              {
                'orderPosNo': 25,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '6979663',
                'articleName': 'Artikel 9',
                'isNarcotic': false
              }
            ],
            'packageId': '0',
            'packageType': 'Tote',
            'cscOrderNo': 543730,
            'deliveryAdviceNo': 987654,
            'trackingBarcode': null,
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          },
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 1,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '9282371',
                'articleName': 'Artikel 10',
                'isNarcotic': false
              },
              {
                'orderPosNo': 2,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '7239885',
                'articleName': 'Artikel 11',
                'isNarcotic': false
              }
            ],
            'packageId': '0',
            'packageType': 'Tote',
            'cscOrderNo': 557105,
            'deliveryAdviceNo': 654321,
            'trackingBarcode': null,
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          }
        ],
        order: 'order'
      }),
      new TrackItem({
        delayInformation: 'keine ETA-Daten verfügbar',
        delayInMinutes: null,
        deliveredAt: null,
        expectedTimeOfArrivalMax: null,
        expectedTimeOfArrivalMin: null,
        plannedTimeOfArrival: '2023-09-09T18:00:00',
        tourId: '140702',
        center: 'Fürth',
        package: [
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 1,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '9605443',
                'articleName': 'Artikel 12',
                'isNarcotic': true
              }
            ],
            'packageId': '4475113',
            'packageType': 'Narcotics',
            'cscOrderNo': 505948,
            'deliveryAdviceNo': 123456,
            'trackingBarcode': '44491862825044751136',
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          },
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 1,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '11585937',
                'articleName': 'Artikel 13',
                'isNarcotic': true
              },
              {
                'orderPosNo': 2,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '14167413',
                'articleName': 'Artikel 14',
                'isNarcotic': true
              }
            ],
            'packageId': '4475179',
            'packageType': 'Narcotics',
            'cscOrderNo': 517234,
            'deliveryAdviceNo': 456789,
            'trackingBarcode': '44491862825044751792',
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          }
        ],
        order: 'order'
      }),
      new TrackItem({
        delayInformation: 'keine Verspätung',
        delayInMinutes: 7,
        deliveredAt: null,
        expectedTimeOfArrivalMax: '2023-09-09T12:12:00',
        expectedTimeOfArrivalMin: '2023-09-09T12:00:00',
        plannedTimeOfArrival: '2023-09-09T12:00:00',
        tourId: '110502',
        center: 'Fürth',
        package: [
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 1,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '9605443',
                'articleName': 'Artikel 15',
                'isNarcotic': true
              }
            ],
            'packageId': '4475113',
            'packageType': 'Narcotics',
            'cscOrderNo': 505948,
            'deliveryAdviceNo': 123456,
            'trackingBarcode': '44491862825044751136',
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          },
          {
            'ibtInfo': null,
            'lineDetails': [
              {
                'orderPosNo': 1,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '11585937',
                'articleName': 'Artikel 16',
                'isNarcotic': true
              },
              {
                'orderPosNo': 2,
                'expectedDeliveryQuantity': 1,
                'confirmedDeliveryQuantity': null,
                'articleCode': '14167413',
                'articleName': 'Artikel 17',
                'isNarcotic': true
              }
            ],
            'packageId': '4475179',
            'packageType': 'Narcotics',
            'cscOrderNo': 517234,
            'deliveryAdviceNo': 456789,
            'trackingBarcode': '44491862825044751792',
            'toteContentChanged': null,
            'redirectedToTourId': null,
            'redirectedFromTourId': null,
            'toteDeletedAt': null
          }
        ],
        order: 'order'
      }),
    ]
  }

};

@Injectable({
  providedIn: 'root',
})
export class TrackTraceService implements OnDestroy {
  public trackItems: TrackItem[] = [];
  public trackItems$ = new Subject<TrackItem[]>();
  private ngUnsubscribe$ = new Subject<any>();
  private subject = new Subject<any>();


  constructor(public store: Store<fromReducers.State>,
              @Inject(TrackingRestService) public restService: TrackingRestService
  ) {
  }

  startTracking(branch, idf, date) {
    const request = new TrackRequestModel(branch, idf, date, true, null);
    this.store.dispatch(new StartTrackAction(request));
  }

  getTrackLoadingObservable(): Observable<boolean> {
    return this.store.pipe(select(fromReducers.getTrackLoading));
  }

  getTrackErrorObservable(): Observable<any> {
    return this.store.pipe(select(fromReducers.getTrackErrorResponse));
  }

  getTrackItems(): Observable<TrackItem[]> {
    return this.store.pipe(select(fromReducers.getTrackResponse));
  }

  sendDetails(item: TrackItem) {
    return this.subject.next(item);
  }

  onSendDetails() {
    return this.subject.asObservable();
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getDummyItems() {
    return dummy.tourInformation.tour;
  }
}
