<h1>
  Monatsfolder
</h1>
<p>
  Als optimale Ergänzung zu jeder Monatsaktion steht Ihnen zur Produktübersicht unser Monatsfolder in digitaler Form
  zur Verfügung.
</p>
<div class="row mt-s">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Monatsfolder%20Februar%202024.jpg" class="img-border"/>
    </a>
  </div>
  <div class="col-md align-items-center d-flex">
    <a target="_blank">Monatsfolder Februar 2024</a>&nbsp; (8,98 MB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-2">
    <a  target="_blank">
      <img src="../../assets/dummy/Monatsfolder_Januar_2024.jpg" class="img-border"/>
    </a>
  </div>
  <div class="col-md align-items-center d-flex">
    <a   target="_blank">Monatsfolder Januar 2024</a>&nbsp; (11,73 MB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>
