import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatevInfoComponent} from './datev-info/datev-info.component';
import {WichtigeInformationenComponent} from './wichtige-informationen/wichtige-informationen.component';
import {LivsaneComponent} from './livsane/livsane.component';
import {AkademieComponent} from './akademie/akademie.component';
import {PpcComponent} from './ppc/ppc.component';
import {OffizinImBlickComponent} from './offizin-im-blick/offizin-im-blick.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {ImpressumComponent} from './impressum/impressum.component';
import {NutzungsbedingungenPortalComponent} from './nutzungsbedingungen-portal/nutzungsbedingungen-portal.component';
import {DatenschutzComponent} from './datenschutz/datenschutz.component';
import { DigitalerAssistentComponent } from './digitaler-assistent/digitaler-assistent.component';


@NgModule({
  declarations: [
    DatevInfoComponent,
    WichtigeInformationenComponent,
    LivsaneComponent,
    AkademieComponent,
    PpcComponent,
    OffizinImBlickComponent,
    ImpressumComponent,
    NutzungsbedingungenPortalComponent,
    DatenschutzComponent,
    DigitalerAssistentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule
  ]
})
export class DummyModule {
}
