<h1>
  Aktionen
</h1>
<p>
  Wir unterstützen Sie nicht nur mit wertvollen Tipps & Tricks für Ihren gelungenen Arbeitsalltag sondern geben Ihnen mindestens zweimal jährlich mit unseren Aktionen eine zusätzliche Portion Humor und Spaß zur optimalen Work-Fun-Balance mit auf den Weg. Schauen Sie sich unsere letzten Aktionen an und freuen Sie sich auf spannende Teilnahmemöglichkeiten mit großartigen Gewinnspielen für Sie und das ganze Team!
</p>

<img src="../../assets/dummy/Aktionen.jpg" alt="PKP Aktionen">

<p class="mt-l">
  Sie nehmen noch nicht an unserem Beauftragtenprogramm teil, wollen die kommende Aktion aber auf keinen Fall verpassen? Dann melden Sie sich jetzt direkt an.
</p>

<a href="/#/page/anmeldung?heading=PHOENIX%20Kooperations-Partnerschaft" class="btn btn-cms">Anmelden</a>
