import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FluShopRestService} from './api/fluShop-rest-service';
import {PreOrderCategoryActive} from '../models/shop/pre-order-category-active.model';
import {PreOrderSupplierModel} from '../models/shop/pre-order-supplier.model';
import {PreOrderResponseModel} from '../models/shop/pre-order-response.model';
import {PreOrderArchivedResponseModel} from '../models/shop/pre-order-archived-response.model';
import {PreOrderArchivedItemResponseModel} from '../models/shop/pre-order-archived-item-response.model';

@Injectable({
  providedIn: 'root',
})
export class FluShopService implements OnDestroy {
  public activePreOrderCategories$ = new Subject<PreOrderCategoryActive[]>();
  public preOrderSuppliers$ = new Subject<PreOrderSupplierModel[]>();
  public preOrders$ = new Subject<PreOrderResponseModel>();
  public archivedPreOrders$ = new Subject<PreOrderArchivedResponseModel[]>();
  public archivedPreOrderItems$ = new Subject<PreOrderArchivedItemResponseModel[]>();
  private ngUnsubscribe$ = new Subject<any>();

  constructor(
      @Inject(FluShopRestService) public restService: FluShopRestService
  ) {
  }

  loadActivePreorderCategories() {
    this.restService
    .getActivePreorderCategories()
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((response) => {
      if (response.returnObject) {
        return this.activePreOrderCategories$.next(response.returnObject);
      }
      return this.activePreOrderCategories$;
    });
    return this.activePreOrderCategories$.asObservable();
  }

  loadPreorderSuppliersByUrl(categoryUrl: string) {
    return [
      new PreOrderSupplierModel({
        vendor: 'Grippehersteller 1',
        vendorImage: '',
        start: '2023-09-01T00:00:00',
        end: '2024-04-30T00:00:00',
        preOrderPeriodId: 1,
        showDates: false,
        products: [
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 100.76,
            priceTier1: 100.76,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230806',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 1',
            unit: '10 Dosen',
            comment: 'mit Kanüle',
            sorting: 1,
            quantity: 5,
            doctorsCount: 0,
            vorteil: 0
          },
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 100.76,
            priceTier1: 100.76,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230812',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 1',
            unit: '10 Dosen',
            comment: 'ohne Kanüle',
            sorting: 1,
            quantity: 0,
            doctorsCount: 0,
            vorteil: 0
          },
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 11.94,
            priceTier1: 11.94,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230798',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 2',
            unit: '1 Dosis',
            comment: 'mit Kanüle',
            sorting: 1,
            quantity: 7,
            doctorsCount: 0,
            vorteil: 0
          },
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 86.31,
            priceTier1: 86.31,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '17620971',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 3',
            unit: '10 Dosen',
            comment: 'ohne Kanüle',
            sorting: 1,
            quantity: 0,
            doctorsCount: 0,
            vorteil: 0
          },
        ]
      }),
      new PreOrderSupplierModel({
        vendor: 'Grippehersteller 2',
        vendorImage: '',
        start: '2023-09-01T00:00:00',
        end: '2024-04-30T00:00:00',
        preOrderPeriodId: 1,
        showDates: false,
        products: [
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 100.76,
            priceTier1: 100.76,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230806',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 1',
            unit: '10 Dosen',
            comment: 'mit Kanüle',
            sorting: 1,
            quantity: 0,
            doctorsCount: 0,
            vorteil: 0
          },
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 11.94,
            priceTier1: 11.94,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230798',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 2',
            unit: '1 Dosis',
            comment: 'mit Kanüle',
            sorting: 1,
            quantity: 0,
            doctorsCount: 0,
            vorteil: 0
          },
        ]
      }),
      new PreOrderSupplierModel({
        vendor: 'Grippehersteller 3',
        vendorImage: '',
        start: '2023-09-01T00:00:00',
        end: '2024-04-30T00:00:00',
        preOrderPeriodId: 1,
        showDates: false,
        products: [
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 100.76,
            priceTier1: 100.76,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230806',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 1',
            unit: '10 Dosen',
            comment: 'mit Kanüle',
            sorting: 1,
            quantity: 0,
            doctorsCount: 0,
            vorteil: 0
          },
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 11.94,
            priceTier1: 11.94,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230798',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 2',
            unit: '1 Dosis',
            comment: 'mit Kanüle',
            sorting: 1,
            quantity: 0,
            doctorsCount: 0,
            vorteil: 0
          },
        ]
      }),
      new PreOrderSupplierModel({
        vendor: 'Grippehersteller 4',
        vendorImage: '',
        start: '2023-09-01T00:00:00',
        end: '2024-04-30T00:00:00',
        preOrderPeriodId: 1,
        showDates: false,
        products: [
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 100.76,
            priceTier1: 100.76,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230806',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 1',
            unit: '10 Dosen',
            comment: 'mit Kanüle',
            sorting: 1,
            quantity: 0,
            doctorsCount: 0,
            vorteil: 0
          },
          {
            createdAt: '2023-08-29T16:00:00',
            type: 'NET',
            priceNormalAEP: 11.94,
            priceTier1: 11.94,
            priceTier2: null,
            priceTier3: null,
            amountTier1: 1,
            amountTier2: 0,
            amountTier3: 0,
            id: {
              pzn: '18230798',
              preOrderPeriodId: 1,
            },
            name: 'Grippeimpfstoff 2',
            unit: '1 Dosis',
            comment: 'mit Kanüle',
            sorting: 1,
            quantity: 0,
            doctorsCount: 0,
            vorteil: 0
          },
        ]
      })
    ];
  }

  loadPreordersForIdf(period: number, idf: string) {
    this.restService
    .getPreordersForIdf(period, idf)
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((response) => {
      if (response.returnObject) {
        return this.preOrders$.next(response.returnObject);
      }
      return this.preOrders$;
    });
    return this.preOrders$.asObservable();
  }

  updatePreorderRequest(period: number, idf: string, data: any) {
    return this.restService.saveOrdersForPeriod(period, idf, data);
  }

  loadArchivedPreOrders(idf: string) {
    this.restService
    .getArchivesForIdf(idf)
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((response) => {
      if (response.returnObject) {
        return this.archivedPreOrders$.next(response.returnObject);
      }
      return this.archivedPreOrders$;
    });
    return this.archivedPreOrders$.asObservable();
  }

  loadSingleArchivePreOrderItems(idf: any, id: any) {
    this.archivedPreOrderItems$ = new Subject<PreOrderArchivedItemResponseModel[]>();
    this.restService.getArchivePreOrderById(idf, id).subscribe((response) => {
      if (response.returnObject) {
        return this.archivedPreOrderItems$.next(response.returnObject);
      }
      return this.archivedPreOrderItems$;
    });
    return this.archivedPreOrderItems$.asObservable();
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
