import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Response} from '../../models/response.model';
import {SubsequentDeliveriesRequest} from '../../models/subsequent-deliveries/subsequent.deliveries.request';
import {SubsequentDeliveriesUpdate} from '../../models/subsequent-deliveries/subsequent.deliveries.update';


@Injectable({
    providedIn: 'root'
})
export class SubsequentDeliveriesRestService extends RestServiceAbstract implements OnDestroy {
    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public loadSubsequentDeliveries(subsequentDeliveriesRequest: SubsequentDeliveriesRequest) {
        return this.get('/resource/subsequent-delivery/' + subsequentDeliveriesRequest.getIdf()).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public updateSubsequentDelivery(subsequentDeliveryUpdate: SubsequentDeliveriesUpdate) {
        return this.put('/resource/subsequent-delivery/',  subsequentDeliveryUpdate).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        )
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
