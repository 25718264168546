import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jahreskalender',
  templateUrl: './jahreskalender.component.html',
  styleUrls: ['./jahreskalender.component.scss']
})
export class JahreskalenderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
