import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromReducers from '../core/store/reducers';
import {AuthService} from '../core/services/api/auth.service';
import {LoadUserAction} from '../core/store/actions/user.actions';
import {takeUntil} from 'rxjs/operators';
import {LogService} from '../core/services/utility/log.service';
import {AdsService} from '../core/services/ads.service';
import {Router} from '@angular/router';
import {UrlService} from '../core/services/url.service';

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, OnDestroy {
    public authenticated = false;
    public userLoading$: Observable<boolean>; // getUserLoading
    public count = 10;
    private ngUnsubscribe$ = new Subject<any>();
    public ads;
    public currentRoute;


    constructor(public store: Store<fromReducers.State>,
                @Inject(AuthService) public auth: AuthService,
                @Inject(LogService) public log: LogService,
                @Inject(AdsService) public adService: AdsService,
                @Inject(UrlService) public urlService: UrlService,
                public router: Router) {

        // dispatch action to load user into store via effect
        // const token = this.auth.getToken();
        // if (token) {
        //     this.store.dispatch(new LoadUserAction());
        //     this.userLoading$ = this.store.pipe(select(fromReducers.getUserLoading));
        //     this.userLoading$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((loading: boolean) => {
        //         if (loading === false) {
        //             // check user on every call, because he may have logged out on another system
        //             this.authenticated = this.auth.isAuthenticated();
        //             this.log.info('view.component:constructor:isAuthenticated: ' + this.authenticated);
        //
        //             if (!this.authenticated) {
        //                 // window.location.href = this.LOGIN;
        //             }
        //         }
        //     });
        // } else {
        //     this.urlService.lastUrl = "logout";
        //     this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
        // }
    }

    ngOnInit() {
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

}
