export class ReklamationsService {

  getData() {
    return {
      'recent': [
        {
          'division': 'Retoure',
          'complaint': 'string',
          'createdAt': '2024-02-16T15:08:10.913Z',
          'estimateInMinutes': 0,
          'status': 'COMPLETED',
          'completedAt': '2024-02-16T10:24:10.913Z',
          'closedHours': true
        },
        {
          'division': 'Lager',
          'complaint': 'string',
          'createdAt': '2024-02-16T12:45:10.913Z',
          'estimateInMinutes': 60,
          'status': 'ASSIGNED',
          'completedAt': '',
          'closedHours': false
        },
        {
          'division': 'BTM',
          'complaint': 'string',
          'createdAt': '2024-02-16T15:08:10.913Z',
          'estimateInMinutes': 0,
          'status': 'CREATED',
          'completedAt': '',
          'closedHours': false
        }
      ],
      'archive': [
        {
          'division': 'Lager',
          'complaint': 'Lagerplatz prüfen',
          'createdAt': '2024-02-14T13:30:10.914Z',
          'estimateInMinutes': 0,
          'status': 'COMPLETED',
          'completedAt': '2024-02-14T13:30:10.914Z',
          'closedHours': true
        }
      ]
    };
  }

}
