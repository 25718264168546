import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import * as fromSpecialOffers from './special-offers.reducer';
import * as fromTopPrisma from './top-prisma.reducer';
import * as fromDokuLight from './doku-light.reducer';
import * as fromExtraPrisma from './extra-prisma.reducer';
import * as fromProFax from './pro-fax.reducer';
import * as fromPromo from './promo.reducer';
import * as fromUber from './uber.reducer';
import * as fromParagraph73 from './paragraph73.reducer';
import * as fromUser from './user.reducer';
import * as fromInvoices from './invoices.reducer';
import * as fromCart from './cart.reducer';
import * as fromSubUser from './sub-user.reducer';
import * as fromExclusiveOffers from './exclusive-offers.reducer';
import * as fromMessage from './message.reducer';
import * as fromSubsequentDeliveries from './subsequent-deliveries.reducer';
import * as fromTrackAndTrace from './track.reducer';
import * as fromRetouren from './retouren.reducer';
import * as fromDialogReducer from './dialog.reducer';

export interface State {
    user: fromUser.State;
    invoices: fromInvoices.State;
    cart: fromCart.State;
    pin: fromSpecialOffers.State;
    exclusiveOffers: fromExclusiveOffers.State;
    topPrisma: fromTopPrisma.State;
    dokuLight: fromDokuLight.State;
    extraPrisma: fromExtraPrisma.State;
    proFax: fromProFax.State;
    promo: fromPromo.State;
    uber: fromUber.State;
    proFaxForCart: fromProFax.State;
    promoForCart: fromPromo.State;
    paragraph73: fromParagraph73.State;
    subUser: fromSubUser.State;
    message: fromMessage.State;
    subsequentDeliveries: fromSubsequentDeliveries.State;
    track: fromTrackAndTrace.State;
    retouren: fromRetouren.State;
    dialog: fromDialogReducer.State;
}

export const reducers: ActionReducerMap<State> = {
    user: fromUser.reducer,
    invoices: fromInvoices.reducer,
    cart: fromCart.reducer,
    pin: fromSpecialOffers.reducer,
    exclusiveOffers: fromExclusiveOffers.reducer,
    topPrisma: fromTopPrisma.reducer,
    dokuLight: fromDokuLight.reducer,
    extraPrisma: fromExtraPrisma.reducer,
    proFax: fromProFax.reducer,
    promo: fromPromo.reducer,
    uber: fromUber.reducer,
    proFaxForCart: fromProFax.reducerForCartActions,
    promoForCart: fromPromo.reducerCart,
    paragraph73: fromParagraph73.reducer,
    subUser: fromSubUser.reducer,
    message: fromMessage.reducer,
    subsequentDeliveries: fromSubsequentDeliveries.reducer,
    track: fromTrackAndTrace.reducer,
    retouren: fromRetouren.reducer,
    dialog: fromDialogReducer.reducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function (state: State, action: any): State {
        console.log('state', state);
        console.log('action', action);

        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
// TODO: Check environment
export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger]
    : [];


// pin sonderangebote special offers selectors
export const getExclusiveOffersState = createFeatureSelector<fromExclusiveOffers.State>('exclusiveOffers');
export const getExclusiveOffersResponse = createSelector(getExclusiveOffersState, fromExclusiveOffers.getExclusiveOffersResponse);
export const getExclusiveOffersRequest = createSelector(getExclusiveOffersState, fromExclusiveOffers.getExclusiveOffersRequest);
export const getExclusiveOffersErrorResponse = createSelector(getExclusiveOffersState, fromExclusiveOffers.getExclusiveOffersErrorResponse);
export const getExclusiveOffersLoading = createSelector(getExclusiveOffersState, fromExclusiveOffers.getExclusiveOffersLoading);
export const getExclusiveOffersProductCache = createSelector(getExclusiveOffersState, fromExclusiveOffers.getExclusiveOffersProductCache);
export const getExclusiveOffersProducts = createSelector(getExclusiveOffersState, fromExclusiveOffers.getExclusiveOffersProducts);
export const getExclusiveOffersScrollPosition = createSelector(getExclusiveOffersState,
    fromExclusiveOffers.getExclusiveOffersScrollPosition);

// pin sonderangebote special offers selectors
export const getSpecialOffersState = createFeatureSelector<fromSpecialOffers.State>('pin');
export const getSpecialOffersResponse = createSelector(getSpecialOffersState, fromSpecialOffers.getSpecialOffersResponse);
export const getSpecialOffersRequest = createSelector(getSpecialOffersState, fromSpecialOffers.getSpecialOffersRequest);
export const getSpecialOffersErrorResponse = createSelector(getSpecialOffersState, fromSpecialOffers.getSpecialOffersErrorResponse);
export const getSpecialOffersLoading = createSelector(getSpecialOffersState, fromSpecialOffers.getSpecialOffersLoading);
export const getSpecialOffersProductCache = createSelector(getSpecialOffersState, fromSpecialOffers.getSpecialOffersProductCache);
export const getSpecialOffersProducts = createSelector(getSpecialOffersState, fromSpecialOffers.getSpecialOffersProducts);
export const getSpecialOffersScrollPosition = createSelector(getSpecialOffersState, fromSpecialOffers.getSpecialOffersScrollPosition);

// pin sonderangebote special offers selectors
export const getProFaxState = createFeatureSelector<fromProFax.State>('proFax');
export const getProFaxResponse = createSelector(getProFaxState, fromProFax.getProFaxResponse);
export const getProFaxRequest = createSelector(getProFaxState, fromProFax.getProFaxRequest);
export const getProFaxErrorResponse = createSelector(getProFaxState, fromProFax.getProFaxErrorResponse);
export const getProFaxLoading = createSelector(getProFaxState, fromProFax.getProFaxLoading);
export const getProFaxProductCache = createSelector(getProFaxState, fromProFax.getProFaxProductCache);
export const getProFaxProducts = createSelector(getProFaxState, fromProFax.getProFaxProducts);
export const getProFaxScrollPosition = createSelector(getProFaxState, fromProFax.getProFaxScrollPosition);
export const getProFaxCart = createSelector(getProFaxState, fromProFax.getProFaxCart);

// promo offers selectors
export const getPromoState = createFeatureSelector<fromPromo.State>('promo');
export const getPromoResponse = createSelector(getPromoState, fromPromo.getPromoResponse);
export const getPromoRequest = createSelector(getPromoState, fromPromo.getPromoRequest);
export const getPromoProducts = createSelector(getPromoState, fromPromo.getPromoProducts);
export const getPromoProductCache = createSelector(getPromoState, fromPromo.getPromoProductCache);
export const getPromoLoading = createSelector(getPromoState, fromPromo.getPromoLoading);
export const getPromoScrollPosition = createSelector(getPromoState, fromPromo.getPromoScrollPosition);
export const getPromoErrorResponse = createSelector(getPromoState,fromPromo.getPromoErrorResponse);
export const getPromoCart = createSelector(getPromoState, fromPromo.getPromoCart);

// extraprisma extra prisma selectors
export const getExtraPrismaState = createFeatureSelector<fromExtraPrisma.State>('extraPrisma');
export const getExtraPrismaResponse = createSelector(getExtraPrismaState, fromExtraPrisma.getExtraPrismaResponse);
export const getExtraPrismaRequest = createSelector(getExtraPrismaState, fromExtraPrisma.getExtraPrismaRequest);
export const getExtraPrismaErrorResponse = createSelector(getExtraPrismaState, fromExtraPrisma.getExtraPrismaErrorResponse);
export const getExtraPrismaLoading = createSelector(getExtraPrismaState, fromExtraPrisma.getExtraPrismaLoading);
export const getExtraPrismaProductCache = createSelector(getExtraPrismaState, fromExtraPrisma.getExtraPrismaProductCache);
export const getExtraPrismaProducts = createSelector(getExtraPrismaState, fromExtraPrisma.getExtraPrismaProducts);
export const getExtraPrismaScrollPosition = createSelector(getExtraPrismaState, fromExtraPrisma.getExtraPrismaScrollPosition);

// topprisma top prisma selectors
export const getTopPrismaState = createFeatureSelector<fromTopPrisma.State>('topPrisma');
export const getTopPrismaResponse = createSelector(getTopPrismaState, fromTopPrisma.getTopPrismaResponse);
export const getTopPrismaRequest = createSelector(getTopPrismaState, fromTopPrisma.getTopPrismaRequest);
export const getTopPrismaErrorResponse = createSelector(getTopPrismaState, fromTopPrisma.getTopPrismaErrorResponse);
export const getTopPrismaLoading = createSelector(getTopPrismaState, fromTopPrisma.getTopPrismaLoading);
export const getTopPrismaProductCache = createSelector(getTopPrismaState, fromTopPrisma.getTopPrismaProductCache);
export const getTopPrismaProducts = createSelector(getTopPrismaState, fromTopPrisma.getTopPrismaProducts);
export const getTopPrismaScrollPosition = createSelector(getTopPrismaState, fromTopPrisma.getTopPrismaScrollPosition);

// dokuLight selectors
export const getDokuLightState = createFeatureSelector<fromDokuLight.State>('dokuLight');
export const getDokuLightResponse = createSelector(getDokuLightState, fromDokuLight.getDokuLightResponse);
export const getDokuLightRequest = createSelector(getDokuLightState, fromDokuLight.getDokuLightRequest);
export const getDokuLightErrorResponse = createSelector(getDokuLightState, fromDokuLight.getDokuLightErrorResponse);
export const getDokuLightLoading = createSelector(getDokuLightState, fromDokuLight.getDokuLightLoading);
export const getDokuLightProductCache = createSelector(getDokuLightState, fromDokuLight.getDokuLightProductCache);
export const getDokuLightProducts = createSelector(getDokuLightState, fromDokuLight.getDokuLightProducts);
export const getDokuLightScrollPosition = createSelector(getDokuLightState, fromDokuLight.getDokuLightScrollPosition);

// paragraph73 selectors
export const getParagraph73State = createFeatureSelector<fromParagraph73.State>('paragraph73');
export const getParagraph73Response = createSelector(getParagraph73State, fromParagraph73.getParagraph73Response);
export const getParagraph73Request = createSelector(getParagraph73State, fromParagraph73.getParagraph73Request);
export const getParagraph73ErrorResponse = createSelector(getParagraph73State, fromParagraph73.getParagraph73ErrorResponse);
export const getParagraph73Loading = createSelector(getParagraph73State, fromParagraph73.getParagraph73Loading);
export const getParagraph73ProductCache = createSelector(getParagraph73State, fromParagraph73.getParagraph73ProductCache);
export const getParagraph73Products = createSelector(getParagraph73State, fromParagraph73.getParagraph73Products);
export const getParagraph73ScrollPosition = createSelector(getParagraph73State, fromParagraph73.getParagraph73ScrollPosition);

// user selectors
export const getUserState = createFeatureSelector<fromUser.State>('user');
export const getUserResponse = createSelector(getUserState, fromUser.getUserResponse);
export const getUserErrorResponse = createSelector(getUserState, fromUser.getUserErrorResponse);
export const getUserLoading = createSelector(getUserState, fromUser.getUserLoading);
export const getNav = createSelector(getUserState, fromUser.getNav);
export const getNavLoading = createSelector(getUserState, fromUser.getNavLoading);
export const getUserIDFs = createSelector(getUserState, fromUser.getUserIDFs);
export const getContextualizedIDFs =
    createSelector(getUserIDFs, (idfs: any, context: string) => idfs && idfs[context]);
export const getUserIDFsLoading = createSelector(getUserState, fromUser.getUserIDFsLoading);
export const getUserMainIdf = createSelector(getUserState, fromUser.getUserMainIdf);
export const getUserMainIdfLoading = createSelector(getUserState, fromUser.getUserMainIdfLoading);
export const getUserVZs = createSelector(getUserState, fromUser.getUserVZs);
export const getUserVZsLoading = createSelector(getUserState, fromUser.getUserVZsLoading);
export const getUserShippingAdresses = createSelector(getUserState, fromUser.getUserShippingAdresses);
export const getUserShippingAdressesLoading = createSelector(getUserState, fromUser.getUserShippingAdressesLoading);
export const getMainDistributionCentersAdress = createSelector(getUserState, fromUser.getMainDistributionCentersAdress);
export const getMainDistributionCenterAdressLoading = createSelector(getUserState, fromUser.getMainDistributionCentersAdressLoading);
export const getAllDistributionCentersAdress = createSelector(getUserState, fromUser.getAllDistributionCentersAdress);
export const getAllDistributionCenterAdressLoading = createSelector(getUserState, fromUser.getAllDistributionCentersAdressLoading);

// auth token selectors
export const getAuthToken = createSelector(getUserState, fromUser.getAuthToken);
export const getAuthTokenLoading = createSelector(getUserState, fromUser.getAuthTokenLoading);
export const getAuthTokens = createSelector(getUserState, fromUser.getAuthTokens);
export const getAuthTokensLoading = createSelector(getUserState, fromUser.getAuthTokensLoading);

// invoices selectors
export const getInvoicesState = createFeatureSelector<fromInvoices.State>('invoices');
export const getInvoicesRequest = createSelector(getInvoicesState, fromInvoices.getInvoicesRequest);
export const getInvoicesResponse = createSelector(getInvoicesState, fromInvoices.getInvoicesResponse);
export const getInvoicesByBranchId = (id: number) =>
    createSelector(getInvoicesResponse, (invoicesResponse) => invoicesResponse?.results[id] ? invoicesResponse.results[id] : null);
export const getInvoicesSearching = createSelector(getInvoicesState, fromInvoices.getInvoicesSearching);
export const getDocTypesLoading = createSelector(getInvoicesState, fromInvoices.getDocTypesLoading);
export const getDocTypes = createSelector(getInvoicesState, fromInvoices.getDocTypes);
export const getAllDocTypes = createSelector(getInvoicesState, fromInvoices.getAllDocTypes);
export const getAllDocTypesLoading = createSelector(getInvoicesState, fromInvoices.getAllDocTypesLoading);
// export const getDocTypesRequest = createSelector(getInvoicesState, fromInvoices.getDocTypesRequest);
export const getContactFormVisibility = createSelector(getInvoicesState, fromInvoices.getContactFormVisibility);
export const getSelectedBranchesForInvoiceSearch = createSelector(getInvoicesState, fromInvoices.getSelectedBranchesForInvoiceRequest);

// cart selectors
export const getCartState = createFeatureSelector<fromCart.State>('cart');
export const getCartItems = createSelector(getCartState, fromCart.getCartItems);
export const getTotals = createSelector(getCartState, fromCart.getTotals);
export const getProductTypes = createSelector(getCartState, fromCart.getProductTypes);
export const getLatestCartItems = createSelector(getCartState, fromCart.getLatestCartItems);

// cart selectors
export const getSubUserState = createFeatureSelector<fromSubUser.State>('subUser');
export const getSubUsers = createSelector(getSubUserState, fromSubUser.getSubUsers);
export const getSubUsersLoading = createSelector(getSubUserState, fromSubUser.getSubUsersLoading);
export const getSubUsersError = createSelector(getSubUserState, fromSubUser.getSubUsersError);
export const getInitAuthTree = createSelector(getSubUserState, fromSubUser.getInitAuthTree);
export const getAuthTreeLoading = createSelector(getSubUserState, fromSubUser.getAuthTreeLoading);

// message selectors
export const getMessagesState = createFeatureSelector<fromMessage.State>('message');
export const getMessagesRequest = createSelector(getMessagesState, fromMessage.getMessagesRequest);
export const getMessages = createSelector(getMessagesState, fromMessage.getMessagesResponse);
export const getMessagesError = createSelector(getMessagesState, fromMessage.getMessagesErrorResponse);
export const getMessagesLoading = createSelector(getMessagesState, fromMessage.getMessagesLoading);
export const getMessageLoading = createSelector(getMessagesState, fromMessage.getMessageLoading);

//subsequent deliveries selectors
export const getDeliveriesState = createFeatureSelector<fromSubsequentDeliveries.State>('subsequentDeliveries');
export const getSubsequentDeliveries = createSelector(getDeliveriesState, fromSubsequentDeliveries.getSubsequentDeliveriesResponse);
export const getSubsequentDeliveriesLoading = createSelector(getDeliveriesState, fromSubsequentDeliveries.getSubsequentDeliveriesLoading);
export const getSubsequentDeliveriesRequest = createSelector(getDeliveriesState, fromSubsequentDeliveries.getSubsequentDeliveriesRequest);
export const getSubsequentDeliveriesUpdate = createSelector(getDeliveriesState, fromSubsequentDeliveries.getSubsequentDeliveriesUpdateResponse);
export const getSubsequentDeliveriesDeleteResponse = createSelector(getDeliveriesState, fromSubsequentDeliveries.getSubsequentDeliveriesDeleteResponse);
export const getSubsequentDeliveriesError = createSelector(getDeliveriesState, fromSubsequentDeliveries.getSubsequentDeliveriesErrorResponse);

// Uberweiser selectors
export const getUberState = createFeatureSelector<fromUber.State>('uber');
export const getUberLoading = createSelector(getUberState, fromUber.getUberLoading);
export const getUberRequest = createSelector(getUberState, fromUber.getUberRequest);
export const getUberResponse = createSelector(getUberState, fromUber.getUberResponse);
export const getUberErrorResponse = createSelector(getUberState, fromUber.getUberErrorResponse);
export const getUberProducts = createSelector(getUberState, fromUber.getUberProducts);
export const getUberProductCache = createSelector(getUberState, fromUber.getUberProductCache);
export const getUberCart = createSelector(getUberState, fromUber.getUberCart);
export const getUberScrollPosition = createSelector(getUberState, fromUber.getUberScrollPosition);
export const getUberVendorsLoading = createSelector(getUberState, fromUber.getUberVendorsLoading);
export const getUberVendorsErrorResponse = createSelector(getUberState, fromUber.getUberVendorsErrorResponse);
export const getUberVendors = createSelector(getUberState, fromUber.getUberVendors);

// Track and Trace selectors
export const getTrackState = createFeatureSelector<fromTrackAndTrace.State>('track');
export const getTrackLoading = createSelector(getTrackState, fromTrackAndTrace.getTrackLoading);
export const getTrackResponse = createSelector(getTrackState, fromTrackAndTrace.getTrackResponse);
export const getTrackErrorResponse = createSelector(getTrackState, fromTrackAndTrace.getTrackErrorResponse);

// Retouren selectors
export const getRetourenState = createFeatureSelector<fromRetouren.State>('retouren');
export const getRetourenLoading = createSelector(getRetourenState, fromRetouren.getRetourenLoading);
export const getRetourenResponse = createSelector(getRetourenState, fromRetouren.getRetourenResponse);
export const getRetourenError = createSelector(getRetourenState, fromRetouren.getRetourenError);
export const getRetourenAnnouncementLoading = createSelector(getRetourenState, fromRetouren.getRetourenAnnouncementLoading);
export const getRetourenAnnouncementResponse = createSelector(getRetourenState, fromRetouren.getRetourenAnnouncementResponse);
export const getRetourenAnnouncementError = createSelector(getRetourenState, fromRetouren.getRetourenAnnouncementError);


// Dialog selectors
export const getDialogState = createFeatureSelector<fromDialogReducer.State>('dialog');
export const getDialogLoading = createSelector(getDialogState, fromDialogReducer.getDialogLoading);
export const getDialogs = createSelector(getDialogState, fromDialogReducer.getDialogs);
export const getDialogError = createSelector(getDialogState, fromDialogReducer.getDialogError);