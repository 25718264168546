<div class="nav-top">
  <div class="d-inline-block position-relative" *ngIf="canOrders()">
    <a [routerLink]="['/warenkorb']" routerLinkActive="navLinkActive" routerLinkActiveOptions="{exact:true}">
      <i class="fal fa-shopping-cart"
         matBadge="{{(totals$ | async).quantityTotal}}"
         matBadgeSize="medium"
         [matBadgeHidden]="(totals$ | async).quantityTotal == 0" attr.data-cy="quick-nav-go-to-cart"
         matTooltip="Zum Warenkorb">
      </i>
    </a>
    <add-to-cart-notifier [cartItems]="(cartItems$ | async)" [latestItems]="(latestItems$ | async)"></add-to-cart-notifier>
  </div>
  <a [routerLink]="['/postfach']" *ngIf="(mails$ | async) && canMail()" routerLinkActive="navLinkActive"
     routerLinkActiveOptions="{exact:true}" >
    <i class="fal fa-envelope"
       matBadge="{{(mails$ | async).unread}}"
       matBadgeSize="medium"
       [matBadgeHidden]="(mails$ | async).unread == 0" attr.data-cy="quick-nav-message-box"
       matTooltip="Postfach"></i>
  </a>
  <a [routerLink]="['/fragenundantworten']"
     routerLinkActive="navLinkActive"
     [routerLinkActiveOptions]="{exact:true}"
     class="helpNav">
    <i class="fal fa-question-circle" matTooltip="Hilfecenter"></i>
  </a>
  <a data-cy="quick-nav-logout"><i class="fal fa-unlock-alt" matTooltip="Logout"></i></a>
</div>