<div class="addToCartOverlay" *ngIf="this.show" (click)="show = false"></div>
<div id="add-to-cart-notifier" [class.added]="show">
  <h2>Warenkorb</h2>
    <p [innerHTML]="names + ' wurde' + plural + 'in Ihren Warenkorb gelegt.' | safeHtml"></p>
  <div class="row">
      <div class="col-md-6">
      {{quantity}} Einheiten
      </div>
      <div class="col-md-6 text-right">
        {{ price | currency:'EUR':'symbol':'1.2-2'}}
      </div>
  </div>
  <div class="row corporate-border-top phx-corporate">
      <div class="col-md-6">
      <b>Gesamtsumme</b>
      </div>
      <div class="col-md-6 text-right">
        <b>{{ totals?.total | currency:'EUR':'symbol':'1.2-2'}}</b>
      </div>
  </div>
  <p>
    Es befinden sich {{ totals?.quantityTotal }} Artikel in ihrem Warenkorb.
  </p>
  <a >
    <button class="btn-primary w-100" type="button" data-cy="notifier-go-to-cart">
      Zum Warenkorb
    </button>
  </a>
  <a >
    <button class="btn-secondary w-100" type="button">
      Zur Kasse
    </button>
  </a>
</div>
