<div id="content">
    <notification *ngIf="alert === 'accountSuccess'" type="info" message="Ihr Änderungswunsch wurde erfolgreich übermittelt. Ihre Apothekenstammdaten werden nach erfolgter Prüfung i. d. R. innerhalb von 2 Werktagen aktualisiert."></notification>
    <notification *ngIf="alert === 'passwordSuccess'" type="success" message="Ihr Passwort wurde erfolgreich geändert."></notification>
    <notification *ngIf="alert === 'failure'" type="error" message="{{errorMessage | translate}}"></notification>
    <div class="row">
        <div class="col-md-6">
            <div class="panel">
                <h2>Meine Daten</h2>
                <div class="data">
                    <div class="row border-bottom dataset">
                        <div class="col-md-11">
                            <strong>Name</strong><br/>
                            {{accountData.owner.salutation ? accountData.owner.salutation + ' ' : ''}}
                            {{accountData.owner.title ? accountData.owner.title + ' ' : ''}}
                            {{accountData.owner.firstName ? accountData.owner.firstName + ' ' : ''}}
                            {{accountData.owner.lastName ? accountData.owner.lastName : ''}}
                        </div>
                        <div class="col-md-1 aligned">
                            <span class="alignment-helper"></span>
                            <a data-cy="open-owner-update-dialog" ><i class="fal fa-pencil"></i></a>
                        </div>
                    </div>
                    <div class="row border-bottom dataset">
                        <div class="col-md-12">
                            <strong>Benutzername</strong><br/>
                            {{(user)?.username}}
                        </div>
                    </div>
                    <div class="row border-bottom dataset">
                        <div class="col-md-11">
                            <div>
                                <strong>Passwort</strong><br/>
                                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                            </div>
                        </div>
                        <div class="col-md-1 aligned">
                            <span class="alignment-helper"></span>
                            <a data-cy="open-password-update-dialog" ><i class="fal fa-pencil"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="panel" *ngIf="accountData && accountData.accounts && accountData.accounts.length">
                <h2>Meine Adressen</h2>
                <div class="data">
                    <div class="row dataset border-bottom" *ngFor="let a of accountData.accounts">
                        <div class="col-md-6">
                            <span class="text-muted">IDF-Nummer {{a.idf}}</span><br/>
                            <span *ngIf="a.name">{{a.name}}</span><br/>
                            <span *ngIf="accountData.owner && accountData.owner.salutation">{{accountData.owner.salutation}} </span>
                            <span *ngIf="accountData.owner && accountData.owner.title">{{accountData.owner.title}}</span><br/>
                            <span *ngIf="accountData.owner && accountData.owner.firstName">{{accountData.owner.firstName}} </span>
                            <span *ngIf="accountData.owner && accountData.owner.lastName">{{accountData.owner.lastName}}</span>
                        </div>
                        <div class="col-md-5">
                            <span *ngIf="a.street">{{a.street}}</span><br/>
                            <span *ngIf="a.zipCode">{{a.zipCode}}</span>&nbsp;<span *ngIf="a.city">{{a.city}}</span><br/>
                            <span class="dataset-email" *ngIf="a.email">{{a.email}}</span><br/>
                            <span *ngIf="a.phone">Telefon {{a.phone}}</span><br/>
                            <span *ngIf="a.fax">Telefax {{a.fax}}</span>
                        </div>
                        <div class="col-md-1 aligned">
                            <span class="alignment-helper"></span>
                            <a attr.data-cy="open-account-update-dialog-{{a.idf}}" ><i class="fal fa-pencil"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
