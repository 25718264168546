<div id="content" class="mb-0">
  <img
      src="../../assets/dummy/csm_headerimage_dA_akademie.jpg"
      alt="Fortbildungsangebote für jeden Bedarf">
  <div class="panel-without-border mb-0">
    <h1>Fortbildungsangebote für jeden Bedarf</h1>
    <p>
      Die AKADEMIE unterstützt Apothekenteams dabei, Qualifikationen aktuell zu halten und zu erweitern. Wählen Sie aus unserem breiten Spektrum das Passende für Sie aus: Seminare vor Ort, Inhouse-Schulungen in Ihrer Apotheke oder digitale Fortbildungen wie Webinare, Lernvideos und E-Learnings.
    </p>
    <h2 class="mt-l">Wir bieten Ihnen Fortbildungen zu folgenden Themengebieten an:</h2>
    <ul>
      <li>Management und Apothekenpraxis</li>
      <li>Häusliche Pflege und Chroniker</li>
      <li>Sanfte Alternativen</li>
      <li>Beratung für die Selbstmedikation</li>
      <li>Erleben & Genießen: Genussevents</li>
    </ul>
    <p>
      Online bei der AKADEMIE finden Sie alle Angebote. Hier können Sie Seminare, Webinare und E-Learnings buchen und nutzen, haben einen Überblick über bereits absolvierte und noch bevorstehende Schulungen und können sich nach erfolgreichem Abschluss jederzeit Ihr persönliches Zertifikat herunterladen. Registrieren Sie sich einmalig kostenfrei, um in den Genuss aller Vorteile zu kommen!
    </p>
    <h2 class="mt-l">Ihre Vorteile im Überblick: </h2>
    <ul>
      <li>Erfahrene Referenten mit fachlichem Know-how</li>
      <li>Praxisbezogene Schulungsinhalte</li>
      <li>Fortbildungspunkte</li>
      <li>Jederzeit auf aktuellem Stand bei Zusatzterminen</li>
      <li>Übersicht über Ihre gebuchten Kurse</li>
      <li>Herunterladen der Zertifikate und Schulungsunterlagen zu jeder Zeit möglich</li>
      <li>Gezieltes Suchen nach Schulungen über die Filterfunktion</li>
      <li>Zeitersparnis durch einmalige kostenfreie Registrierung</li>
      <li>Einfache Kursbuchung mit nur einem Klick</li>
      <li>Orts- und zeitunabhängiges Lernen durch Zugriff auf digitale Fortbildungsangebote</li>
      <li>Zugang zu kostenfreien Lernangeboten</li>
    </ul>
  </div>
</div>
<section class="green-square">
  <h2>Hier gelangen Sie zur AKADEMIE</h2>
  <a>
    <button class="btn-primary on-dark-bg mr-l">
      Akademie
    </button>
  </a>
</section>
