import {Injectable} from '@angular/core';

@Injectable()
export class ScrollingService {

    public scrollToYPositionOfVisibleViewport(yPosition: any) {
        if (document.getElementById('visible-view') !== null) {
            if (this.testSupportsSmoothScroll()) {
                document.getElementById('visible-view').scrollTo({
                    top: yPosition,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        } else {
            scrollTo(yPosition);
        }
    }

    public determineScrollPositionOfVisibleViewport(): number {
        const pos = -document.getElementById('main-content').getBoundingClientRect().top;
        return pos;
    }

    testSupportsSmoothScroll() {
        let supports = false;
        try {
            const div = document.createElement('div');
            div.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            supports = true;
        } catch (err) {
        }
        return supports;
    }

    scrollTo(pos = 0) {
        window.scrollTo(pos, 0);
    }
}
