export interface SupplierProduct {
    createdAt: string;
    type:  'NET' | 'NR' | 'UNKNOWN';
    priceNormalAEP: number;
    priceTier1: number;
    priceTier2: number;
    priceTier3: number;
    amountTier1: number;
    amountTier2: number;
    amountTier3: number;
    id: {
        pzn: string;
        preOrderPeriodId: number;
    };
    name: string;
    unit: string;
    comment: string;
    sorting: number;
    quantity?: number;
    doctorsCount?: number;
    vorteil?: number;
}

export class PreOrderSupplierModel {
    vendor: string;
    vendorImage: string;
    start: string;
    end: string;
    preOrderPeriodId: number;
    products: SupplierProduct[];

    constructor(obj: any = {}) {
        this.vendor = obj.vendor;
        this.vendorImage = obj.vendorImage;
        this.start = obj.start;
        this.end = obj.end;
        this.preOrderPeriodId = obj.preOrderPeriodId;
        this.products = obj.products;
    }
}
