<h1>Jahreskalender Sonderaktionen</h1>
<p>
  Mit dem Jahreskalender erfahren Sie schon heute welche Produkte Ihnen über das Jahr hinweg zugeteilt werden. So haben
  Sie im Rahmen Ihrer Kooperation einen Überblick aller Vorteile rund um die Bevorratungsware und die entsprechenden
  Konditionen und können diese bestmöglich positionieren. </p>
<div class="row mt-s">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Jahresuebersicht_LINDA_Monatsaktion_2023.jpg"
           class="img-border">
    </a>
  </div>
  <div class="col-md align-items-center d-flex"><a
      target="_blank">Jahresübersicht LINDA Sonderaktionen 2024</a>&nbsp; (89 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>
<h1 class="mt-xl">
  PKP-Sonderaktionen
</h1>
<p>
  Nachfolgend stellen wir Ihnen die Anschreiben zu Sonderaktionen digital gerne zur Verfügung. </p>
<div class="row mt-s">
  <div class="col-md-7 align-items-center d-flex"><a
      target="_blank">Sonderaktion "Heuschnupfenmittel"</a>&nbsp; (57 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-7 align-items-center d-flex"><a
      target="_blank">Sonderaktion "Prospan Hustenliquid"</a>&nbsp; (53 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank"><span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-7 align-items-center d-flex"><a
      target="_blank">Sonderaktion "Elmex gelee"</a>&nbsp; (48 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
