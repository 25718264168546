<h1>
  Newsletter
</h1>
<p>
  Lesen Sie hier die letzten drei Newsletter noch einmal in aller Ruhe nach und holen Sie sich Ihre spannenden Tipps & Tricks immer dann, wenn Sie diese brauchen.
</p>
<div class="row mt-l">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/newsletter_2.jpg" class="img-border" />
    </a>
  </div>
  <div class="col-md-7 align-items-center d-flex">
    <a target="_blank">Newsletter Beauftragtenprogramm Webinaraufzeichnung, Juli 2023</a>&nbsp;(231 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank"><span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
<div class="row mt-l">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Newsletter_Beauftragtenprogramm_Schulung.jpg" class="img-border" />
    </a>
  </div>
  <div class="col-md-7 align-items-center d-flex">
    <a target="_blank">Newsletter Beauftragtenprogramm Schulung, Mai 2023</a>&nbsp;(456 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank"><span class="arrow-helper far fa-arrow-down"></span></a>
  </div>
</div>
