import {HttpErrorResponse} from '@angular/common/http';
import {SubsequentDeliveriesActions, SubsequentDeliveriesActionsType} from '../actions/subsequent-deliveries.actions';
import {SubsequentDeliveryModel} from '../../models/subsequent-deliveries/subsequent.delivery.model';
import {SubsequentDeliveriesRequest} from '../../models/subsequent-deliveries/subsequent.deliveries.request';
import * as fromPromo from './promo.reducer';
import {getPromoState} from './index';
import {SubsequentDeliveriesResponse} from '../../models/subsequent-deliveries/subsequent.deliveries.response';

export interface State {
    subsequentDeliveriesResponse: any;
    subsequentDeliveriesRequest: any;
    subsequentDeliveriesUpdateResponse: SubsequentDeliveryModel;
    subsequentDeliveriesDeleteResponse: any;
    subsequentDeliveriesErrorResponse: HttpErrorResponse;
    loading: boolean;
}

export const initState: State = {
    subsequentDeliveriesResponse: null,
    subsequentDeliveriesRequest: null,
    subsequentDeliveriesUpdateResponse: null,
    subsequentDeliveriesDeleteResponse: null,
    subsequentDeliveriesErrorResponse: null,
    loading: false
}

export function reducer(state = initState, action: SubsequentDeliveriesActions){
    switch (action.type) {
        case SubsequentDeliveriesActionsType.INIT_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                loading: true
            }
        case SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                subsequentDeliveriesRequest: action.payload,
                loading: true
            }
        case SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES_COMPLETED:
            return {
                ...state,
                subsequentDeliveriesResponse: action.payload,
                loading: false
            }
        case SubsequentDeliveriesActionsType.UPDATE_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                subsequentDeliveriesUpdateResponse: action.payload,
                loading: false
            }
        case SubsequentDeliveriesActionsType.DELETE_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                subsequentDeliveriesDeleteResponse: action.payload,
                loading: false
            }
        case SubsequentDeliveriesActionsType.FAIL_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                subsequentDeliveriesErrorResponse: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

export const getSubsequentDeliveriesResponse = (state: State) => state.subsequentDeliveriesResponse;
export const getSubsequentDeliveriesRequest = (state: State) => state.subsequentDeliveriesRequest;
export const getSubsequentDeliveriesUpdateResponse = (state: State) => state.subsequentDeliveriesUpdateResponse;
export const getSubsequentDeliveriesDeleteResponse = (state: State) => state.subsequentDeliveriesDeleteResponse;
export const getSubsequentDeliveriesErrorResponse = (state: State) => state.subsequentDeliveriesErrorResponse;
export const getSubsequentDeliveriesLoading = (state: State) => state.loading;
