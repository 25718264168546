export enum ProductType {
    ExtraPrisma = 'EXTRAPRISMA',
    TopPrisma = 'TOPPRISMA',
    OffersPin = 'SONDERANGEBOTE',
    OffersExclusive = 'EXKLUSIVANGEBOTE',
    DokuLight = 'DOKULIGHT',
    Vormerkungen = 'vormerkungen',
    Paragraph73 = 'PARAGRAPH_73',
    Profax = 'PROFAXEMAIL',
    Promo = 'PROMO',
    Payback = 'PAYBACK',
    PrivateStock = 'PRIVATE_STOCK',
    NONE = 'UNKNOWN',
    Uber = 'UBER'

// TODO PROFAXMSV_3

}
