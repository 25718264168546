import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sonderaktionen',
  templateUrl: './sonderaktionen.component.html',
  styleUrls: ['./sonderaktionen.component.scss']
})
export class SonderaktionenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
