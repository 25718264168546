import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bestellung-werbemittel',
  templateUrl: './bestellung-werbemittel.component.html',
  styleUrls: ['./bestellung-werbemittel.component.scss']
})
export class BestellungWerbemittelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
