<h1>Jahreskalender Monatsaktion</h1>
<p>
  Mit dem Jahreskalender erfahren Sie schon heute welche Produkte Ihnen über das Jahr hinweg zugeteilt werden. So haben
  Sie im Rahmen Ihrer Kooperation einen Überblick aller Vorteile rund um die Bevorratungsware und die entsprechenden
  Konditionen und können diese bestmöglich positionieren. </p>
<div class="row mt-s">
  <div class="col-md-2">
    <a target="_blank">
      <img src="../../assets/dummy/Jahresuebersicht_LINDA_Monatsaktion_2023.jpg"
           class="img-border">
    </a>
  </div>
  <div class="col-md align-items-center d-flex"><a
      target="_blank">Jahresübersicht LINDA Monatsaktion 2024</a>&nbsp; (189 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>

<h1 class="mt-xl">
  PKP-Warenbevorratungen
</h1>
<p>
  Um Ihre exklusiven Vorteile der monatlichen Bevorratung jederzeit flexibel zu überblicken stellen wir Ihnen das
  Bevorratungsanschreiben digital zur Verfügung.</p>
<div class="row mt-s">
  <div class="col-md-7 align-items-center d-flex">
    <a target="_blank">Aktionsmonat März 2023</a>&nbsp; (268 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-7 align-items-center d-flex">
    <a target="_blank">Aktionsmonat Februar 2024</a>&nbsp; (295 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div><div class="row mt-s">
  <div class="col-md-7 align-items-center d-flex">
    <a target="_blank">Aktionsmonat Januar 2024</a>&nbsp; (278 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-7 align-items-center d-flex">
    <a target="_blank">Aktionsmonat Dezember 2023</a>&nbsp; (293 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>


