<div id="content" class="reklamationWrapper">
  <div class="panel">
    <div class="row">
      <div class="col-md-8 d-flex flex-column">
        <h2 class="pb-s">Ihre aktuellen Reklamationen</h2>
        <p>Hier können Sie den Status Ihrer beim Kunden-Service-Center platzierten Anliegen einsehen.</p>
        <small class="mt-m d-inline-block text-muted">Letzte
          Aktualisierung {{ lastOnClickRefreshTime | date: 'short' }} Uhr</small>
        <div class="my-m cursorPointer refresh-btn" (click)="onRefresh()">
          <i class="fal fa-redo fa-lg"></i> Bearbeitungsstatus neu laden
        </div>
      </div>
      <div class="col-4">
        <form>
          <mat-form-field appearance="outline">
            <mat-label>IDF-Nummer</mat-label>
            <input name="idf" matInput [value]="currentlyDisplayedIdf" readonly>
          </mat-form-field>
        </form>
      </div>
    </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table mb-l">
            <thead>
            <tr>
              <th class="pl-0">Reklamationen</th>
              <th>Details</th>
              <th></th>
              <th>Aufgenommen</th>
              <th>In Bearbeitung</th>
              <th>Abgeschlossen</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let recentItem of reklamationList.recent;">
              <td>{{recentItem.division}}</td>
              <td><i class="fal fa-eye phx-cta cursorPointer"></i></td>
              <td>
                                <span *ngIf="recentItem.closedHours">
                                    <i class="fal fa-info-circle phx-cta"
                                       matTooltip='Hinweis: die Abteilung gewährleistet eine Bearbeitung ab dem darauffolgenden Werktag.'></i>
                                </span>
              </td>
              <td class="position-relative px-0">
                <div class="greenBox">{{recentItem.status === 'COMPLETED' ? "" : recentItem.createdAt | date: 'H:mm\' Uhr\''}}</div>
                <div style="min-height: 35px" class="d-flex justify-content-end align-items-center">
                  <hr [ngClass]="recentItem.status !== 'CREATED' ? 'greenDashLine' : 'greyDashLine'"/>
                </div>
              </td>
              <td class="position-relative px-0">
                <div [ngClass]="recentItem.status !== 'CREATED' ? 'greenBox' : 'greyBox'">
                  {{recentItem.status === 'ASSIGNED' && recentItem.estimateInMinutes > 0 ? recentItem.estimateInMinutes + ' Min. *' : ""}}
                </div>
                <div style="min-height: 35px" class="d-flex justify-content-end align-items-center">
                  <hr [ngClass]="recentItem.status === 'COMPLETED' ? 'greenDashLine' : 'greyDashLine'"/>
                </div>
              </td>
              <td class="position-relative px-0">
                <div [ngClass]="recentItem.status === 'COMPLETED' ? 'greenBox' : 'greyBox'">
                  {{recentItem.completedAt | date: 'H:mm\' Uhr\''}}
                </div>
                <div style="min-height: 35px" class="d-flex justify-content-end align-items-center">
                  <hr class="invisibleLine"/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <small>*Hinweis: Die Angabe entspricht der geschätzten Bearbeitungszeit. <br>
            Bitte beachten Sie, dass die durchschnittliche Bearbeitungszeit aufgrund der Arbeitszeiten der
            jeweiligen Fachabteilung
            abweichen kann.</small>
        </div>
      </div>
  </div>
  <div class="panel">
    <div class="row">
      <div class="col-md-8">
        <h2 class="pb-1">Archiv</h2>
        <span>Hier können Sie alle Reklamationen der letzten 30 Tage einsehen.</span>
      </div>
    </div>
      <div class="row py-s mt-5">
        <div class="col-3 d-flex cursorPointer">
          <strong>Reklamationen</strong>
        </div>
        <div class="col-6 d-flex cursorPointer">
          <strong>Details</strong>
        </div>
        <div class="col-3 d-flex cursorPointer">
          <strong>Status</strong>
        </div>
      </div>
      <div class="row py-3 border-top" *ngFor="let archivItem of reklamationList.archive;">
        <div class="col-3">
          {{archivItem.division}}
        </div>
        <div class="col-6">
          {{archivItem.createdAt | date: 'dd.MM.yyyy, H:mm\' Uhr\''}} | {{archivItem.complaint}}
        </div>
        <div class="col-3" *ngIf="archivItem.status === 'COMPLETED'">
          Abgeschlossen <i class="fas fa-check fa-lg phx-corporate"></i>
        </div>
        <div class="col-3" *ngIf="archivItem.status !== 'COMPLETED'">Ausstehend</div>
      </div>
  </div>
</div>
