import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromReducers from '../../store/reducers';
import {ProductServiceAbstract} from './product-service.abstract';
import {WebshopRestService} from '../api/webshop-rest.service';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Product} from '../../models/shop/product.model';
import {
    PromoSearchAction, PromoUpdateScrollPositionAction,
    PromoLoadAction, PromoBatchFromCache, PromoUpdateCart, PromoEmptyCart
} from '../../store/actions/promo.actions';
import {EmptyProfaxCart} from '../../store/actions/pro-fax.actions';

@Injectable()
export class PromoService extends ProductServiceAbstract {

    constructor(public store: Store<fromReducers.State>,
                @Inject(WebshopRestService) public shopservice: WebshopRestService ) {
        super(store, shopservice);
    }

    private _promoCart$: Observable<any>;


    get promoCart$(): Observable<any>{
        return this._promoCart$;
    }

    set promoCart$(value: Observable<any>) {
        this._promoCart$ = value;
    }

    updateScrollingPosition(pos: number) {
        this.store.dispatch(new PromoUpdateScrollPositionAction(pos))
    }

    searchProducts(request: ProductRequest  = new ProductRequest({})) {
        this.store.dispatch(new PromoSearchAction(request))
    }

    displayNewBatch(request: ProductRequest) {
    }

    public getProductsLoadingObservable(): Observable<boolean>{
        this.productsLoading = this.store.pipe(select(fromReducers.getPromoLoading));
        return this.productsLoading;
    }

    public getProductsResponseObservable(): Observable<ProductsResponse>{
        this.productsResponse$ = this.store.pipe(select(fromReducers.getPromoResponse));
        return this.productsResponse$;
    }

    public getProductsRequestObservable(): Observable <ProductRequest>{
        this.productsRequest$ = this.store.pipe(select(fromReducers.getPromoRequest));
        return this.productsRequest$;
    }

    public getProductsObservable(): Observable<Product[]> {
        this.products$ = this.store.pipe(select(fromReducers.getPromoProducts));
        return this.products$;
    }

    public getProductCacheObservable(): Observable<Product[]> {
        this.productCache$ = this.store.pipe(select(fromReducers.getPromoProductCache))
        return this.productCache$;
    }

    public getScrollPositionObservable(): Observable<number>{
        this.scrollPosition$ = this.store.pipe(select(fromReducers.getPromoScrollPosition));
        return this.scrollPosition$;
    }

    getPromoCartObservable(): Observable<any> {
        this.promoCart$ = this.store.pipe(select(fromReducers.getPromoCart));
        return this.promoCart$;
    }

    updatePromoCart(amount: number, product: Product): void {
        const updatePromoCartAction: PromoUpdateCart = new PromoUpdateCart(
        {
            id: product.id + product.campaignId,
            cartItem: {amount, product}
        }
        );
        this.store.dispatch(updatePromoCartAction);
    }

    emptyPromoCart(campaignId){
        const emptyPromoCartAction: PromoEmptyCart = new PromoEmptyCart(campaignId);
        this.store.dispatch(emptyPromoCartAction);
    }
}