<div id="content" class="mb-0">
  <img
      src="../../assets/dummy/csm_headerimage_ppc.jpg"
      alt="ppc – Beratung für Apotheken">
  <div class="panel-without-border mb-0">
    <h1>ppc – Beratung für Apotheken</h1>
    <p>
      Wenn es um die strategische Ausrichtung, die Steigerung der Wirtschaftlichkeit oder die unkomplizierte Integration eines QM-Systems geht, sind Sie mit ppc bestens beraten. Aber natürlich auch bei Plänen zu einer Filialisierung oder beim Kauf bzw. Verkauf einer Apotheke.
    </p>
    <h2 class="mt-l">Erfolg ist kein Zufall. Erfolg ist planbar.</h2>
    <p>
      Vom einfachen Apotheken-Check bis hin zur langfristigen Beratung: Mit der Erfahrung aus über 10.000 Beratungen, validen Marktdaten zur Standortanalyse und zum Benchmarking der Sortimente und Umsätze, betrachtet Ihr ppc Berater Ihre Apotheke objektiv und nimmt den direkten Wettbewerb persönlich unter die Lupe. Dann erfolgt die branchenspezifische Interpretation der Daten über das Umfeld, den Wettbewerb, die Alters- und Einkommensstrukturen, Haushaltsformen, Kaufkraft, Umsatz- und Absatzchancen. Besonderen Wert legt ppc auf die individuellen Wünsche und Besonderheiten jeder Apotheke. Mit einem unabhängigen Apotheken-Consulting unterstützen wir Apotheken da, wo sie es brauchen – modular und mit transparenten Preisen.
    </p>
    <h2 class="mt-l">Individuelle, unabhängige Beratung</h2>
    <p>
      Die Beratungsprofis von ppc setzen sich dafür ein, das individuelle Profil jeder einzelnen Apotheke zu stärken, sie im Wettbewerb um Umsatz und Kunden konkurrenzfähiger und erfolgreicher zu machen. Dazu nutzen sie branchenspezifisches, betriebswirtschaftliches Know-how, kombiniert mit einer umfangreichen und profunden Datengrundlage über Wettbewerb, Einkommensstrukturen und Absatzchancen. Eine Analyse dieser Daten kann bestehende Prozesse verbessern oder der Apotheke Chancen für eine neue, vielversprechende strategische Ausrichtung geben. Dabei legt ppc großen Wert auf eine neutrale und unabhängige Beratung.
    </p>
    <h2 class="mt-l">Ihre Vorteile auf einen Blick</h2>
    <ul>
      <li>Betriebswirtschaftliche Analysen auf der Basis von relevantem Datenmaterial</li>
      <li>Individuelle, vertrauliche Beratung</li>
      <li>Apothekenbewertung</li>
      <li>Potenzial- und Standortanalysen</li>
      <li>Teamschulungen</li>
    </ul>
  </div>
</div>
<section class="green-square">
  <h2>Hier erfahren Sie mehr</h2>
  <a>
    <button class="btn-primary on-dark-bg mr-l">
      www.ppc-beratung.de
    </button>
  </a>
</section>
